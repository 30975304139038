function convertCommDeviceToNode(commDevice, fullTree, browsingClientId, commDeviceOptions, controllerOptions, doorOptions, configObjectOptions) {

    var children = null;
    var asyncChildren = null;
    
    if (fullTree) {

        children = [];

        if (controllerOptions) {
            var childControllers = commDeviceOptions.findCommDeviceChildrenFunction(commDevice.commDeviceId);
            children = children.concat(childControllers.map(d => convertControllerToNode(d, fullTree, browsingClientId, controllerOptions, doorOptions, configObjectOptions)));
        }

        children = children.filter(c => c != null);

        if (commDeviceOptions.hideEmptyCommDevices && children.length == 0)
            return null;
    }
    // We don't send the children down with the comm device object so don't know
    //else if (commDevice.children) {

    //    var hasChildren = false;

    //    if (commDeviceOptions)
    //        hasChildren = hasChildren || commDevice.children.hasDoorChildren

    //    asyncChildren = hasChildren;
    //}
    else {
        // assume childen on a async tree
        if (controllerOptions)
            asyncChildren = true;
    }

    return {
        data: {
            nodeType: 'comm-device',
            href: '/management/comm-device/' + commDevice.commDeviceId,
            ...commDevice
        },
        children: children,
        isBatch: asyncChildren,
        id: `cd-${commDevice.commDeviceId}`,
        displayName: `<div>` +
            (commDevice.client != null ? (browsingClientId != commDevice.client.clientId ? `<span class="badge badge-client">${commDevice.client.name}</span> ` : '') : '') +
            `<span class="badge badge-comm-device mr-1">${commDevice.type}</span>` +
            `<span class="circle mr-1" style="background-color:${getCircleColour(commDevice.state)};"></span>${commDevice.name}` +
            `</div>`
    };
}

function convertControllerToNode(controller, fullTree, browsingClientId, controllerOptions, doorOptions, configObjectOptions) {

    var children = null;
    var asyncChildren = null;

    if (fullTree) {

        children = [];

        if (doorOptions) {
            var childDoors = doorOptions.findDoorChildrenFunction(controller.controllerId);
            children = children.concat(childDoors.map(d => convertDoorToNode(d, fullTree, browsingClientId, doorOptions, configObjectOptions)));
        }

        children = children.filter(c => c != null);

        if (controllerOptions.hideEmptyControllers && children.length == 0)
            return null;
    }
    // We don't send the children down with the controller object so don't know
    //else if (controller.children) {

    //    var hasChildren = false;

    //    if (doorOptions)
    //        hasChildren = hasChildren || controller.children.hasDoorChildren

    //    asyncChildren = hasChildren;
    //}
    else {
        // assume childen on a async tree
        if (doorOptions)
            asyncChildren = true;
    }
   

    return {
        data: {
            nodeType: 'controller',
            href: '/management/controller/' + controller.controllerId,
            ...controller
        },
        children: children,
        isBatch: asyncChildren,
        id: `c-${controller.controllerId}`,
        displayName:
            `<span class="badge badge-controller mr-1">${controller.type}</span>` +
            ` ${controller.name}`
    };
}

function convertNodeToNode(node, fullTree, browsingClientId, nodeOptions, configObjectOptions) {

    var children = null;
    var asyncChildren = null;

    //if (fullTree) {

    //    children = [];

    //    if (configObjectOptions) {
    //        var childConfigOptions = configObjectOptions.findConfigObjectChildrenFunction(node.nodeId);
    //        children = children.concat(childConfigOptions.map(o => convertConfigObjectToNode(o, fullTree, browsingClientId, configObjectOptions)));
    //    }

    //    children = children.filter(c => c != null);

    //    if (nodeOptions.hideEmptyNodes && children.length == 0)
    //        return null;
    //}
    //else if (node.configurationObjects) {

    //    var hasChildren = false;

    //    if (configObjectOptions)
    //        hasChildren = hasChildren || node.hasConfigOptions

    //    asyncChildren = hasChildren;
    //}

    if (node.configurationObjects) {

        children = [];

        if (configObjectOptions) {
            var childConfigOptions = node.configurationObjects;
            children = children.concat(childConfigOptions.map(o => convertConfigObjectToNode(o, fullTree, browsingClientId, configObjectOptions)));
        }

        children = children.filter(c => c != null);

        if (nodeOptions.hideEmptyNodes && children.length == 0)
            return null;

    }

    return {
        data: {
            nodeType: 'node',
            href: '/management/node/' + node.nodeId,
            ...node
        },
        children: children,
        //isBatch: asyncChildren,
        id: `n-${node.nodeId}`,
        displayName: `<div>` +
            (browsingClientId != node.clientId ? `<span class="badge badge-client mr-1">${node.client.name}</span> ` : '') +
            `<span class="badge badge-udn mr-1">${node.udn}</span>` +
            `<span class="badge badge-secondary mr-1">${node.type}</span>` +
            `<span class="circle mr-1" style="background-color:${getCircleColour(node.state)};"></span>${node.name}` +
            `</div>`
    };
}

function convertConfigObjectToNode(configObject, fullTree, browsingClientId, configObjectOptions) {

    var children = null;
    var asyncChildren = null;

    return {
        data: {
            nodeType: 'configuration-object',
            href: '/management/' + configObject.type.toLowerCase() + '/' + configObject[configObject.type.toLowerCase()+'Id'],
            ...configObject
        },
        children: children,
        isBatch: asyncChildren,
        id: `co-${configObject.configObjectId}`,
        displayName: `<div>` +
            `<span class="badge badge-secondary mr-1">${configObject.type}</span>` +
            `${configObject.name}` +
            `</div>`
    };
}

function convertGroupToNode(group, fullTree, browsingClientId, groupOptions, fobOptions, displayFobType) {

    var children = null;
    var asyncChildren = null;

    if (fullTree) {

        children = [];

        if (fobOptions) {
            var childFobs = fobOptions.findFobChildrenFunction(group.groupId);
            children = children.concat(childFobs.map(f => convertFobToNode(f, fullTree, browsingClientId, fobOptions, displayFobType)));
        }

        if (groupOptions) {
            var childGroups = groupOptions.findGroupChildrenFunction(group.groupId);
            children = children.concat(childGroups.map(g => convertGroupToNode(g, fullTree, browsingClientId, groupOptions, fobOptions, displayFobType)));
        }

        children = children.filter(c => c != null);

        if (groupOptions.hideEmptyGroups && children.length == 0)
            return null;
    }
    else if (group.children) {

        var hasChildren = group.children.hasGroupChildren;

        if (fobOptions)
            hasChildren = hasChildren || group.children.hasFobChildren

        asyncChildren = hasChildren;
    }
    return {
        data: {
            nodeType: 'group',
            href: '/administration/group/' + group.groupId,
            ...group
        },
        children: children,
        isBatch: asyncChildren,
        id: `g-${group.groupId}`,
        displayName: (browsingClientId != group.client.clientId ?
            `<span class="badge badge-client">${group.client.name}</span> ` : '') +
            `${group.name}`
    }
}

function convertNoticeBoardToNode(noticeBoard, fullTree, browsingClientId, noticeBoardOptions) {
    return {
        data: {
            nodeType: 'notice-board',
            href: '/management/notice-board/' + noticeBoard.digitalNoticeBoardId,
            ...noticeBoard
        },
        id: `nb-${noticeBoard.digitalNoticeBoardId}`,
        displayName: (browsingClientId != noticeBoard.client.clientId ?
                `<span class="badge badge-client">${noticeBoard.client.name}</span> ` :
            '') +
            //`<span class="badge badge-success">${noticeBoardOptions.noticeBoardVocabulary}</span > ` +
            `<span class="badge badge-success">Notice Board</span > ` +
            `${noticeBoard.name}`
    }
}

function convertFobToNode(fob, fullTree, browsingClientId, fobOptions, showFobType) {

    return {
        data: {
            nodeType: 'fob',
            href: '/administration/fob/' + fob.fobId,
            ...fob
        },
        /*isBatch: true,*/
        id: `f-${fob.fobId}`,
        displayName:
            (fob.client && browsingClientId != fob.client.clientId ? `<span class="badge badge-client">${fob.client ? fob.client.name : "Unknown"}</span> ` : '') +
            `<span class="badge badge-${fob.labelType}">${fob.textStatus}</span> ` +
            (showFobType ? `<span v-if='this.$auth.user().userData.showFobType' class="badge badge-secondary ml-1 mr-1">${ fob.type }</span>` :'') +
            `<span class="circle-wrapper"><span style="background-color:${fob.styleHexColour}" class="circle"></span></span> ` +
            ` ${fob.reference ? fob.reference : fob.keyCode} ` +
            (fob.person ?
                ` - <span class="text-muted">${(fob.person.firstName ? fob.person.firstName : '')} ` +
                `${(fob.person.surname ? fob.person.surname : '')} ` +
                (fob.person.company ? `(${fob.person.company})` : '') +
                '</span>'
                : '')
    }

}

function convertDoorToNode(door, fullTree, browsingClientId, doorOptions, configObjectOptions) {

    var children = null;
    var asyncChildren = null;

    if (doorOptions && doorOptions.displayConfigurationObjects && configObjectOptions) {

        var objects = [
            door.readerOne,
            door.readerTwo,
            door.lockOne,
            door.lockTwo,
            door.inputRte,
            door.inputDoorEntry,
            door.inputEmergencyUnlock,
            door.inputDoorContact,
            door.inputTradesButton
        ];

        if (door.outputs) {
            objects.push(...door.outputs);
        }

        objects = objects.filter(c => c != null);
        children = [];

        if (configObjectOptions) {
            children = children.concat(objects.map(o => convertConfigObjectToNode(o, fullTree, browsingClientId, configObjectOptions)));
        }

        children = children.filter(c => c != null);

        if (doorOptions.hideEmptyNodes && children.length == 0)
            return null;

    }



    return {
        data: {
            nodeType: 'door',
            href: '/management/door/' + door.doorId,
            ...door
        },
        children: children,
        //isBatch: asyncChildren,
        id: `d-${door.doorId}`,
        displayName: `<span class="badge badge-info">${doorOptions.doorVocabulary}</span> ` +
            `${ door.name }`
    }

}

function convertAreaToNode(area, fullTree, browsingClientId,  areaOptions, doorOptions, fobOptions, noticeBoardOptions, displayFobType) {

    var children = null;
    var asyncChildren = null;

    if (fullTree) {

        children = [];

        if (fobOptions) {
            var childFobs = fobOptions.findFobChildrenFunction(area.areaId);
            children = children.concat(childFobs.map(f => convertFobToNode(f, fullTree, browsingClientId, doorOptions, displayFobType)));
        }

        if (doorOptions) {
            var childDoors = doorOptions.findDoorChildrenFunction(area.areaId);
            children = children.concat(childDoors.map(d => convertDoorToNode(d, fullTree, browsingClientId,  doorOptions)));
        }

        if (noticeBoardOptions) {
            var childNoticeBoards = noticeBoardOptions.findNoticeBoardChildrenFunction(area.areaId);
            children = children.concat(childNoticeBoards.map(nb => convertNoticeBoardToNode(nb, fullTree, browsingClientId, noticeBoardOptions)));
        }

        if (areaOptions) {
            var childAreas = areaOptions.findAreaChildrenFunction(area.areaId);
            children = children.concat(childAreas.map(a => convertAreaToNode(a, fullTree, browsingClientId, areaOptions, doorOptions, fobOptions, noticeBoardOptions, displayFobType)));
            
        }
        //console.log(areaOptions.hideEmptyAreas)
        children = children.filter(c => c != null);

        if (areaOptions.hideEmptyAreas && children.length == 0)
            return null;
    }
    else if (area.children) {

        var hasChildren = area.children.hasAreaChildren;

        if (fobOptions) 
            hasChildren = hasChildren || area.children.hasFobChildren
        if (doorOptions)
            hasChildren = hasChildren || area.children.hasDoorChildren
        if (noticeBoardOptions)
            hasChildren = hasChildren || area.children.hasNoticeBoardChildren

        asyncChildren = hasChildren;
    }




    return {
        data: {
            nodeType: 'area',
            href: '/administration/area/' + area.areaId,
            ...area
        },
        children: children,
        isBatch: asyncChildren,
        id: `a-${area.areaId}`,
        displayName:
            (browsingClientId != area.client.clientId ?
                `<span class="badge badge-client">${area.client.name}</span> ` :
                '') +
            `<span class="badge badge-area">${area.type}</span> ` +
            `${area.name}`
    }

}

export function getCircleColour(state) {
    switch (state) {
        case "Online":
            return "#5cb85c";
        case "AttentionRequired":
            return "#f0ad4e";
        case "Offline":
            return "#d9534f";
    }
}

export async function AreaFullPromise(browsingClientId, includeSubClientStuff, hideEmptyAreas, includeFobs, includeDoors, includeNoticeBoards, subclientId) {
    

    var areaQueryString = '';
    if (subclientId) areaQueryString = `?subclient_id=${subclientId}`;
    else if (includeSubClientStuff) areaQueryString = '?include_client_areas=true';
    var areas = (await this.$http.get('/area' + areaQueryString)).data;
   
    var areaOptions = {
        findAreaChildrenFunction: (areaId) => areas.filter(a => a.parentAreaId == areaId),
        hideEmptyAreas: hideEmptyAreas
    };

    var doorOptions = null;
    var fobOptions =  null;
    var noticeBoardOptions = null;

    if (includeDoors) {
        var doorQueryString = includeSubClientStuff ? '?include_client_doors=true' : '';
        var doors = (await this.$http.get('/door' + doorQueryString)).data;

        doorOptions = {
            findDoorChildrenFunction: (areaId) => doors.filter(d => d.areaId == areaId),
            doorVocabulary: this.$t('objects.door')
        };
    }

    if (includeFobs) {
        var fobQueryString = includeSubClientStuff ? '?inlcude_client_fobs=true' : '';
        var fobs = (await this.$http.get('/fob/area-fobs' + fobQueryString)).data;

        fobOptions = {
            findFobChildrenFunction: (areaId) => fobs.filter(f => f.primaryActivation && f.primaryActivation.area && f.primaryActivation.area.areaId == areaId),
            fobVocabulary: this.$t('objects.fob')
        };
    }

    if (includeNoticeBoards) {
        var noticeBoardQueryString = includeSubClientStuff ? '?include_client_notice_boards=true' : '';
        var noticeBoards = (await this.$http.get('/noticeboard' + noticeBoardQueryString)).data;

        noticeBoardOptions = {
            findNoticeBoardChildrenFunction: (areaId) => noticeBoards.filter(nb => nb.areaId == areaId),
            noticeBoardVocabulary: this.$t('objects.notice-board')
        };
    }


    var result = [];
    var topLevel = areas.filter(a => a.parentAreaId == null);

    for (var i = 0; i < topLevel.length; i++) 
        result.push(convertAreaToNode(topLevel[i],
            true,
            browsingClientId,
            areaOptions,
            doorOptions,
            fobOptions,
            noticeBoardOptions
        ));
    
    result = result.filter(c => c != null);


    return result;
}

export async function GroupFullPromise(browsingClientId, includeSubClientStuff, hideEmptyGroups, includeFobs) {

    var groupQueryString = includeSubClientStuff ? '?include_client_groups=true' : '';
    var groups = (await this.$http.get('/group' + groupQueryString)).data;

    var groupOptions = {
        findGroupChildrenFunction: (groupId) => groups.filter(g => g.parentGroupId == groupId),
        hideEmptyGroups: hideEmptyGroups
    };

    var fobOptions = null;


    if (includeFobs) {
        var fobQueryString = includeSubClientStuff ? '?inlcude_client_fobs=true' : '';
        var fobs = (await this.$http.get('/fob/group-fobs' + fobQueryString)).data;

        fobOptions = {
            findFobChildrenFunction: (groupId) => fobs.filter(f => f.primaryActivation && f.primaryActivation.group && f.primaryActivation.group.groupId == groupId),
            fobVocabulary: this.$t('objects.fob')
        };
    }

    var result = [];
    var topLevel = groups.filter(g => g.parentGroupId == null);

    for (var i = 0; i < topLevel.length; i++)
        result.push(convertGroupToNode(topLevel[i],
            true,
            browsingClientId,
            groupOptions,
            fobOptions
        ));

    result = result.filter(c => c != null);

    return result;
}

export async function CommDeviceDoorFullPromise(browsingClientId, includeSubClientStuff, hideEmptyCommDevices, includeControllers, includeDoors) {

    var commDeviceQueryString = includeSubClientStuff ? '?include_client_comm_devices=true' : '';
    var commDevices = (await this.$http.get('/communicationdevice' + commDeviceQueryString)).data;

    var commDeviceOptions = {
        findCommDeviceChildrenFunction: (commDeviceId) => [],
        hideEmptyCommDevices: hideEmptyCommDevices
    };

    var doorOptions = null;
    var controllerOptions = null;

    if (includeControllers) {
        var controllerQueryString = includeSubClientStuff ? '?inlcude_client_controllers=true' : '';
        var controllers = (await this.$http.get('/controller' + controllerQueryString)).data;

        commDeviceOptions.findCommDeviceChildrenFunction = (commDeviceId) => controllers.filter(c => c.commDeviceId == commDeviceId),

        controllerOptions = {
            findControllerChildrenFunction: (controllerId) => [],
            hideEmptyControllers: false
        };
    

        if (includeDoors) {
            var doorQueryString = includeSubClientStuff ? '?include_client_doors=true' : '';
            var doors = (await this.$http.get('/door' + doorQueryString)).data;

            doorOptions = {
                doorVocabulary: this.$t('objects.door')
            };

            controllerOptions.findControllerChildrenFunction = (controllerId) => doors.filter(d => d.controllerId == controllerId) ;
        }
    }
    

    var result = [];
    var topLevel = commDevices;

    for (var i = 0; i < topLevel.length; i++)
        result.push(convertCommDeviceToNode(topLevel[i],
            true,
            browsingClientId,
            commDeviceOptions,
            controllerOptions,
            doorOptions
        ));

    result = result.filter(c => c != null);

    return result;
}

export async function CommDeviceNodeFullPromise(browsingClientId, includeSubClientStuff, hideEmptyCommDevices, includeControllers, includeNodes) {

    var commDeviceQueryString = includeSubClientStuff ? '?include_client_comm_devices=true' : '';
    var commDevices = (await this.$http.get('/communicationdevice' + commDeviceQueryString)).data;

    var commDeviceOptions = {
        findCommDeviceChildrenFunction: (commDeviceId) => [],
        hideEmptyCommDevices: hideEmptyCommDevices
    };

    var nodeOptions = null;
    var controllerOptions = null;

    if (includeControllers) {
        var controllerQueryString = includeSubClientStuff ? '?inlcude_client_controllers=true' : '';
        var controllers = (await this.$http.get('/controller' + controllerQueryString)).data;

        commDeviceOptions.findCommDeviceChildrenFunction = (commDeviceId) => controllers.filter(c => c.commDeviceId == commDeviceId),

            controllerOptions = {
                findControllerChildrenFunction: (controllerId) => [],
                hideEmptyControllers: hideEmptyCommDevices
            };


        if (includeNodes) {
            var nodeQueryString = includeSubClientStuff ? '?include_client_nodes=true' : '';
            var nodes = (await this.$http.get('/door' + nodeQueryString)).data;

            nodeOptions = {
                nodeVocabulary: this.$t('objects.node')
            };

            nodeOptions.findControllerChildrenFunction = (controllerId) => doors.filter(d => d.controllerId == controllerId);
        }
    }


    var result = [];
    var topLevel = commDevices;

    for (var i = 0; i < topLevel.length; i++)
        result.push(convertCommDeviceToNode(topLevel[i],
            true,
            browsingClientId,
            commDeviceOptions,
            controllerOptions,
            doorOptions
        ));

    result = result.filter(c => c != null);

    return result;
}

export async function AreaChildrenPromise(browsingClientId, workingAreaId, queryString, includeFobs, includeDoors, includeNoticeBoards) {
    var url = '/area' + (workingAreaId == -1 ? '/top-level' : `/${workingAreaId}/children`) + queryString;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertAreaToNode(i,
        false, /* fullTree */
        browsingClientId,
        null, /* areaOptions */
        includeDoors,
        includeFobs,
        includeNoticeBoards
    ));
}

export async function GroupChildrenPromise(browsingClientId, workingGroupId, queryString, includeFobs, hideEmptyGroups) {
    
    var url = '/group' + (workingGroupId == -1 ? '/top-level' : `/${workingGroupId}/children`) + queryString;

    if (hideEmptyGroups) {
        if (queryString)
            url = url + "&hideEmptyGroups=true";
        else
            url = url + "?hideEmptyGroups=true";
    }

    var items = (await this.$http.get(url)).data;

    return items.map(i => convertGroupToNode(i,
        false, /* fullTree */
        browsingClientId,
        null, /* groupOptions */
        includeFobs,
        null /* includeDoors */
    ));
}

export async function GroupFobChildrenPromise(browsingClientId, workingGroupId, displayFobType) {
    var url = `/group/${workingGroupId}/fobs`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertFobToNode(i,
        false, /* fullTree */
        browsingClientId,
        null, /* fobOptions */
        displayFobType
    ));
}

export async function FobChildrenPromise(browsingClientId, workingAreaId, displayFobType) {
    var url = `/area/${workingAreaId}/fobs`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertFobToNode(i,
        false, /* fullTree */
        browsingClientId,
        null, /* fobOptions */
        displayFobType
    ));
}

export async function NoticeBoardChildrenPromise(browsingClientId, workingAreaId) {

    var url = `/area/${workingAreaId}/notice-boards`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertNoticeBoardToNode(i,
        false, /* fullTree */
        browsingClientId,
        {   /* noticeBoardOptions */
            doorVocabulary: this.$t('objects.notice-board')
        } 
    ));
}

export async function DoorChildrenPromise(browsingClientId, workingAreaId) {

    var url = `/area/${workingAreaId}/doors`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertDoorToNode(i,
        false, /* fullTree */
        browsingClientId,
        {   /* doorOptions */
            doorVocabulary: i.type == "Access Location" ? this.$t('objects.access-location') : this.$t('objects.door')
        }
    ));
}

export async function CommDeviceControllerPromise(browsingClientId, commDeviceId, includeDoors) {

    var url = `/communicationdevice/${commDeviceId}/controllers`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertControllerToNode(i,
        false, /* fullTree */
        browsingClientId,
        {   /* controllerOptions */
        },
        includeDoors /* includeDoors */
    ));
}

export async function CommDevicePromise(browsingClientId, queryString) {

    var url = '/communicationdevice' + queryString;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertCommDeviceToNode(i,
        false, /* fullTree */
        browsingClientId,
        {   /* commDeviceOptions */
        },
        true, /* includeControllers */
        true, /* includeDoors */
    ));
}

export async function ControllerDoorPromise(browsingClientId, controllerId) {
    var url = `/controller/${controllerId}/doors`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertDoorToNode(i,
        false, /* fullTree */
        browsingClientId,
        {   /* doorOptions */
            doorVocabulary: this.$t('objects.door')
        }
    ));
}

export async function ControllerNodePromise(browsingClientId, controllerId) {
    var url = `/controller/${controllerId}/nodes`;
    var items = (await this.$http.get(url)).data;

    return items.map(i => convertNodeToNode(i,
        false, /* fullTree */
        browsingClientId,
        {   /* nodeOptions */
            nodeVocabulary: this.$t('objects.node')
        },
        {   /* configObjectOptions */
        }
    ));
}





export async function CommDeviceDoorFullLocalPromise(commDevices, browsingClientId, includeSubClientStuff, hideEmptyCommDevices, includeControllers, includeDoors, includeDoorObjects) {


    var commDeviceOptions = {
        findCommDeviceChildrenFunction: (commDeviceId) => [],
        hideEmptyCommDevices: hideEmptyCommDevices
    };

    var doorOptions = null;
    var controllerOptions = null;
    var configObjectOptions = null;

    if (includeControllers) {

        controllerOptions = {
            //findControllerChildrenFunction: (controllerId) => [],
            findControllerChildrenFunction: (commDeviceId) => commDevices.find(c => c.commDeviceId == commDeviceId).controllers,
            hideEmptyControllers: hideEmptyCommDevices
        };

        if (includeDoors) {

            doorOptions = {
                doorVocabulary: this.$t('objects.door'),
                //findDoorChildrenFunction: (doorId) => [],
                findDoorChildrenFunction: (controllerId) => commDevices.flatMap(cd => cd.controllers).find(d => d.controllerId == controllerId).doors
            };

            if (includeDoorObjects) {
                doorOptions.displayConfigurationObjects = true;

                configObjectOptions = {}
            }
        }
    }


    var result = [];
    var topLevel = commDevices;

    for (var i = 0; i < topLevel.length; i++)
        result.push(convertCommDeviceToNode(topLevel[i],
            true,
            browsingClientId,
            commDeviceOptions,
            controllerOptions,
            doorOptions,
            configObjectOptions
        ));

    result = result.filter(c => c != null);

    return result;
}