import { render, staticRenderFns } from "./hidden-tabs-tab.vue?vue&type=template&id=5ec87b04"
import script from "./hidden-tabs-tab.vue?vue&type=script&lang=js"
export * from "./hidden-tabs-tab.vue?vue&type=script&lang=js"
import style0 from "./hidden-tabs-tab.vue?vue&type=style&index=0&id=5ec87b04&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports