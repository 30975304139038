export default {
    computed: {
        userData() {
            var user = this.$auth.user();
            if (!user || !user.userData) return null;
            return user.userData;
        },
    },
    methods: {
        getTransferred(area) {
            if (!area) return null; 
            if(!this.userData) return null;

            // Does the working client own the site, or is the site client managed by the working client 
            if (this.userData.managingClientIds.includes(area.clientId) ||
                area.clientId == this.userData.workingClientId) {

                // If the site has a installer defined then display that over anything else
                if (area.engineerClient) {
                    return {
                        value: 'transferred',
                        colour: 'dark',
                        foreground: null,
                        background: null,
                        allowAccess: true,
                        icon: 'tools',
                        display: area.engineerClient.name,
                    }; 
                } else {
                    // Is the current client an installer then display that the site hasn't been transferred
                    if (this.userData.isEngineer) {
                        return {
                            value: 'notTransferred',
                            colour: 'danger',
                            foreground: null,
                            background: null,
                            allowAccess: true,
                            icon: 'dash-circle',
                            display: this.$t('area.not-transferred'),
                        };
                    } else {
                        // Otherwise if the current client is a site owner then
                        // display not transferred and no maintencance installer set
                        return {
                            value: 'notTransferred',
                            colour: 'muted',
                            foreground: null,
                            background: null,
                            allowAccess: true,
                            icon: 'tools',
                            display: this.$t('area.no-engineer'),
                        };
                    }
                }
            } else {
                // The working client does not own the site,
                // so it should be viewing the area as an engineer
                if (area.engineerClient && area.engineerClient.clientId == this.userData.workingClientId) {
                    return {
                        value: 'transferred',
                        colour: 'success',
                        foreground: null,
                        background: null,
                        allowAccess: true,
                        icon: 'arrow-up-right-circle',
                        display: this.$t('area.transferred'),
                    };
                } else {
                    // Or they don't have access to view the site
                    return {
                        value: 'noAccess',
                        colour: 'muted',
                        foreground: 'muted',
                        background: 'light',
                        allowAccess: false,
                        icon: 'x-circle',
                        display: this.$t('area.no-access'),
                    };
                }
            }
            
        },
    },
}