import { render, staticRenderFns } from "./doors-cancel-commands-button.vue?vue&type=template&id=8c53d09c"
import script from "./doors-cancel-commands-button.vue?vue&type=script&lang=js"
export * from "./doors-cancel-commands-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports