import ApiErrors from '../components/common/api-errors'

export default {
    components: {
        'api-errors': ApiErrors
    },
    data() {
        return {
            apiErrors: []
        }
    },
    methods: {
        handleApiError(error, errorTitle) {

            var errorResponse = error.response || error;
            

            if (errorResponse.status) {

                if (errorResponse.status == 422) {
                    this.apiErrors = errorResponse.data.map(e => {
                        return { error: e.propertyName + ': ' + e.errorMessage }
                    });
                }
                else if (errorResponse.status == 500) {
                    this.apiErrors = [ errorResponse.data ];
                }
                else if (errorResponse.status == 400) {
                    this.apiErrors = [{ error: errorResponse.data }];
                }
                else if (errorResponse.status == 200) {

                }
                else
                    this.apiErrors = [{ error: errorTitle }];
            }
            else {
                if (error.message)
                    this.apiErrors = [{ error: error.message }];
                else
                    this.apiErrors = [{ error: errorTitle }];
            }

            var text = error.message;

            if (this.apiErrors.length > 1) {
                text = text += '<br />' + this.apiErrors.length + ' ' + this.$tc('errors.plurals.error', this.apiErrors.length) + ':';
                for (var apiError of this.apiErrors) {
                    text = text += '<br /> - ' + apiError.error;
                }
            }
            else if (this.apiErrors.length == 1) {
                if (text != this.apiErrors[0].error) text = text += '<br />' + this.apiErrors[0].error;
            }
            else {
                text = text += '<br />' + this.$t('errors.error-not-available');
            }

            this.$notify({
                type: 'alert-danger',
                title: errorTitle,
                text: text
            });
        },
        throwNoResponseApiError() {
            throw new Error(this.$t('errors.no-response'));
        },
    }
}