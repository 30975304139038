import { render, staticRenderFns } from "./sign-off-form-page.vue?vue&type=template&id=022c28fe"
import script from "./sign-off-form-page.vue?vue&type=script&lang=js"
export * from "./sign-off-form-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports