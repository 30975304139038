export default class EventSignalR {
    constructor(vueComponent) {
        this.vueComponent = vueComponent;

        // Please also expose headers from WebApi/Startup.cs
        // https://stackoverflow.com/questions/67803262/set-up-cors-in-asp-net-core-to-allow-wildcard-origin-without-auth-and-whiteliste
        //
        // Headers are Pascal-Kebab-Case and javascript object property names are kebab-case
        // See transformation in CommonVue/data-list.vue loadItems method
        this.hubs = {
            'subscribe-logger-area': {
                hub: this.vueComponent.$loggerHub,
                startRegistration: this.vueComponent.$loggerHubStartRegistration,
                endRegistration: this.vueComponent.$loggerHubEndRegistration,
                subscribe: 'SubscribeAreaEvents',
                unsubscribe: 'UnsubscribeAreaEvents',
            },
            'subscribe-logger-controller': {
                hub: this.vueComponent.$loggerHub,
                startRegistration: this.vueComponent.$loggerHubStartRegistration,
                endRegistration: this.vueComponent.$loggerHubEndRegistration,
                subscribe: 'SubscribeControllerEvents',
                unsubscribe: 'UnsubscribeControllerEvents',
            },
            'subscribe-logger-door': {
                hub: this.vueComponent.$loggerHub,
                startRegistration: this.vueComponent.$loggerHubStartRegistration,
                endRegistration: this.vueComponent.$loggerHubEndRegistration,
                subscribe: 'SubscribeDoorEvents',
                unsubscribe: 'UnsubscribeDoorEvents',
            },
            'subscribe-logger-fob': {
                hub: this.vueComponent.$loggerHub,
                startRegistration: this.vueComponent.$loggerHubStartRegistration,
                endRegistration: this.vueComponent.$loggerHubEndRegistration,
                subscribe: 'SubscribeFobEvents',
                unsubscribe: 'UnsubscribeFobEvents',
            },
            'subscribe-logger-reader': {
                hub: this.vueComponent.$loggerHub,
                startRegistration: this.vueComponent.$loggerHubStartRegistration,
                endRegistration: this.vueComponent.$loggerHubEndRegistration,
                subscribe: 'SubscribeReaderEvents',
                unsubscribe: 'UnsubscribeReaderEvents',
            },
        };
    }

    getSubscriptions(subscribe) {
        let subscriptions = [];
        for (const headerKey in subscribe) {
            const hub = this.hubs[headerKey];
            if (hub) {
                for (const value of subscribe[headerKey]) {
                    subscriptions.push({
                        key: `${headerKey}-${value}`,
                        startRegistration: hub.startRegistration,
                        endRegistration: hub.endRegistration,
                        registerCallback: function () {
                            hub.hub.invoke(hub.subscribe, value);
                        },
                        unregisterCallback: function () {
                            hub.hub.invoke(hub.unsubscribe, value);
                        },
                    });
                }
            }
        }
        return subscriptions;
    }

    register(newSubscriptions, oldSubscriptions) {
        if (!Array.isArray(newSubscriptions) || !newSubscriptions.length)
            newSubscriptions = [];

        if (!Array.isArray(oldSubscriptions) || !oldSubscriptions.length)
            oldSubscriptions = [];

        for (var removing of oldSubscriptions.filter(os => !newSubscriptions.map(ns => ns.key).includes(os.key)))
            removing.endRegistration(removing.unregisterCallback, removing.key);

        for (var adding of newSubscriptions.filter(ns => !oldSubscriptions.map(os => os.key).includes(ns.key)))
            adding.startRegistration(adding.registerCallback, adding.key);
    }

    unregister(subscriptions) {
        this.register([], subscriptions)
    }
}
