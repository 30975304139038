<template>
    <div>
        <ul class="navbar-nav mr-auto">

            <li class="nav-item" v-if="$auth.check()">
                <router-link to="/areas" class="nav-link">Areas </router-link>
            </li>

            <li class="nav-item" v-if="$auth.check()">
                <router-link to="/dashboard" class="nav-link">Dashboard </router-link>
            </li>


            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDocumentationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Documentation
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDocumentationDropdown">

                    <router-link v-if="$auth.check()" to="/documentation" class="dropdown-item">All Documentation</router-link>
                    <router-link to="/documentation/tags" class="dropdown-item">Documentation Tags</router-link>
                    <router-link v-if="$auth.check()" to="/documentation/upload" class="dropdown-item">Upload Documentation</router-link>

                    <div class="dropdown-divider"></div>

                    <router-link v-for="documentationTag in documentationTags" v-bind:key="documentationTag" :to="'/documentation/tag/' + documentationTag" class="dropdown-item">
                        {{ documentationTag }}
                    </router-link>
                </div>
            </li>


            <li v-if="$auth.check()" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarFirmwareDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Firmware
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarRmaDropdown">

                    <router-link to="/download-software" class="dropdown-item">Download Software</router-link>
                    <div class="dropdown-divider"></div>

                    <router-link v-for="firmwareDeviceType in firmwareDeviceTypes" v-bind:key="firmwareDeviceType.deviceType" :to="'/firmware/device-type/' + firmwareDeviceType.deviceType" class="dropdown-item">
                        {{ firmwareDeviceType.name }}
                    </router-link>
                </div>
            </li>

            <li v-if="$auth.check()" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarUdnDevicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    UDN Devices
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarUdnDevicesDropdown">

                    <router-link to="/udn-devices" class="dropdown-item">UDN Device Types</router-link>
                    <router-link to="/udn-lookup" class="dropdown-item">UDN Lookup</router-link>
                    <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/udn-devices/bulk-status-update" class="dropdown-item">Bulk UDN Status Update</router-link>
                    <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/udn-devices/generate" class="dropdown-item">Generate New UDNs</router-link>
                    <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/udn-devices/generate-labels" class="dropdown-item">Generate UDN Labels</router-link>

                    <div class="dropdown-divider"></div>

                    <router-link v-for="udnDeviceType in udnDeviceTypes" v-bind:key="udnDeviceType.deviceType" :to="'/udn-devices/device-type/' + udnDeviceType.deviceType" class="dropdown-item">
                        {{ udnDeviceType.name }}
                    </router-link>
                </div>
            </li>


            <li v-if="$auth.check('KMSUSER_USER', 'permissions')" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarRmaDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    RMAs
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarRmaDropdown">
                    <router-link to="/rmas" class="dropdown-item">All RMAs</router-link>
                    <router-link to="/rma/add" class="dropdown-item">Add RMA</router-link>
                    <router-link to="/rma-lookup" class="dropdown-item">RMA Lookup</router-link>
                    <router-link to="/rma-report" class="dropdown-item">RMA Report</router-link>
                </div>
            </li>


            <li v-if="$auth.check('KMSUSER_USER', 'permissions')" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarConfigDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Configuration
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarConfigDropdown">
                    <router-link to="/clients" class="dropdown-item">Clients</router-link>
                    <router-link to="/addresses" class="dropdown-item">Addresses</router-link>
                </div>
            </li>


        </ul>

        <form  v-if="!$auth.check()"  class="form-inline my-2 my-lg-0">
            <router-link to="/dashboard"  class="btn btn-outline-light btn-sm my-2 my-sm-0">KMS Login</router-link>
        </form>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                collapsed: false,
                documentationTags: [],
                firmwareDeviceTypes: [],
                udnDeviceTypes: []
            }
        },
        methods: {
            toggleCollapsed: (event) => this.collapsed = !this.collapsed,
            async handleAuthChange(isAuthenticatedDOESNTWORK) {
                this.documentationTags = (await this.$http.get(`/documentation/tags`)).data;

                if (this.$auth.check()) {
                    this.firmwareDeviceTypes = (await this.$http.get(`/firmware/device-types`)).data;
                    this.udnDeviceTypes = (await this.$http.get("/udn-device/device-types")).data;
                }
            }
        },
        async created() {
            await this.handleAuthChange(false);
            this.$watch(() => this.$auth.check(), this.handleAuthChange);

        }
    }
</script>
<style>
    .slide-enter-active, .slide-leave-active {
        transition: max-height .35s
    }

    .slide-enter, .slide-leave-to {
        max-height: 0px;
    }

    .slide-enter-to, .slide-leave {
        max-height: 20em;
    }
</style>
