import moment from 'moment';

export default {
    data: function () {
        return {
            statuses: [],
            stages: [],
        };
    },

    filters: {
        dateFormat: function (datetime) {
            if (!datetime) return '';
            return moment(datetime).format('DD/MM/YYYY');
        },

        timeFormat: function (datetime) {
            if (!datetime) return '';
            return moment(datetime).format('HH:mm:ss');
        },
    },

    created() {
        this.$set(this.statuses, 0, {
            status: 0,
            stage: 0,
            name: 'Created',
            display: 'Created',
            icon: 'bag-check',
            theme: 'success',
            comingStatuses: [10, 30, 40, 50, 90],
            isInternalListDefault: false,
            isReal: true,
        });
        this.$set(this.statuses, 10, {
            status: 10,
            stage: 10,
            name: 'PaymentRequiresPaymentMethod',
            display: 'Payment Requires Payment Method',
            icon: 'credit-card',
            theme: 'info',
            comingStatuses: [30, 40, 50, 90],
            isInternalListDefault: false,
            isReal: true,
        });
        this.$set(this.statuses, 11, {
            status: 11,
            stage: 10,
            name: 'PaymentRequiresConfirmation',
            display: 'Payment Requires Confirmation',
            icon: 'credit-card',
            theme: 'info',
            comingStatuses: [30, 40, 50, 90],
            isInternalListDefault: false,
            isReal: false,
        });
        this.$set(this.statuses, 20, {
            status: 20,
            stage: 20,
            name: 'PaymentRequiresAction',
            display: 'Payment Requires Action',
            icon: 'exclamation-circle',
            theme: 'warning',
            comingStatuses: [30, 40, 50, 90],
            isInternalListDefault: false,
            isReal: true,
        });
        this.$set(this.statuses, 21, {
            status: 21,
            stage: 20,
            name: 'PaymentProcessing',
            display: 'Payment Processing',
            icon: 'exclamation-circle',
            theme: 'warning',
            comingStatuses: [30, 40, 50, 90],
            isInternalListDefault: false,
            isReal: false,
        });
        this.$set(this.statuses, 22, {
            status: 22,
            stage: 20,
            name: 'PaymentRequiresCapture',
            display: 'Payment Requires Capture',
            icon: 'exclamation-circle',
            theme: 'warning',
            comingStatuses: [30, 40, 50, 90],
            isInternalListDefault: false,
            isReal: false,
        });
        this.$set(this.statuses, 25, {
            status: 25,
            stage: 20,
            name: 'PayOnAccountRequiresApproval',
            display: 'Pay on Account Requires Approval',
            icon: 'exclamation-circle',
            theme: 'warning',
            comingStatuses: [30, 40, 50, 90],
            isInternalListDefault: true,
            isReal: true,
        });
        this.$set(this.statuses, 30, {
            status: 30,
            stage: 30,
            name: 'PaymentSucceeded',
            display: 'Payment Successful',
            icon: 'check-circle',
            theme: 'success',
            comingStatuses: [40, 50, 90],
            isInternalListDefault: false,
            isReal: true,
        });
        this.$set(this.statuses, 39, {
            status: 39,
            stage: 30,
            name: 'PaymentCancelled',
            display: 'Payment Cancelled',
            icon: 'x-circle',
            theme: 'secondary',
            comingStatuses: [],
            isInternalListDefault: false,
            isReal: false,
        });
        this.$set(this.statuses, 40, {
            status: 40,
            stage: 40,
            name: 'OrderConfirming',
            display: 'Order Confirming',
            icon: 'inboxes',
            theme: 'info',
            comingStatuses: [50, 90],
            isInternalListDefault: true,
            isReal: true,
        });
        this.$set(this.statuses, 41, {
            status: 41,
            stage: 40,
            name: 'OrderDigitallyDelivered',
            display: 'Order Digitally Delivered',
            icon: 'box-seam',
            theme: 'success',
            comingStatuses: [90],
            isInternalListDefault: true,
            isReal: true,
        });
        this.$set(this.statuses, 50, {
            status: 50,
            stage: 50,
            name: 'OrderProcessing',
            display: 'Order Processing',
            icon: 'gear',
            theme: 'info',
            comingStatuses: [90],
            isInternalListDefault: true,
            isReal: true,
        });
        this.$set(this.statuses, 90, {
            status: 90,
            stage: 90,
            name: 'Completed',
            display: 'Order Dispatched',
            icon: 'box-seam',
            theme: 'success',
            comingStatuses: [],
            isInternalListDefault: false,
            isReal: true,
        });
        this.$set(this.statuses, 99, {
            status: 99,
            stage: 90,
            name: 'Cancelled',
            display: 'Cancelled',
            icon: 'x-circle',
            theme: 'danger',
            comingStatuses: [],
            isInternalListDefault: false,
            isReal: true,
        });

        this.$set(this.stages, 0, {
            stage: 0,
            name: 'Created',
            statuses: this.statuses.filter(item => item.stage === 0),
            isReal: true,
        });
        this.$set(this.stages, 10, {
            stage: 10,
            name: 'PaymentPending',
            statuses: this.statuses.filter(item => item.stage === 10),
            isReal: true,
        });
        this.$set(this.stages, 20, {
            stage: 20,
            name: 'PaymentProcessing',
            statuses: this.statuses.filter(item => item.stage === 20),
            isReal: true,
        });
        this.$set(this.stages, 30, {
            stage: 30,
            name: 'PaymentCompleted',
            statuses: this.statuses.filter(item => item.stage === 30),
            isReal: true,
        });
        this.$set(this.stages, 40, {
            stage: 40,
            name: 'OrderConfirming',
            statuses: this.statuses.filter(item => item.stage === 40),
            isReal: true,
        });
        this.$set(this.stages, 50, {
            stage: 50,
            name: 'OrderProcessing',
            statuses: this.statuses.filter(item => item.stage === 50),
            isReal: true,
        });
        this.$set(this.stages, 90, {
            stage: 90,
            name: 'Completed',
            statuses: this.statuses.filter(item => item.stage === 90),
            isReal: true,
        });

        for (var i = 0; i < this.statuses.length; i++) {
            if (!this.statuses[i]) this.$set(this.statuses, i, {
                status: i,
                name: `UnknownStatus${i}`,
                display: `Unknown Status ${i}`,
                icon: 'question-circle',
                theme: 'secondary',
                isInternalListDefault: false,
                isReal: false,
            })
        }

        for (var i = 0; i < this.stages.length; i = i + 10) {
            if (!this.stages[i]) this.$set(this.stages, i, {
                stage: i,
                name: `UnknownStage${i}`,
                statuses: [],
                isReal: false,
            })
        }
    },
}