export default class FavouritesDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'heart';
        this.title = this.dashboardComponent.$tc('objects.plurals.favourite', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/favourite/user-favourites');
        if (!response || !response.data) {
            this.data = [];
            return
        };

        this.data = response.data;
    }

    getId(favourite) {
        return favourite.favouriteId;
    }

    getIcon(favourite) {
        return favourite.icon;
    }

    getDisplay(favourite) {
        return favourite.name;
    }

    getVariant(favourite) {
        return null;
    }

    getUrl(favourite) {
        return favourite.url;
    }

    async handleActionAsync(favourite) {
        // Do nothing
    }

    getValue(favourite) {
        return null;
    }

    getValueDisplay(favourite) {
        return null;
    }

    getMinValue(favourite) {
        return null;
    }

    getMaxValue(favourite) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}