<template>
    <div class="bg-white d-flex justify-content-center align-items-center p-3"
         style="position: fixed; top:0; left:0; bottom: 0; right:0;">
        <div style="position: fixed; top:0; left:0; bottom: 0; right:0;"
             id="public-registration-create-page-background">
        </div>

        <validation-observer tag="div" class="z-100 border rounded bg-light d-flex flex-column" style="max-height: 100%; width: 32rem;" v-slot="{ errors, valid }">
            <div class="d-flex align-items-end">
                <h3 class="m-3 text-primary">{{$t('public-registration.register')}}</h3>
                <div class="small text-muted m-3 ml-auto">
                    {{step}}
                    {{$t('actions.of')}}
                    3</div>
            </div>

            <loading v-if="submitting" />
            <div v-else class="overflow-auto">
                <public-registration-form-steps v-model="publicRegistration"
                                                :step="step"/>
            </div>

            <div class="m-3 btn-list">
                <button v-if="step!=1"
                        class="btn btn-secondary"
                        :disabled="submitting"
                        @click="handleBack">
                    <b-icon icon="arrow-left-circle" class="inline" font-scale="0.95" />
                    {{$t('actions.back')}}
                </button>
                <button v-if="step!=3"
                        class="btn btn-primary"
                        :disabled="submitting || !valid"
                        @click="handleContinue">
                    <b-icon icon="arrow-right-circle" class="inline" font-scale="0.95" />
                    {{$t('actions.continue')}}
                </button>
                <button v-if="step==3"
                        class="btn btn-primary"
                        :disabled="submitting || !valid"
                        @click="handleRegister">
                    <b-icon icon="arrow-right" class="inline" font-scale="0.95" />
                    {{$t('public-registration.register')}}
                </button>
            </div>
        </validation-observer>
    </div>
</template>

<script>
    import ParticlesMixin from 'common/mixins/particles';
    import ApiData from 'common/mixins/api-data';
    import PublicRegistrationFormSteps from 'common/components/public-registration/public-registration-form-steps';

    export default {
        mixins: [ParticlesMixin, ApiData],

        props: {

        },

        components: {
            'public-registration-form-steps': PublicRegistrationFormSteps,
        },

        data() {
            return {
                publicRegistration: {
                    publicRegistrationId: 0,
                    reference: null,
                    emailAddress: null,
                    password: null,
                    firstName: null,
                    surname: null,
                    mobileNumber: null,
                    profilePictureAssetId: null,
                    profilePictureAsset: null,
                    isEngineer: null, // Set by URL query (type=installer)
                    name: null,
                    biography: null,
                    address: null,
                    phoneNumber: null,
                    logoAssetId: null,
                    logoAsset: null,
                    createDatetime: new Date(),
                    links: [
                        { publicRegistrationLinkId: 0, publicRegistrationId: 0, linkType: 6, link: null },
                        { publicRegistrationLinkId: 0, publicRegistrationId: 0, linkType: 1, link: null },
                        { publicRegistrationLinkId: 0, publicRegistrationId: 0, linkType: 2, link: null },
                        { publicRegistrationLinkId: 0, publicRegistrationId: 0, linkType: 3, link: null },
                        { publicRegistrationLinkId: 0, publicRegistrationId: 0, linkType: 4, link: null },
                        { publicRegistrationLinkId: 0, publicRegistrationId: 0, linkType: 5, link: null },
                    ],
                },
                loaded: false,
                submitting: false,
                step: 1, // 1: account type; 2: personal information; 3: company information;
            }
        },

        computed: {
            type() {
                if ('type' in this.$route.query) return this.$route.query['type'];
                return null;
            },
        },

        methods: {
            applyType() {
                if (this.type == 'installer') {
                    this.publicRegistration.isEngineer = true;
                    this.step = 2;
                } else if (this.type == 'siteowner') {
                    this.publicRegistration.isEngineer = false;
                    this.step = 2;
                }
            },
            logout() {
                try {
                    this.$auth.logout({
                        makeRequest: false,
                        params: {},
                        success: () => { },
                        error: () => { },
                        redirect: null,
                    });
                } catch (error) {
                    this.$debugLog(error);
                }
            },
            handleBack() {
                if (this.step > 1) {
                    this.step--;
                }
            },
            handleContinue() {
                if (this.step < 3) {
                    this.step++;
                }
            },
            async handleRegister() {
                if (this.submitting) return;

                try {
                    this.submitting = true;
                    this.apiErrors = [];

                    var response = await this.$http.post(`/publicregistration/reference`, this.publicRegistration);
                    if (!response || !response.data) this.throwNoResponseApiError();

                    this.$router.push(`/register/${response.data.reference}?message=success`);
                } catch (error) {
                    this.handleApiError(error, this.$t('actions.create-fail') + ' ' + this.$t('objects.public-registration').toLowerCase());
                } finally {
                    this.submitting = false;
                }
            },
        },

        watch: {
            type() {
                this.applyType();
            },
        },

        async mounted() {
            this.logout();

            if (this.isACS) await this.createParticles('public-registration-create-page-background');

            this.applyType();
        },
    };
</script>
