<script>
    import { kebabCase } from 'lodash';
    import SitePlanCommon from 'common/components/site-plan/site-plan-common';
    import SitePlanMarkerCommDevice from 'common/components/site-plan/site-plan-marker-comm-device';
    import SitePlanMarkerController from 'common/components/site-plan/site-plan-marker-controller';
    import SitePlanMarkerDoor from 'common/components/site-plan/site-plan-marker-door';
    import SitePlanMarkerReader from 'common/components/site-plan/site-plan-marker-reader';
    import SitePlanMarkerArea from 'common/components/site-plan/site-plan-marker-area';
    import SitePlanMarkerCamera from 'common/components/site-plan/site-plan-marker-camera';
    import SitePlanMarkerLabel from 'common/components/site-plan/site-plan-marker-label';

    export default {
        mixins: [SitePlanCommon],

        render: function (createElement) {
            var markerTag = 'site-plan-marker-' + kebabCase(this.value.sitePlanMarkerTypeName);
            var markerComponent = this.$options.components[markerTag];
            if (!markerComponent) return createElement('span', '');

            if (this.iconOnly) return createElement('b-icon', {
                props: {
                    'icon': this.icon,
                },
            });

            return createElement(markerTag, {
                props: {
                    'value': this.value,
                    'icon': this.icon,
                    'show-name': this.showName,
                    'editing': this.editing,
                    'hovering': this.hovering,
                },
                on: {
                    'update:value': this.handleUpdateValue,
                    delete: this.handleDelete,
                },
            });
        },

        props: {
            value: null,
            iconOnly: Boolean,
            showName: Boolean,
            editing: Boolean,
            hovering: Boolean
        },

        emits: [
            'update:value',
            'delete',
        ],

        components: {
            'site-plan-marker-comm-device': SitePlanMarkerCommDevice,
            'site-plan-marker-controller': SitePlanMarkerController,
            'site-plan-marker-door': SitePlanMarkerDoor,
            'site-plan-marker-reader': SitePlanMarkerReader,
            'site-plan-marker-area': SitePlanMarkerArea,
            'site-plan-marker-camera': SitePlanMarkerCamera,
            'site-plan-marker-label': SitePlanMarkerLabel,
        },

        data: function () {
            return {
            }
        },

        computed: {
            icon() {
                if (!this.value || !this.value.sitePlanMarkerTypeName) return '';
                switch (this.value.sitePlanMarkerTypeName) {
                    case 'CommDevice': return this.getIcons().commDevice.icon;
                    case 'Controller': 
                        if (!this.value || !this.value.controller || !this.value.controller.type) return this.getIcons().controller.icon;
                        switch (this.value.controller.type) {
                            case 'DC01': return this.getIcons().controllerDC01.icon;
                            case 'DC02': return this.getIcons().controllerDC02.icon;
                            case 'DC10': return this.getIcons().controllerDC10.icon;
                        }
                        return this.getIcons().controller.icon;
                    case 'Door': return this.getIcons().door.icon;
                    case 'Reader': return this.getIcons().reader.icon;
                    case 'Area': return this.getIcons().area.icon;
                    case 'Camera': return this.getIcons().camera.icon;
                    case 'Label':
                        if (!this.value || !this.value.labelIcon) return this.getIcons().label.icon;
                        return this.value.labelIcon;
                }
                return '';
            }
        },

        methods: {
            handleUpdateValue(newValue) {
                this.$emit('update:value', newValue);
            },

            handleDelete() {
                this.$emit('delete');
            },
        },

        watch: {
        },

        created() {
        },

        async mounted() {
        },
    }
</script>
