
export default {
    props: {
        showCheckboxes: null,
        fields: null,
        items: null,
        linkGenerator: Function,
        iconType: String,
        rowClicked: Function,
        
    },
    data() {
        
    },
    computed: {
        rowClasses() {
            var classes = ["row"];

            classes.push(`row-cols-${this.sm}`);
            classes.push(`row-cols-md-${this.md}`);
            classes.push(`row-cols-lg-${this.lg}`);
            classes.push(`row-cols-xl-${this.xl}`);

            return classes;
        },
        theme() {
            return 'light';
        }
    },
    mounted() {
        //Array.from(document.querySelectorAll('[data-toggle="tooltip"]'))
        //        .forEach((tooltipNode) => new Tooltip(tooltipNode));
    }
}