export default class FobHelper {
    constructor(vueComponent) {
        this.vueComponent = vueComponent;
    }
    async getFobType(fob) {
        var fobTypes = (await this.vueComponent.$http.get('/fobtype')).data;
        console.log(fobTypes)

        return fobTypes.filter(t => t.fobTypeId == fob.fobTypeId)[0].name
    } 

    async getKeyCodeAsync(mutableFob, originalFob) {
        var fobTypes = await this.vueComponent.$http.get('/fobtype');
        if (!fobTypes || !fobTypes.data) this.vueComponent.throwNoResponseApiError();
        fobTypes = fobTypes.data;
        var fobType = fobTypes.filter(ft => ft.fobTypeId == mutableFob.fobTypeId)[0];
        if (!fobType) this.vueComponent.throwNoResponseApiError();


        var credentialTypes = await this.vueComponent.$http.get('/fob/credential-types');
        if (!credentialTypes || !credentialTypes.data) this.vueComponent.throwNoResponseApiError();
        credentialTypes = credentialTypes.data;

        var credentialType = credentialTypes[fobType.credentialType];
        if (!credentialType) this.vueComponent.throwNoResponseApiError();



                return await this.getKeyCode(mutableFob, credentialType, originalFob);
    }

    async getKeyCode(mutableFob, credentialType, originalFob) {
        switch (credentialType.string) {
            case 'Fob':
                var keyCode = `0000000000${mutableFob.keyCode}`;
                keyCode = keyCode.substring(keyCode.length - 10);
                if (!/^[0-9A-F]{10}$/i.test(keyCode))
                    return null;
                else
                    return credentialType.nibble + '0' + keyCode;
            case 'ANPR':
                const enc = new TextEncoder();
                const hash = await crypto.subtle.digest('SHA-1', enc.encode(mutableFob.reference));

                const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
                const hashHex = hashArray.map((b) => b.toString(16))                    
                    .join("")
                    .slice(-10).toUpperCase();

                var padded = hashHex.padStart(11, '0');

                return "3" + padded;

            case 'RemoteOrRadio':
                // TODO
                return null;
            case 'PIN':
                var hexString = parseInt(mutableFob.reference).toString(16).toUpperCase();
                var padded = hexString.padStart(11, '0');

                return "1" + padded;
            case 'Smart':
                if (originalFob && originalFob.keyCode && originalFob.keyCode.length == 12 && originalFob.keyCode.startsWith(credentialType.nibble + '0')) {
                    return originalFob.keyCode;
                } else {
                    var random = Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    random += Math.floor(Math.random() * 16).toString(16).toUpperCase();
                    return credentialType.nibble + '0' + random;
                }
            default:
                return null;
        }
    }
}