import { render, staticRenderFns } from "./comm-device-inspection-form.vue?vue&type=template&id=38a90963&scoped=true"
import script from "./comm-device-inspection-form.vue?vue&type=script&lang=js"
export * from "./comm-device-inspection-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a90963",
  null
  
)

export default component.exports