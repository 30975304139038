export default class FobOverviewDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'key';
        this.title = this.dashboardComponent.$tc('objects.plurals.fob', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/fob/overview');
        if (!response || !response.data) {
            this.data = [];
            return
        };

        let data = [];
        let total = response.data.totalFobs;

        data.push({
            display: this.dashboardComponent.$t('fob.available'),
            url: '/administration/fob/available-cloud-fobs',
            value: response.data.totalNotIssued,
            min: 0,
            max: total,
        });

        data.push({
            display: this.dashboardComponent.$t('fob.activated'),
            url: null,
            value: response.data.totalIssued,
            min: 0,
            max: total,
        });

        data.push({
            display: this.dashboardComponent.$t('fob.total'),
            url: '/administration/fob/cloud-fobs',
            value: response.data.totalFobs,
            min: 0,
            max: total,
        });

        data.push({
            display: `${this.dashboardComponent.$tc('objects.plurals.group', 2)} ${this.dashboardComponent.$t('fob.activated')}`,
            url: null,
            value: response.data.totalFobsInGroups,
            min: 0,
            max: total,
        });

        data.push({
            display: `${this.dashboardComponent.$tc('objects.plurals.area', 2)} ${this.dashboardComponent.$t('fob.activated')}`,
            url: null,
            value: response.data.totalFobsInAreas,
            min: 0,
            max: total,
        });

        this.data = data;
    }

    getId(overview) {
        return overview.display;
    }

    getIcon(overview) {
        return null;
    }

    getDisplay(overview) {
        return overview.display;
    }

    getVariant(overview) {
        return null;
    }

    getUrl(overview) {
        return overview.url;
    }

    async handleActionAsync(overview) {
        // Do nothing
    }

    getValue(overview) {
        return overview.value;
    }

    getValueDisplay(overview) {
        return overview.value;
    }

    getMinValue(overview) {
        return overview.min;
    }

    getMaxValue(overview) {
        return overview.max;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}