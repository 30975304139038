import { render, staticRenderFns } from "./reader-interface-detail-compact.vue?vue&type=template&id=666a4666"
import script from "./reader-interface-detail-compact.vue?vue&type=script&lang=js"
export * from "./reader-interface-detail-compact.vue?vue&type=script&lang=js"
import style0 from "./reader-interface-detail-compact.vue?vue&type=style&index=0&id=666a4666&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports