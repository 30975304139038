export default class TimePeriodOverviewDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'clock';
        this.title = this.dashboardComponent.$t('dashboard.time-period-overview.name');
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/timeprofile/time-period/overview');
        if (!response || !response.data) {
            this.data = [];
            return
        };

        this.data = response.data;
    }

    getId(overview) {
        return `${overview.scope}:${overview.id}`;
    }

    getIcon(overview) {
        return null;
    }

    getDisplay(overview) {
        return overview.name;
    }

    getVariant(overview) {
        return null;
    }

    getUrl(overview) {
        switch (overview.scope) {
            case 'Client': return `/configuration/client/${overview.Id}/edit`;
            case 'Area': return `/administration/area/${overview.Id}/edit`;
            case 'Controller': return `/management/controller/${overview.Id}/edit`;
            default: return null;
        }
    }

    async handleActionAsync(overview) {
        // Do nothing
    }

    getValue(overview) {
        return overview.usedCount;
    }

    getValueDisplay(overview) {
        return `${Math.round(100 * overview.usedCount / overview.maxCount)}%`;
    }

    getMinValue(overview) {
        return overview.minCount;
    }

    getMaxValue(overview) {
        return overview.maxCount;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}