<template>
    <div class="d-flex">
        <notifications position="top center" width="70%">
            <template slot="body" slot-scope="props">
                <div v-bind:class="'alert ' + props.item.type + ' mt-2 mb-0'">
                    <button type="button" class="close" @click="props.close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 v-if="props.item.title" class="alert-heading"> {{ props.item.title }} </h4>
                    <span v-html="props.item.text" />
                </div>
            </template>
        </notifications>

        <aside class="sticky-top d-print-none bg-white" v-if="$auth.check()" :style="sidebarStyle">
            <side-menu-acs :wide="isSidebarWidened" @widen="sidebarWiden" @narrow="sidebarNarrow" />
        </aside>

        <transition name="fade">
            <loading-came v-if="!$auth.ready()" :branding="true" :overlay="true" style="height: 100%"></loading-came>

            <div v-if="$auth.check()" class="flex-fill d-flex flex-column">
                <header class="sticky-top border-bottom border-white bg-light text-grey d-flex d-print-none mb-4" style="height: 110px;">
                    <div class="flex-fill d-flex px-3 pt-2">
                        <!--<b-icon icon="heart" font-scale="2" class="m-2" />
            <b-icon icon="heart" font-scale="2" class="m-2" />
            <b-icon icon="heart" font-scale="2" class="m-2" />
            <b-icon icon="heart" font-scale="2" class="m-2" />
            <b-icon icon="heart" font-scale="2" class="m-2" />-->
                        <portal-target name="breadcrumb" class="mt-auto"></portal-target>
                    </div>

                    <client-switcher-dropdown class="px-3 py-2 my-auto" style="width: 360px;" />

                    <!--<universal-search class="px-3 py-2 my-auto" style="width: 360px;" />-->

                    <button class="position-absolute p-1 text-white border-0 d-block"
                            style="bottom: 0; transform: translate(-50%, 50%); border-radius: 100px; line-height: 0; background-color: var(--border); z-index: 100;"
                            v-if="isSidebarWidening"
                            @click="isSidebarWidening = false">
                        <b-icon icon="g:chevron_left" />
                    </button>
                    <button class="position-absolute p-1 text-white border-0 d-block"
                            style="bottom: 0; transform: translate(-50%, 50%); border-radius: 100px; line-height: 0; background-color: var(--border); z-index: 100;"
                            v-if="!isSidebarWidening"
                            @click="isSidebarWidening = true">
                        <b-icon icon="chevron-right" />
                    </button>


                </header>
                
                <div class="row mx-1">
                    <event-alarm-announcements v-if="$auth.check()" class="col-xl-10 offset-xl-1 mt-n2" />
                    <announcements v-if="$auth.check()" class="col-xl-10 offset-xl-1 mt-n2 mb-2" />
                </div>

                <main class="flex-fill" ref="main">
                    <router-view></router-view>
                </main>


                <footer class="mt-5 border-top border-white bg-light text-muted d-flex d-print-none" style="height: 60px;">
                    <div class="flex-fill my-auto p-3">
                        <span class="d-none d-md-inline">
                            Copyright
                        </span>
                        &copy;
                        <span class="d-none d-md-inline">
                            2024
                        </span>
                        CAME KMS
                        <span class="d-none d-lg-inline"> - All rights reserved</span>
                    </div>
                    <div class="py-auto p-3">
                        <feedback-button class="mr-2 text-muted" />
                        <router-link class="text-muted" :to="'/settings/about'">ABOUT</router-link>
                    </div>
                </footer>
            </div>

            <router-view v-else>
                Whoa, this should never be seen!
            </router-view>
        </transition>


        <b-modal :title="$t('client.setting-browsing-client')"
                 ref="loadingModal"
                 hide-footer hide-header-close
                 no-close-on-backdrop
                 no-close-on-esc>
            <loading />
        </b-modal>

    </div>
</template>

<script>
    import LoadingCame from 'common/components/loading-came';
    import UniversalSearch from '@/components/universal-search';
    import SideMenuAcs from './components/side-menu-acs';
    import FeedbackButton from 'common/components/common/feedback-button';
    import ClientSwitcherDropdown from 'common/components/client/client-switcher-dropdown';
    import PageTitle from 'common/mixins/page-title';
    import Announcements from 'common/components/announcement/announcements';
    import EventAlarmAnnouncements from 'common/components/event-alarm/event-alarm-announcements';

    export default {
        mixins: [PageTitle],

        components: {
            'loading-came': LoadingCame,
            'universal-search': UniversalSearch,
            'side-menu-acs': SideMenuAcs,
            'feedback-button': FeedbackButton,
            'client-switcher-dropdown': ClientSwitcherDropdown,
            'announcements': Announcements,
            'event-alarm-announcements': EventAlarmAnnouncements,
        },

        data() {
            return {
                isSidebarWidening: true,
                isSidebarWidened: true,
                sidebarWidth: 300,
            };
        },

        computed: {
            sidebarStyle() {
                return {
                    'height': '100vh',
                    width: `${this.sidebarWidth}px`,
                    'min-width': `${this.sidebarWidth}px`,
                };
            },
        },

        methods: {
            sidebarWiden() {
                this.isSidebarWidening = true;
            },

            sidebarNarrow() {
                if (!window.matchMedia('(min-width: 768px)').matches) {
                    this.isSidebarWidening = false;
                }
            },

            handleUserChange() {
                this.$store.commit('initialise');

                var user = this.$auth.user();

                if (user && user.userData && user.userData.workingClientId)
                    this.$store.commit('setBrowsingClient', user.userData.workingClientId);
            },
        },

        watch: {
            isSidebarWidening() {
                if (this.isSidebarWidening) {
                    if (this.sidebarWidth < 300) this.sidebarWidth += 10;
                } else {
                    this.isSidebarWidened = false;
                    if (this.sidebarWidth > 60) this.sidebarWidth -= 10;
                }
            },

            async sidebarWidth() {
                await new Promise((resolve) => window.setTimeout(resolve, 2));

                if (this.isSidebarWidening) {
                    if (this.sidebarWidth >= 300) {
                        this.isSidebarWidened = true;
                    } else {
                        this.sidebarWidth += 10;
                    }
                } else {
                    if (this.sidebarWidth > 60) {
                        this.sidebarWidth -= 10;
                    }
                }
            },


            async '$store.state.browsingClientId'(newBrowsingClientId, oldBrowsingClientId) {
                if (oldBrowsingClientId == -1) {
                    return;
                }

                this.$refs.loadingModal.show();

                try {
                    var user = this.$auth.user();

                    var fullPath = this.$router.currentRoute.fullPath;
                    var currentLocation = this.$router.history.getCurrentLocation();

                    await this.$http.post('/user/' + user.user.userId + '/set-browsing-client', { clientId: newBrowsingClientId });
                    await this.$auth.refresh();
                    await this.$auth.fetch();

                    // Replace the router with something else, this force a page reload in the coming this.$router.replace()
                    await this.$router.replace('/blank');
                    while (this.$router.currentRoute.fullPath != '/blank') {
                        await this.$nextTick();
                    }

                    if (fullPath != "/client-mismatch") {
                        this.$router.replace(fullPath);
                    } else {
                        this.$router.replace(currentLocation);
                    }

                    this.$store.commit('setBrowsingClientComplete');

                    this.$notify({ type: 'alert-success', text: 'Successfully changed browsing client' });
                } catch (error) {
                    this.$debug?.log('Error changing browsing client: ' + error);
                    this.$notify({ type: 'alert-danger', title: 'Error changing browsing client', text: error.message });
                } finally {
                    this.$refs.loadingModal.hide();
                }
            },

        },

        created() {
            // Load Vuex from local storage
            this.$watch(() => this.$auth.user(), this.handleUserChange)
            window.addEventListener('storage', this.$store.onStorage);

            document.documentElement.classList.add('acs');
            document.documentElement.classList.remove('skw');


            var lightDarkModeSetting = window.localStorage.getItem('shield-web-light-dark-mode');
            var mode = '1';

            if (lightDarkModeSetting)
                mode = lightDarkModeSetting;

            if ( mode == '3' || (mode == '2' && (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches))) {
                document.documentElement.classList.add('dark');
                document.documentElement.classList.remove('light');
            } else {
                document.documentElement.classList.add('light');
                document.documentElement.classList.remove('dark');
            }



            if (window.matchMedia('(min-width: 768px)').matches) {
                this.isSidebarWidening = true;
            } else {
                this.isSidebarWidening = false;
            }
        },

        beforeDestroy() {
            window.removeEventListener('storage', this.$store.onStorage);
        },
    }

</script>

<style>
</style>

