export default class AreasDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'building';
        this.title = this.dashboardComponent.$tc('objects.plurals.area', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/area?include_client_areas=false');
        if (!response || !response.data) {
            this.data = [];
            return
        };

        this.data = response.data;
    }

    getId(area) {
        return area.areaId;
    }

    getIcon(area) {
        return 'building';
    }

    getDisplay(area) {
        return area.name;
    }

    getVariant(area) {
        return null;
    }

    getUrl(area) {
        return `/administration/area/${area.areaId}/edit`;
    }

    async handleActionAsync(area) {
        // Do nothing
    }

    getValue(area) {
        return null;
    }

    getValueDisplay(area) {
        return null;
    }

    getMinValue(area) {
        return null;
    }

    getMaxValue(area) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}