import moment from 'moment'
export default {
    data() {
        return {
            announcementTypes: [
                {
                    name: "Information",
                    colour:"#bce8f1",
                    value: 1,
                    alertType: 'primary'
                },
                {
                    name: "Danger",
                    colour: "#ebccd1",
                    value: 2,
                    alertType: 'danger'
                },
                {
                    name: "Warning",
                    colour: "#faebcc",
                    value: 3,
                    alertType: 'warning'
                },
                {
                    name: "Success",
                    colour: "#d6e9c6",
                    value: 4,
                    alertType: 'success'
                }
            ],
            announcementTargets: [
                {
                    name: "Website Users",
                    value: 1
                },
                {
                    name: "Credential Users - Splash Screen",
                    value: 2
                },
                {
                    name: "Credential Users - Push Notification",
                    value: 3
                }
            ]
        }
    },
    methods: {
        getTypeObject(type) {
            return this.announcementTypes.find(obj => {
                return obj.value == type
            })
        },
        formatDate(date) {
            if (date)
                return moment(date).format("DD/MM/YYYY");
        },
        async setupRouterLink(element) {
            // Wait for the announcements to be rendered
            await this.$nextTick();

            // Change the behaviour of <a> inside announcements to <router-link>
            if (element) {
                var anchors = element.getElementsByTagName('a');
                for (var anchor of anchors) {
                    var href = anchor.getAttribute('href');

                    if (!href.includes('://')) {
                        // Process relative URLs only
                        anchor.addEventListener('click', (event) => {
                            this.$router.push(event.target.getAttribute('href'));
                            event.preventDefault();
                        });
                    }
                }
            }
        },
    }
}