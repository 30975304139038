import _ from 'lodash'
import HiddenTabsTab from './hidden-tabs-tab'

export const TabsMixin = {
    components: {
        'hidden-tabs-tab': HiddenTabsTab
    },
    data() {
        return {
            tabs: null,
            tab: '',
            hiddenTabNames: [],
            tabsObserver: null,
            bodyObserver: null,
        }
    },
    methods: {
        handleHashChange() {
            this.tab = window.location.hash;
        },
        navigateToHash(newHash) {
            if (history.replaceState) {
                history.replaceState(null, null, newHash);
            }
            else {
                location.hash = newHash;
            }
        },
        preSetup() {
            // Setup body observer, which reacts according to the existance of #maintabs
            var bodyElement = document.getElementsByTagName('body')[0];
            this.bodyObserver = new MutationObserver(_.debounce(this.readTabs, 100, {}));
            this.bodyObserver.observe(bodyElement, { childList: true, subtree: true });
        },
        setup() {
            var ulElement = document.querySelector('#maintabs > div > ul');

            if (!ulElement) return;

            if (!this.tabsObserver) this.tabsObserver = new MutationObserver(_.debounce(this.readTabs, 100, {}));
            this.tabsObserver.observe(ulElement, { childList: true, subtree: false });
        },
        finish() {
            if (this.tabsObserver)
                this.tabsObserver.disconnect();

            if (this.bodyObserver)
                this.bodyObserver.disconnect();
        },
        readTabs() {
            var ulElement = document.querySelector('#maintabs > div > ul');

            if (!ulElement) {
                this.tabs = [];
                return;
            }

            var liElements = document.querySelectorAll('#maintabs > div > ul > li');
            var toRehide = [];

            for (var i = 0; i < liElements.length; i++) {
                if (liElements[i].classList.contains('d-none')) {
                    toRehide.push(liElements[i]);
                    liElements[i].classList.remove("d-none");
                }
            }

            this.tabs = [];

            for (var i = 0; i < liElements.length; i++) {
                var li = liElements[i];
                var rect = li.getBoundingClientRect();

                var tabName = li.firstChild.dataset.tab;

                if (tabName && !this.tabs.some(t => t.tab == tabName))
                    this.tabs.push({
                        tab: tabName,
                        text: li.innerText,
                        boundingRect: rect,
                        element: li
                    });
            }

            for (var i = 0; i < toRehide.length; i++) {
                toRehide[i].classList.add("d-none");
            }

            this.runFixTabs();
        },
        runFixTabs() {
            var ulElement = document.querySelector('#maintabs > div > ul');

            if (!ulElement) return;

            var rect = ulElement.getBoundingClientRect();

            var maxWidth = rect.width;

            if (!this.tabs) {
                return;
            }

            var tabWidths = this.tabs.map(t => t.boundingRect.width);

            if (tabWidths.length == 0) {
                return;
            }

            var tabsToShow = [];
            var tabsToHide = [];
            var overflowWidth = 40;
            var workingWidth = 0;

            var maxTabWidth = tabWidths.reduce((prev, next) => prev + next);
            if (maxTabWidth <= maxWidth) {
                tabsToShow = this.tabs.slice();
            } else {
                // Try to show the active tab first
                var activeTab = this.tabs.find(t => t.tab == this.tab);
                if (activeTab) {
                    if (workingWidth + activeTab.boundingRect.width + overflowWidth <= maxWidth) {
                        tabsToShow.push(activeTab);
                        workingWidth += activeTab.boundingRect.width;
                    } else tabsToHide.push(activeTab);
                }

                for (var t of this.tabs) {
                    // Skip the active tab
                    if (t.tab != this.tab) {
                        if (workingWidth + t.boundingRect.width + overflowWidth <= maxWidth) {
                            tabsToShow.push(t);
                            workingWidth += t.boundingRect.width;
                        } else tabsToHide.push(t);
                    }
                }
            }

            var tabsToAdd = this.hiddenTabNames.filter(t => tabsToShow.some(tt => t.tab == tt.tab));
            var tabsToRemove = tabsToHide.filter(t => !this.hiddenTabNames.some(tt => t.tab == tt.tab));

            for (var i = 0; i < tabsToRemove.length; i++) {

                var items = document.querySelectorAll(`[data-tab="${tabsToRemove[i].tab}"]`);
                for (var j = 0; j < items.length; j++) {
                    items[j].parentNode.classList.add("d-none");
                }
                this.hiddenTabNames.push(tabsToRemove[i]);
            }

            for (var i = 0; i < tabsToAdd.length; i++) {
                var items = document.querySelectorAll(`[data-tab="${tabsToAdd[i].tab}"]`);
                for (var j = 0; j < items.length; j++) {
                    items[j].parentNode.classList.remove("d-none");
                }
                this.hiddenTabNames = this.hiddenTabNames.filter(t => t != null && t.tab != null && t.tab != tabsToAdd[i].tab);
            }

            // Ensure hidden are already hidden
            for (var i = 0; i < this.hiddenTabNames.length; i++) {
                var items = document.querySelectorAll(`[data-tab="${this.hiddenTabNames[i].tab}"]`);
                for (var j = 0; j < items.length; j++) {
                    items[j].parentNode.classList.add("d-none");
                }
            }
        },
        handleHiddenTabClick(tab) {
            this.tab = tab;
        }
    },
    created() {
        if (window.location.hash == '')
            this.navigateToHash('#details');

        this.tab = window.location.hash;

        window.addEventListener('hashchange', this.runFixTabs, false);
        window.addEventListener('resize', _.debounce(this.runFixTabs, 150));
    },
    async mounted() {
        await this.$nextTick();

        this.preSetup();
    },
    beforeDestroy() {
        this.finish();
    },
    watch: {
        tab(newTab, oldTab) {
            this.navigateToHash(newTab);
        },
        loaded(newLoaded, oldLoaded) {
            this.runFixTabs();
        },
        '$route.hash'(to, from) {
            this.tab = to;
        },
    },
}