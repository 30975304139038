export default {
    data() {
        return {
            dataTypes: [
                {
                    value: 0,
                    name: "Text",
                },
                {
                    value: 1,
                    name: "Number",
                },
                {
                    value: 2,
                    name: "File",
                },
                {
                    value: 3,
                    name: "Picture",
                }
            ],
            objectTypes: [
                {
                    value: 0,
                    name: "Person",
                }
            ]
        }
    },
    computed: {
        fieldIdValueId() {
            var customFieldValues = {}

            if (!this.customFieldData)
                return customFieldValues;

            for (var i = 0; i < this.customFields.length; i++) {
                var fieldData = this.customFieldData.filter(c => c.customFieldId == this.customFields[i].customFieldId);

                if (fieldData.length == 0)
                    customFieldValues[this.customFields[i].customFieldId] = null;
                else
                    customFieldValues[this.customFields[i].customFieldId] = fieldData[0].customFieldValueId;
            }

            return customFieldValues;
        }
    },
    methods: {
        getObjectTypeName(value) {
            return this.objectTypes.filter(ot => ot.value == value)[0].name
        },
        getObjectTypeValue(name) {
            return this.objectTypes.filter(ot => ot.name == name)[0].value
        },
        getDataTypeName(value) {
            return this.dataTypes.filter(dt => dt.value == value)[0].name
        },
        async getClientCustomFields() {
            this.loaded = false;
            this.customFields = [];
            if (this.$auth.check()) {
                let customFields = (await this.$http.get(`/customfield?objectType=` + this.objectTypeValue)).data;
                this.customFields = customFields;
            } else {
                this.customFields = [];
            }
            this.loaded = true;
        },
        getCustomFieldData(customFieldId) {
            var matchingFields = this.customFieldData.filter(c => c.customFieldId == customFieldId)

            if (matchingFields.length > 0)
                return matchingFields[0].value;
        },
        async downloadFile(customFieldValueId) {
            var response = (await this.$http.get(`/customfield/get-custom-field-file/` + customFieldValueId, { responseType: 'blob' }));
            var fieldData = this.customFieldData.filter(c => c.customFieldValueId == customFieldValueId)[0];
            
            
            const blob = new Blob([response.data], { type: fieldData.fileDataType });
            const link = document.createElement('a');

            link.href = URL.createObjectURL(blob)
            link.download = "download"
            link.click()
            URL.revokeObjectURL(link.href)
        }
    }
}