export default {
    data() {
        return {
            timeProfiles: [],
            loaded: false
        }
    },
    computed: {
        profileAvailable() {
            return !this.isACS ? this.timeProfiles.length < 20 : true;
        },
        periodCount() {
            return this.timeProfiles.reduce((total, current) => { return total + current.timePeriods.length }, 0);
        },
        periodAvailable() {
            return !this.isACS ? this.periodCount < 32 : this.periodCount < 128;
        }
    },
    methods: {
        async loadTimeProfiles(loaded) {
            this.loaded = false;
            this.timeProfiles = [];
            this.timeProfiles = (await this.$http.get("/timeprofile")).data;
            this.loaded = loaded;
        },
        getNextProfileNumber() {
            var profileNumbers = Array.from({ length: 20 }, (val, ind) => ind + 1);
            var availableProfiles = profileNumbers.filter(p => this.timeProfiles.findIndex((tp) => tp.profileNumber == p) == -1);

            return availableProfiles[0];
        },
        profileCompatibleApex(timeProfile) {
            if (timeProfile.timePeriods.length > 1)
                return false;

            var days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
            var period = timeProfile.timePeriods[0];
            
            var dayIndexs = days.map((day, index) => { return { day: day, index: index } }).filter(d => period[d.day]).map(d => d.index);
            console.log(dayIndexs);
            var consecutive = dayIndexs.every((d, i) => i === 0 || +d === +dayIndexs[i - 1] + 1);

            if (!consecutive)
                return false;

            return true;
        }
    }
}