import { camelCase } from 'lodash';

const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys(obj[key]),
            }),
            {},
        );
    }
    return obj;
};

export default {
    
    data() {
        return {
            signalrId: null
        }
    },
    computed: {
        signalrUnavailable() {
            return this.signalrId == null;
        },
        emailUnavailable() {
            var userEmail = this.$auth.user().user.email;
            if (!userEmail) return true;
            else return false;
        }
    },
    methods: {

         createSignalrGroup: async function () {
            try {
                var groupId = await this.$jobHub.invoke("createJobGroup");
                this.signalrId = groupId;
            }
            catch (error) {
                this.signalrId = null;
            }
        },

        signalRStart: function () {
            this.createSignalrGroup();
        },

        handleReturnReport: function (result) {
            console.log(result)
            var objResult = JSON.parse(result);
            var camelResult = camelizeKeys(objResult);
            console.log(camelResult);
            this.handleResults(camelResult);
        },

        handleReturnReportGZipped: async function (result) {
            this.$debugLog(`handleReturnReportGZipped(result[${result.length}])`);

            // Result is base64 encoded byte array of GZipped JSON
            // Decode base64 (convert ascii to binary)
            var binary = atob(result);
            // Convert binary string to character-number array
            var numbers = binary.split('').map(function (x) { return x.charCodeAt(0); });
            // Turn number array into byte-array
            var byteArray = new Uint8Array(numbers);
            // Use pako, a libaray base on zlib, to ungzip it
            var pako = require('pako');
            var text = pako.ungzip(byteArray, { to: 'string' });

            var objResult = JSON.parse(text);
            var camelResult = camelizeKeys(objResult);
            this.$debugLog(camelResult);
            this.handleResults(camelResult);
        }
    },

    mounted: function () {
        this.$jobHubStartRegistration(this.signalRStart.bind(this));
        this.$jobHub.on('returnReport', this.handleReturnReport);
        this.$jobHub.on('returnReportGZipped', this.handleReturnReportGZipped);
    },

    beforeDestroy() {
        this.$jobHub.off('returnReport', this.handleReturnReport);
        this.$jobHub.off('returnReportGZipped', this.handleReturnReportGZipped);
    },
}