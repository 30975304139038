    export default {
        props: {
            noticeMessage: {
                type: Object,
                default: () => { return {} }
            }
        },
        components: {},
        data() {
            return {
                messageColours: {},
                messageTitleColours: {},
                days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                messageFonts: [
                    {
                        name: "Default",
                        value: ""
                    },
                    {
                        name: "Yftoow - Regular",
                        value:"yftoow-regular"
                    },
                    {
                        name: "Hitmo 2.0 - Light",
                        value: "hitmo2-light"
                    },
                    {
                        name: "Hitmo 2.0 - Regular",
                        value: "hitmo2-regular"
                    },
                    
                    {
                        name: "Hitmo 2.0 - Bold",
                        value: "hitmo2-bold"
                    },
                    {
                        name: "Hitmo 2.0 - Black",
                        value: "hitmo2-black"
                    },
                    {
                        name: "Powerr - Thin",
                        value: "powerr-thin"
                    },
                    {
                        name: "Powerr - Extra Light",
                        value: "powerr-extra-light"
                    },
                    {
                        name: "Powerr - Light",
                        value: "powerr-light"
                    },
                    
                    {
                        name: "Powerr - Regular",
                        value: "powerr-regular"
                    },
                    {
                        name: "Powerr - Medium",
                        value: "powerr-medium"
                    },   
                    {
                        name: "Powerr - Semibold",
                        value: "powerr-semi-bold"
                    },
                    {
                        name: "Powerr - Bold",
                        value: "powerr-bold"
                    },
                    
                    {
                        name: "Powerr - Extra Bold",
                        value: "powerr-extra-bold"
                    },
                    
                    {
                        name: "Powerr - Black",
                        value: "powerr-black"
                    },
                    {
                        name: "Meraki",
                        value: "meraki"
                    },
                    {
                        name: "Fragment Mono - Italic",
                        value: "fragment-mono-italic"
                    },
                    {
                        name: "Fragment Mono - Regular",
                        value: "fragment-mono-regular"
                    },
                    {
                        name: "Alexandria",
                        value: "alexandria"
                    },
                    {
                        name: "Mint Sans - Extra Light",
                        value: "mint-sans-extra-light"
                    },
                    
                    {
                        name: "Mint Sans - Regular",
                        value: "mint-sans-regular"
                    },
                    {
                        name: "Mint Sans - Bold",
                        value: "mint-sans-bold"
                    },
                    {
                        name: "Libre Baskerville - Regular",
                        value: "libre-baskerville-regular"
                    }

                ]

            }
        },
        methods: {
            async getMessageColours() {
                var data = (await this.$http.get("/noticemessage/message-colours")).data
                this.messageColours = data;
            },
            async getMessageTitleColours() {
                var data = (await this.$http.get("/noticemessage/message-title-colours")).data
                this.messageTitleColours = data;
            },

            getDayOrdinal(d) {
                if (d > 3 && d < 21) return 'th';
                switch (d % 10) {
                    case 1: return "st";
                    case 2: return "nd";
                    case 3: return "rd";
                    default: return "th";
                }

            },

            
        },async created() {
            await this.getMessageColours();
            await this.getMessageTitleColours();

        }
    }