import { render, staticRenderFns } from "./include-client-groups-checkbox.vue?vue&type=template&id=1bbf1f28"
import script from "./include-client-groups-checkbox.vue?vue&type=script&lang=js"
export * from "./include-client-groups-checkbox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports