import { property, isEqual } from "lodash";

export const DoorFormMixin = {
    data() {
        return {
            createUpdateStatusMessages: [],
            nodes: [],
            addedNewNode:null
        }
    },
    methods: {
        selectExistingReader(reader) {
            if (reader.doors.length > 0) {
                reader.elevatorControl = true;
            }
            this.$set(this.mutableDoor, this.mutableDoor.readerOne == null ? 'readerOne' : 'readerTwo', reader);
        },
        handleNodeAdded(node) {
            this.nodes.push(node)
        },
        handleNodeOutputSelected(node, outputNumber) {

            if (node.type == "SEM") return;

            this.$bvModal.msgBoxConfirm(this.$t('door.set-inputs-to-output-node'), {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: this.$t('actions.yes'),
                cancelTitle: this.$t('actions.no'),
                footerClass: '',
                hideHeaderClose: false,
                centered: false
            })
                .then(value => {

                    if (!value) return;
                    
                    var inputSetups = [
                        { inputLocation: 'inputDoorContact', inputLocationName: this.$t('object-properties.door.input-door-contact') },
                        { inputLocation: 'inputDoorEntry', inputLocationName: this.$t('object-properties.door.input-door-entry') },
                        { inputLocation: 'inputEmergencyUnlock', inputLocationName: this.$t('object-properties.door.input-emergency-unlock') },
                        { inputLocation: 'inputRte', inputLocationName: this.$t('object-properties.door.input-rte') },
                        //{ inputLocation: 'inputTradesButton', inputLocationName: this.$t('object-properties.door.input-trades-button') }
                    ];

                    for (var i = 0; i < inputSetups.length; i++) {

                        var inputSetup = inputSetups[i];
                        var existingInput = this.mutableDoor[inputSetup.inputLocation];
                        var nodeInputNumber = (i + 1);

                        if (node.type == "LockController") {
                            if (inputSetup.inputLocation == 'inputDoorEntry') nodeInputNumber = 1;
                            else if (inputSetup.inputLocation == 'inputEmergencyUnlock') nodeInputNumber = 2;
                            // input 3 is global fire which isn't associated with a door
                            else if (inputSetup.inputLocation == 'inputDoorContact') nodeInputNumber = 4;
                            else if (inputSetup.inputLocation == 'inputRte') nodeInputNumber = 5;
                        }
                        else if (node.type == "System Controller") {
                            if (inputSetup.inputLocation == 'inputDoorEntry')
                                nodeInputNumber = (outputNumber == "1" || outputNumber == "3" || outputNumber == "5") ? 1 : 4;
                            else if (inputSetup.inputLocation == 'inputEmergencyUnlock') 
                                nodeInputNumber = (outputNumber == "1" || outputNumber == "3" || outputNumber == "5") ? 2 : 5;
                            // input 3 is global fire which isn't associated with a door
                            else if (inputSetup.inputLocation == 'inputDoorContact') 
                                nodeInputNumber = (outputNumber == "1" || outputNumber == "3" || outputNumber == "5") ? 7 : 9;
                            else if (inputSetup.inputLocation == 'inputRte') 
                                nodeInputNumber = (outputNumber == "1" || outputNumber == "3" || outputNumber == "5") ? 8 : 10;
                        }

                        

                        if (existingInput) {
                            existingInput.nodeInstanceId = node.nodeId;
                            existingInput.node = node;
                            existingInput.number = nodeInputNumber;
                            this.$set(this.mutableDoor, inputSetup.inputLocation, existingInput);
                        }
                        else {
                            this.addInput(inputSetup.inputLocation, inputSetup.inputLocationName, node, nodeInputNumber);
                        }
                    }


                })
                .catch(err => {
                    // An error occurred
                })


        },
        nodeTypeToClass(nodeType) {
            switch (nodeType) {
                case 'input': return 15;
                case 'output': return 16;
                case 'reader': return 4;
                case 'readerinterface': return 18;
                case 'lock': return 3;
                case 'iox': return 24;
                case 'bim': return 25;
                default: return 0;
            }
        },
        async addNode(controller, nodeType) {

            var controllerNodes = (await this.$http.get(`/controller/${controller.controllerId}/node-instances`)).data;

            var firstAvailableInstanceId = 1;

            while (controllerNodes.indexOf(firstAvailableInstanceId) >= 0)
                firstAvailableInstanceId++;

            var node = {
                controllerId: controller.controllerId,
                commDeviceId: controller.commDeviceId,
                clientId: controller.clientId,
                name: `N${firstAvailableInstanceId}: ${nodeType}`,
                type: nodeType,
                class: this.nodeTypeToClass(nodeType),
                instanceId: firstAvailableInstanceId
            }

            return node;
        },
        async addReader(readerType) {
            var reader = null;

            if (readerType == 'reader') {
                reader = {
                    controllerId: this.mutableDoor.controllerId,
                    controller: this.mutableDoor.controller,
                    readerType: 'ACSReader',
                    direction: 0,
                    format: 0,
                    wiegandChannel: 0,
                    osdpAddress: 0,
                    osdpType: 0,
                    areaNumber: 0
                };

                if (this.mutableDoor.number) {
                    var guessedChannelNumber = ((this.mutableDoor.number + (this.mutableDoor.readerOne != null ? 1 : 0)) - 1);
                    reader.wiegandChannel = guessedChannelNumber;
                    reader.osdpAddress = guessedChannelNumber;
                }
            }
            else if (readerType == 'bus-reader') {
                var node = await this.addNode(this.controller,'reader');
                reader = {
                    controllerId: this.mutableDoor.controllerId,
                    controller: this.mutableDoor.controller,
                    readerType: 'SK4Reader',
                    direction: 0,
                    format: 0,
                    node: node
                };
            }
            else if (readerType == 'reader-interface-reader') {
                reader = {
                    controllerId: this.mutableDoor.controllerId,
                    controller: this.mutableDoor.controller,
                    readerType: 'SK3Reader',
                    direction: 0,
                    format: 0
                };
            }
            else return;

            this.$set(this.mutableDoor, this.mutableDoor.readerOne == null ? 'readerOne' : 'readerTwo', reader);
        },
        async addLockController(lockControllerType) {
            var lockController = null;

            if (lockControllerType == 'bus-lock-controller') {
                var node = await this.addNode(this.controller, 'lock');

                lockController = {
                    controllerId: this.mutableDoor.controllerId,
                    node: node
                };

            }
            else if (lockControllerType == 'system-controller-lock-one') {

                var scNode = this.nodes.find(n => n.type == 'System Controller');
                var scLockOne = scNode.configurationObjects.find(o => o.instanceId == 1 && o.type == 'LockController');

                lockController = scLockOne;
            }
            else if (lockControllerType == 'system-controller-lock-two') {

                var scNode = this.nodes.find(n => n.type == 'System Controller');
                var scLockTwo = scNode.configurationObjects.find(o => o.instanceId == 2 && o.type == 'LockController');

                lockController = scLockTwo;
            }
            else return;

            this.$set(this.mutableDoor, this.mutableDoor.lockOne == null ? 'lockOne' : 'lockTwo', lockController);
        },
        addInput(inputLocation, inputLocationName, node, nodeInputNumber) {

            var inputs = [
                this.mutableDoor.inputDoorContact,
                this.mutableDoor.inputDoorEntry,
                this.mutableDoor.inputEmergencyUnlock,
                this.mutableDoor.inputRte,
                this.mutableDoor.inputtradesButton
            ]

            var instanceOffset = inputs.reduce(
                (previousValue, input) => previousValue + (input == null ? 0 : (input.inputId == null || input.inputId == 0 ? 1 : 0)),
                0
            );


            var inputName = `D${this.mutableDoor.number}: ${inputLocationName}`;
            inputName = inputName.replace(/(.{17})..+/, "$1...");

            var input = {
                controllerId: this.mutableDoor.controllerId,
                controller: this.mutableDoor.controller,
                function: 0,
                name: inputName,
                instanceIdOffset: instanceOffset
            };

            if (node && nodeInputNumber) {
                input.node = node;
                input.nodeInstanceId = node.nodeId;
                input.number = nodeInputNumber;
            }

            this.$set(this.mutableDoor, inputLocation, input);
        },
        addOutput() {

            var outputs = this.mutableDoor.outputs;
            if (outputs == null)
                outputs = [];

            var instanceOffset = outputs.reduce(
                (previousValue, output) => previousValue + (output.outputId == null || output.outputId == 0 ? 1 : 0),
                0
            );

            var output = {
                controllerId: this.mutableDoor.controllerId,
                controller: this.mutableDoor.controller,
                function: 0,
                name: `D${this.mutableDoor.number}-O${outputs.length + 1}`,
                instanceIdOffset: instanceOffset,
                onTime: 0,
                offTime: 0
            };
           
            outputs.push(output);
            this.$set(this.mutableDoor, 'outputs', outputs);
        },
        removeOutput(outputIndex) {
            var outputs = this.mutableDoor.outputs;
            outputs.splice(outputIndex, 1); // 2nd parameter means remove one item only
            this.$set(this.mutableDoor, 'outputs', outputs);
        },
        selectReaderInterfaceChannelForReader(readerNumber, readerInterface, channelNumber) {
            var reader = readerNumber == 1 ? this.mutableDoor.readerOne : this.mutableDoor.readerTwo;
            this.$set(reader, 'readerInterfaceAsOne', null);
            this.$set(reader, 'readerInterfaceAsTwo', null);
            this.$set(reader, channelNumber == 1 ? 'readerInterfaceAsOne' : 'readerInterfaceAsTwo', readerInterface);
        },
        pushStatusMessage(message) {
            this.createUpdateStatusMessages.push(message);
        },        
        async createUpdateDoorObjects() {
            this.$bvModal.show('create-update-modal');

            this.apiErrors = [];
            this.waiting = true;
            this.createUpdateStatusMessages = [];

            var controller = this.controller;
            var outputs = [];

            try {
                await this.handleProperty('readerOne', this.createUpdateReader, this.deleteReader, (reader) => reader.readerId);
                await this.handleProperty('readerTwo', this.createUpdateReader, this.deleteReader, (reader) => reader.readerId);

                if (controller.variant < 2) {
                    await this.handleProperty('lockOne', this.createUpdateLock, this.deleteLock, (lockController) => lockController.lockControllerId);
                    await this.handleProperty('lockTwo', this.createUpdateLock, this.deleteLock, (lockController) => lockController.lockControllerId);
                }
                else {
                    await this.handleProperty('inputDoorContact', this.createUpdateInput, this.deleteInput, (input) => input.inputId);
                    await this.handleProperty('inputDoorEntry', this.createUpdateInput, this.deleteInput, (input) => input.inputId);
                    await this.handleProperty('inputEmergencyUnlock', this.createUpdateInput, this.deleteInput, (input) => input.inputId);
                    await this.handleProperty('inputRte', this.createUpdateInput, this.deleteInput, (input) => input.inputId);
                    await this.handleProperty('inputTradesButton', this.createUpdateInput, this.deleteInput, (input) => input.inputId);

                    if (this.mutableDoor.outputs) {
                        outputs = this.mutableDoor.outputs;
                    }
                }

                this.mutableDoor = await this.createUpdateDoor(this.mutableDoor);

                if (outputs) {
                    for (var i = 0; i < outputs.length; i++) {
                        var output = outputs[i];

                        if (this.door.outputs) {
                            var originalOutput = this.door.outputs.find(o => o.outputId == output.outputId);
                            if (isEqual(output, originalOutput)) {
                                continue;
                            }
                        }
                        
                        output.triggerSource = this.mutableDoor.number;
                        output.triggerType = 1;
                        output = await this.createUpdateOutput(output);
                        outputs[i] = output;
                    }
                }

            }
            finally {
                this.waiting = true;
                this.$bvModal.hide('create-update-modal');
            }
        },
        async handleProperty(propertyName, createUpdateMethod, deleteMethod, getIdMethod) {
            var object = this.mutableDoor[propertyName];
            var originalObject = this.door[propertyName];

            if (object != null) {
                
                if (isEqual(object, originalObject)) {
                    return;
                }
   
                var updatedObject = await createUpdateMethod(object)
                this.$set(this.mutableDoor, propertyName, updatedObject);
                this.$set(this.mutableDoor, propertyName + 'Id', getIdMethod(updatedObject));
            }
            else if (object == null && originalObject != null) {
                await deleteMethod(originalObject);
            }
        },
        async createUpdateDoor(door) {
            var message = door.doorId <= 0 ? this.$t('door.creating-door') :this.$t('door.updating-door');
            return await this.createUpdateObject(door, door.doorId, message, 'door')
        },
        async createUpdateNode(node) {
            var message = node.nodeId <= 0 ? this.$t('door.creating-node') : this.$t('door.updating-node');
            return await this.createUpdateObject(node, node.nodeId, message, 'node')            
        },
        async createUpdateReaderInterface(readerInterface) {
            if (readerInterface.node) {
                var node = await this.createUpdateNode(readerInterface.node);
                readerInterface.node = node;
                readerInterface.nodeInstanceId = node.nodeId;
            }

            var message = readerInterface.readerInterfaceId <= 0 ? this.$t('door.creating-reader-interface') : this.$t('door.updating-reader-interface');
            return await this.createUpdateObject(readerInterface, readerInterface.readerInterfaceId, message, 'readerinterface')
        },
        async createUpdateReader(reader) {

            if (reader.node) {
                var node = await this.createUpdateNode(reader.node);
                reader.node = node;
                reader.nodeInstanceId = node.nodeId;
            }


            var message = reader.readerId <= 0 ? this.$t('door.creating-reader') : this.$t('door.updating-reader');
            var resultReader = await this.createUpdateObject(reader, reader.readerId, message, 'reader');


            if (reader.readerInterfaceAsOne) {
                var readerInterface = reader.readerInterfaceAsOne;
                readerInterface.readerOneId = resultReader.readerId;
                readerInterface = await this.createUpdateReaderInterface(readerInterface);
            }
            if (reader.readerInterfaceAsTwo) {
                var readerInterface = reader.readerInterfaceAsTwo;
                readerInterface.readerTwoId = resultReader.readerId;
                readerInterface = await this.createUpdateReaderInterface(readerInterface);
            }

            return resultReader;
        },
        async createUpdateLock(lockController) {

            if (lockController.node) {
                var node = await this.createUpdateNode(lockController.node);
                lockController.node = node;
                lockController.nodeInstanceId = node.nodeId;
            }

            if (lockController.inputOne) {
                var input = await this.createUpdateInput(lockController.inputOne);
                lockController.inputOne = input;
                lockController.inputOneId = input.inputId;
            }

            if (lockController.inputTwo) {
                var input = await this.createUpdateInput(lockController.inputTwo);
                lockController.inputTwo = input;
                lockController.inputTwoId = input.inputId;
            }

            if (lockController.inputThree) {
                var input = await this.createUpdateInput(lockController.inputThree);
                lockController.inputThree = input;
                lockController.inputThreeId = input.inputId;
            }


            var message = lockController.lockControllerId <= 0 ? this.$t('door.creating-lock-controlle') : this.$t('door.updating-lock-controller');
            return await this.createUpdateObject(lockController, lockController.lockId, message, 'lockcontroller')
        },
        async createUpdateSem(sem) {

            if (sem.node) {
                var node = await this.createUpdateNode(sem.node);
                sem.node = node;
                sem.nodeInstanceId = node.nodeId;
            }

            var message = sem.ioxId <= 0 ? this.$t('door.creating-sem') : this.$t('door.updating-sem');
            return await this.createUpdateObject(sem, sem.ioxId, message, 'sem')
        },
        async createUpdateInputOutputNode(inputOutput) {
            if (inputOutput.node) {
                var node;
                if (inputOutput.node.nodeId != 0 || !this.addedNewNode)
                    node = await this.createUpdateNode(inputOutput.node);
                else {
                    node = this.addedNewNode;
                }

                if (inputOutput.node.nodeId == 0 && !this.addedNewNode) {
                    this.addedNewNode = node;

                    if (node.type == "LockController") {
                        var lockController = {
                            node: node,
                            instanceId: 0,
                            nodeInstanceId: node.nodeId,
                            controllerId: this.controller.controllerId
                        };

                        await this.createUpdateLock(lockController);
                    }
                    else if (node.type == "SEM") {
                        var sem = {
                            node: node,
                            nodeInstanceId: node.nodeId,
                            controllerId: this.controller.controllerId,
                            instanceId:0
                        };

                        await this.createUpdateSem(sem);
                    }
                }

                inputOutput.node = node;
                inputOutput.nodeInstanceId = node.nodeId;
            }

            return inputOutput;
        },
        async createUpdateInput(input) {

            var input = await this.createUpdateInputOutputNode(input);

            var message = input.inputId <= 0 ? this.$t('door.creating-input') : this.$t('door.updating-input');
            return await this.createUpdateObject(input, input.inputId, message, 'input')
        },
        async createUpdateOutput(output) {

            output = await this.createUpdateInputOutputNode(output);

            var message = output.outputId <= 0 ? this.$t('door.creating-output') : this.$t('door.updating-output');
            return await this.createUpdateObject(output, output.outputId, message, 'output')
        },
        async createUpdateObject(object, objectId, message, url, addNode) {

            this.pushStatusMessage(message);

            try {
                if (objectId > 0)
                    object = (await this.$http.put(`/${url}/${objectId}`, object)).data;
                else 
                    object = (await this.$http.post(`/${url}`, object)).data;
            }
            catch (error) {
                this.apiErrors = error.response.data;
                throw error;
            }

            return object;
        },

        async deleteNode(node) {
            var message = this.$t('door.deleting-node');
            return await this.deleteObject(node.nodeId, message, 'node')
        },
        async deleteReaderInterface(readerInterface) {
            var message = this.$t('door.deleting-reader-interface');
            return await this.deleteObject(readerInterface.readerInterfaceId, message, 'readerinterface')
        },
        async deleteReader(reader) {
            var message = this.$t('door.deleting-reader');
            
            if (this.mutableDoor.readerOneId == reader.readerId)
                this.mutableDoor.readerOneId = null;
            else if (this.mutableDoor.readerTwoId == reader.readerId)
                this.mutableDoor.readerTwoId = null;

            await this.createUpdateDoor(this.mutableDoor);

            return await this.deleteObject(reader.readerId, message, 'reader')
        },
        async deleteInput(input) {
            var message = this.$t('door.deleting-input');
            return await this.deleteObject(input.inputId, message, 'input')
        },
        async deleteOutput(output) {
            var message = this.$t('door.deleting-output');
            return await this.deleteObject(output.outputId, message, 'output')
        },
        async deleteLock(lockController) {
            var message = this.$t('door.deleting-lock-controller');
            return await this.deleteObject(lockController.lockControllerId, message, 'lockcontroller')
        },
        async deleteObject(objectId, message, url) {

            this.pushStatusMessage(message);

            try {
                var result = (await this.$http.delete(`/${url}/${objectId}`))
            }
            catch (error) {
                this.apiErrors = error.response.data;
                throw error;
            }

            return true;
        },

    },
    async created() {
        
    },
    mounted() {
    },
    watch: {
    },
}