const onlineStates = [
    'Online',
    'Downloading',
    'AttentionRequired',
    'UpdatingFirmware',
    'SendingData',
    'CapacityFull',
];

export default class ControllerOverviewDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'cpu';
        this.title = this.dashboardComponent.$tc('objects.plurals.controller', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        var response = await this.dashboardComponent.$http.get('/controller');
        if (!response || !response.data) {
            this.data = [];
            return;
        }

        var data = [
            {
                key: 'online',
                display: this.dashboardComponent.$t('dashboard.controller-overview.online'),
                value: 0,
                min: 0,
                max: 0,
            },
            {
                key: 'offline',
                display: this.dashboardComponent.$t('dashboard.controller-overview.offline'),
                value: 0,
                min: 0,
                max: 0,
            },
            {
                key: 'total',
                display: this.dashboardComponent.$t('dashboard.controller-overview.total'),
                value: 0,
                min: 0,
                max: 0,
            },
        ];

        for (var controller of response.data) {
            data[2].value++;
            if (onlineStates.includes(controller.state))
                data[0].value++;
            else
                data[1].value++;
        }

        data[0].max = data[2].value;
        data[1].max = data[2].value;
        data[2].max = data[2].value;

        this.data = data;
    }

    getId(overview) {
        return overview.key;
    }

    getIcon(overview) {
        return 'cpu';
    }

    getDisplay(overview) {
        return overview.display;
    }

    getVariant(overview) {
        return null;
    }

    getUrl(overview) {
        return null;
    }

    async handleActionAsync(overview) {
        // Do nothing
    }

    getValue(overview) {
        return overview.value;
    }

    getValueDisplay(overview) {
        return overview.value;
    }

    getMinValue(overview) {
        return overview.min;
    }

    getMaxValue(overview) {
        return overview.max;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}