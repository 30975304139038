<template>
    <div class="universal-search-wrapper">
        <vue-simple-suggest ref="search"
                            v-model="searchQuery"
                            display-attribute="title"
                            value-attribute="url"
                            :list="getResults"
                            :styles="autoCompleteStyle"
                            :destyled="true"
                            :min-length="1"
                            :placeholder="$t('search.placeholder')"
                            type="search"
                            :debounce="200"
                            :filter-by-query="false"
                            @request-start="onRequestStart"
                            @request-done="onRequestDone"
                            @request-failed="onRequestFailed"
                            @select="onSuggestSelect"
                            @input="onInput">

            <template v-slot:suggestion-item="{ suggestion }">
                <div>
                    <strong v-html="suggestion.title" />
                    <span class="badge badge-client" v-if="suggestion.client">{{suggestion.client}}</span>
                </div>
                <div class="small" v-html="suggestion.description"></div>
            </template>

            <template v-slot:misc-item-above="{ suggestions, query }">
                <div v-if="!loading" class="list-group-item list-group-item-light p-2 pl-3 d-flex align-items-center"
                     @mouseenter="hackHover" and @mouseleave="hackHover">
                    <div class="flex-fill">{{ resultsLength }} {{$t('actions.results-found-for')}} '{{ query }}'</div>
                    <button class="btn btn-sm btn-success" :to="`/search?query=${searchQuery}`" @click="handleShowMoreClick">{{$t('search.show-more')}}</button>
                </div>
                <div v-if="loading" class="list-group-item d-flex align-items-center">
                    <b-spinner class="mr-3" type="grow" :label="$t('actions.loading')" />
                    <div>{{$t('actions.loading')}}...</div>
                </div>
            </template>
        </vue-simple-suggest>

        
    </div>
</template>

<script>
    import VueSimpleSuggest from "vue-simple-suggest";

    export default {
        components: {
            VueSimpleSuggest
        },
        props: {
        },
        data() {
            return {
                searchQuery: "",
                clientContact: null,
                loading: false,
                autoCompleteStyle: {
                    vueSimpleSuggest: "position-relative",
                    inputWrapper: "",
                    defaultInput: "form-control ",
                    suggestions: "position-absolute list-group search-list z-100 w-100",
                    suggestItem:
                        "list-group-item list-group-item-action cursor-pointer search-item p-2"
                },
                resultsLength: 0,
                dummyItem: { title: '', url: '/blank' },
            };
        },

        methods: {
            async getResults(query) {
                var results = (
                    await this.$http.get(process.env.VUE_APP_UNIVERSALSEARCH_URL + `/collection?query=${query}`)
                ).data;
                this.resultsLength = results.totalCount;
                return results.datums;
            },

            onRequestStart(value) {
                this.loading = true;
                this.$refs.search.clearSuggestions();
            },
            onRequestDone(e) {
                this.loading = false;
            },
            onRequestFailed(e) {
                this.loading = false;
            },
            onSuggestSelect(item) {
                this.$router.push(item.url);
                this.searchQuery = '';
                this.$refs.search.setText('');
                this.$refs.search.hideList();
                this.$nextTick(() => {
                    this.searchQuery = '';
                    this.$refs.search.setText('');
                    this.$refs.search.hideList();
                    this.$nextTick(() => {
                        this.searchQuery = '';
                        this.$refs.search.setText('');
                        this.$refs.search.hideList();
                    })
                })
            },
            onInput(searchValue) {
                if (searchValue == "")
                    return;

                this.$refs.search.showList();
            },

            hackHover(event) {
                // Suggestion list is destructed before any events can be fire in the misc item.
                // So a hack is required to keep the event being fired normally.
                //
                // vue-simple-suggest will check if an suggestion list item is hovered on blur (this happen befor click event).
                // If so, it will skip destroying the suggestion list and let the selection possible.
                //
                // This hack simulates an dummy item is hovered when a misc list item is hovered, so the suggestion list will not be destroyed.
                // 1. Add @mouseenter="hackHover" and @mouseleave="hackHover" to the misc list item (usually a div with class list-group-item);
                // 2. Add ref="suggest" to the vue-simple-suggest;
                // 3. Pass in a dummy item with the proper display and the value attributes.
                // 4. Call this.$refs.suggest.hideList(); in your handler
                if (event.type === 'mouseenter') {
                    this.$refs.search.hover(this.dummyItem, event.currentTarget);
                } else if (event.type === 'mouseleave') {
                    this.$refs.search.hover(null);
                }
            },

            handleShowMoreClick() {
                this.$refs.search.hideList();

                this.$router.push(`/search?query=${this.searchQuery}`);
            },
        },
        watch: {

        },
        created() {
        }
    };
</script>

<style>
    .z-100 {
        z-index: 100;
    }

    .search-list li {
        list-style-type: none;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .search-item.hover {
        color: #495057;
        text-decoration: none;
        background-color: #f8f9fa;
    }


    html.acs .universal-search-wrapper input {
        border-radius: 2rem;
    }

</style>