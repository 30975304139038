export default {
    props: {
        displayFilter: {
            type: Boolean,
            default: false
        },
        disableEditing: {
            type: Boolean,
            default: false
        },
        showCheckboxes: {
            type: Boolean,
            default: false
        },
        renderLinks: {
            type: Boolean,
            default: true
        },
        displayEditButton: {
            type: Boolean,
            default: false
        },
        forceFullDataTree: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            editedPreviews: null,
            finishedSelect: { type: Boolean },
            findNodeFunction: null,
            editing: false,
            editedObjects: [],
            progressCounter: 0,
            waiting: false,
            editObjectErrors: [],
            waitingPreviewChanges: false,
            noItems: null
        }
    },
    computed: {
        fetchFunction() {

            if (this.nodesToFind.length > 0 || this.forceFullDataTree)
                return this.fetchFullTreeData;
            else
                return this.fetchTreeData;
        },
        modalTitleVocabulary() {
            if (this.editedObjects.length == 0)
                return '';
            var types = this.editedObjects.map(o => o.nodeType);
            var uniqueTypes = [... new Set(types)];
            if (uniqueTypes.length == 1)
                return this.$tc('objects.plurals.' + uniqueTypes[0], uniqueTypes.length);
            else
                return this.$tc('objects.plurals.object', uniqueTypes.length);
        },
        loadingModalTitle() {
            return `${this.$t('actions.move')} ${this.editedObjects.length} ${this.modalTitleVocabulary}`;
        },
        confirmModalTitle() {
            return `${this.$t('actions.confirm')} ${this.$t('actions.move')} ${this.editedObjects.length} ${this.modalTitleVocabulary}`
        }
    },
    methods: {
        selectAll() {
            this.$refs.dtree.selectAll();
        },
        deselectAll() {
            this.$refs.dtree.clearSelection();
        },
        findAllCheckedNodes() {
            var doorNodes = this.$refs.dtree.$refs.tree.find({ state: { checked: true }, data: { nodeType: 'door' } }, true);
            var areaNodes = this.$refs.dtree.$refs.tree.find({ state: { checked: true }, data: { nodeType: 'area' } }, true);
            var fobNodes = this.$refs.dtree.$refs.tree.find({ state: { checked: true }, data: { nodeType: 'fob' } }, true);
            var noticeBoardNodes = this.$refs.dtree.$refs.tree.find({ state: { checked: true }, data: { nodeType: 'notice-board' } }, true);
            var groupNodes = this.$refs.dtree.$refs.tree.find({ state: { checked: true }, data: { nodeType: 'group' } }, true);

            var groupIds = groupNodes.map(n => n.data.groupId)
            var doorIds = doorNodes.map(d => d.data.doorId);
            var areaIds = areaNodes.map(a => a.data.areaId);
            var fobIds = fobNodes.map(a => a.data.fobId);
            var noticeBoardIds = noticeBoardNodes.map(n => n.data.digitalNoticeBoardId)

            return {
                'GroupIds': groupIds,
                'AreaIds': areaIds,
                'DoorIds': doorIds,
                'FobIds': fobIds,
                'NoticeBoardIds': noticeBoardIds
            }

        },
        handleTreeDataLoaded(findNodeFunction) {
            //this.$debug.log("tree data loaded")


            if (this.$refs.dtree.$refs.tree.findAll({}).length == 0)
                this.noItems = true

            if (!this.findNodeFunction)
                this.findNodeFunction = findNodeFunction;

            this.selectAllItems(findNodeFunction);

            if (this.disableEditing)
                this.$refs.dtree.disableEditing();
        },
        cancelEditing() {
            this.editing = false;
            this.editedPreviews = null;
            this.editedObjects = [];

        },
        startEditing() {
            this.editing = true;
        },
        treeDragStart(node) {
            //if (!node.states.expanded)
            //    node.expand()
        },
        treeDragOn(targetNode, destinationNode, dropPosition) {

            //console.log('onDragOn', targetNode.text, destinationNode.text, dropPosition)

        },
        treeDragFinish(targetNode, destinationNode, dropPosition) {

            if (destinationNode.data.nodeType == 'door' && dropPosition == 'drag-on') {
                this.$bvModal.msgBoxOk(this.$t('area.cannot-drag-to-door'));
                return false;
            }
            else if (destinationNode.data.nodeType == 'notice-board' && dropPosition == 'drag-on') {
                this.$bvModal.msgBoxOk(this.$t('area.cannot-drag-to-noticeboard'));
                return false;
            }

            var newParentObjectId;

            if (destinationNode.data.nodeType == 'group')
                newParentObjectId = dropPosition == 'drag-on' ?
                destinationNode.data.groupId :
                    destinationNode.parent ? destinationNode.parent.data.groupId : null;
            else
                newParentObjectId = dropPosition == 'drag-on' ?
                    destinationNode.data.areaId :
                    destinationNode.parent ? destinationNode.parent.data.areaId : null;

            if (targetNode.data.nodeType == 'door' && newParentObjectId == null) {
                this.$bvModal.msgBoxOk(this.$t('area.cannot-drag-door-no-parent'));
                return false;
            }
            else if (targetNode.data.nodeType == 'notice-board' && newParentObjectId == null) {
                this.$bvModal.msgBoxOk(this.$t('area.cannot-drag-noticeboard-no-parent'));
                return false;
            }

            var targetParentId = targetNode.parent ? targetNode.parent.id : null
            var destinationParentId = destinationNode.parent ? destinationNode.parent.id : null

            if (dropPosition != 'drag-on' &&
                ((!targetParentId && !destinationParentId) ||
                    destinationParentId == targetParentId))
                return false;

            if (dropPosition == 'drag-on' &&
                targetNode.parent &&
                targetNode.parent.id == destinationNode.id)
                return false;



            var existingIndex = -1;

            if (targetNode.data.nodeType == 'area') {
                existingIndex = this.editedObjects.map(g => g.areaId).indexOf(targetNode.data.areaId);
            }
            else if (targetNode.data.nodeType == 'door') {
                existingIndex = this.editedObjects.map(g => g.doorId).indexOf(targetNode.data.doorId);
            }
            else if (targetNode.data.nodeType == 'notice-board') {
                existingIndex = this.editedObjects.map(g => g.digitalNoticeBoardId).indexOf(targetNode.data.digitalNoticeBoardId);
            }
            else if (targetNode.data.nodeType == 'group') {
                existingIndex = this.editedObjects.map(g => g.groupId).indexOf(targetNode.data.groupId);
            }

            if (existingIndex == -1) {
                this.editedObjects.push(targetNode.data)
                existingIndex = this.editedObjects.length - 1;
            }

            var editedObject = this.editedObjects[existingIndex];

            if (editedObject.nodeType == 'area') {
                editedObject.parentAreaId = newParentObjectId;
            }
            else if (editedObject.nodeType == 'group') {
                editedObject.parentGroupId = newParentObjectId;
            }
            else if (destinationNode.data.nodeType == 'group') {
                editedObject.groupId = newParentObjectId;
            }
            else if ((dropPosition == "drag-on" && destinationNode.data.nodeType == 'area') || (dropPosition != "drag-on" && destinationNode.parent && destinationNode.parent.data.nodeType == 'area')) {
                editedObject.areaId = newParentObjectId;
            }

            editedObject.parentType = destinationNode.data.nodeType;

            return true;
        },
        async showConfirmChanges() {

            var editedPreviews = [];
            this.waitingPreviewChanges = true;
            this.$bvModal.show('confirm-modal');

            for (var i = 0; i < this.editedObjects.length; i++) {

                var editedObject = this.editedObjects[i];

                if (editedObject.nodeType == 'area') {
                    var originalArea = (await this.$http.get(`/area/${editedObject.areaId}`)).data;
                    var newParentArea = editedObject.parentAreaId == null ? null : (await this.$http.get(`/area/${editedObject.parentAreaId}`)).data;
                    var previewArea = JSON.parse(JSON.stringify(originalArea));
                    previewArea.parentAreaId = editedObject.parentAreaId;
                    previewArea.parentAreas = editedObject.parentAreaId == null ? null : newParentArea.parentAreas.concat([newParentArea]);
                    editedPreviews.push({ originalArea: originalArea, previewArea: previewArea });
                }
                else if (editedObject.nodeType == 'door') {
                    var originalDoor = (await this.$http.get(`/door/${editedObject.doorId}`)).data;
                    var newArea = (await this.$http.get(`/area/${editedObject.areaId}`)).data;
                    var previewDoor = JSON.parse(JSON.stringify(originalDoor));
                    previewDoor.areaId = newArea.areaId;
                    previewDoor.area = newArea;
                    editedPreviews.push({ originalDoor: originalDoor, previewDoor: previewDoor });
                }
                else if (editedObject.nodeType == 'notice-board') {
                    var originalDigitalNoticeBoard = (await this.$http.get(`/noticeboard/${editedObject.digitalNoticeBoardId}`)).data;
                    var newArea = (await this.$http.get(`/area/${editedObject.areaId}`)).data;
                    var previewDigitalNoticeBoard = JSON.parse(JSON.stringify(originalDigitalNoticeBoard));
                    previewDigitalNoticeBoard.areaId = newArea.areaId;
                    previewDigitalNoticeBoard.area = newArea;
                    editedPreviews.push({ originalDigitalNoticeBoard: originalDigitalNoticeBoard, previewDigitalNoticeBoard: previewDigitalNoticeBoard });
                }
                else if (editedObject.nodeType == 'group') {
                    var originalGroup = (await this.$http.get(`/group/${editedObject.groupId}`)).data;
                    var newParentGroup = editedObject.parentGroupId == null ? null : (await this.$http.get(`/group/${editedObject.parentGroupId}`)).data;
                    var previewGroup = JSON.parse(JSON.stringify(originalGroup));
                    previewGroup.parentGroupId = editedObject.parentGroupId;
                    previewGroup.parentGroups = editedObject.parentGroupId == null ? null : newParentGroup.parentGroups.concat([newParentGroup]);
                    editedPreviews.push({ originalGroup: originalGroup, previewGroup: previewGroup });
                }

            }

            this.editedPreviews = editedPreviews;
            this.waitingPreviewChanges = false;

        },        
        async saveChanges() {
            this.$bvModal.show('loading-modal');
            this.progressCounter = 0;
            this.waiting = true;
            this.editObjectErrors = [];

            for (var i = 0; i < this.editedObjects.length; i++) {
                await this.processObjectEdit(this.editedObjects[i]);
            }

            if (this.editObjectErrors.length == 0) {
                this.$notify({ type: 'alert-success', text: this.$t('area.update-objects-success').toLowerCase() });
                this.editedObjects = [];
                this.editing = false;
            }
            else {
                this.$notify({ type: 'alert-danger', title: this.$t('area.update-objects-failure').toLowerCase() });
            }

            this.$bvModal.hide('loading-modal');
            this.waiting = false;
        },
        async processObjectEdit(editedObject) {

            if (editedObject.done)
                return;

            var editedObject;
            try {
                if (editedObject.nodeType == 'area')
                    editedObject = (await this.$http.put(`/area/${editedObject.areaId}`, { area: editedObject, areaPicture:null })).data;
                if (editedObject.nodeType == 'group')
                    editedObject = (await this.$http.put(`/group/${editedObject.groupId}`, editedObject)).data;
                else if (editedObject.nodeType == 'door')
                    editedObject = (await this.$http.put(`/door/${editedObject.doorId}`, editedObject)).data;
                else if (editedObject.nodeType == 'notice-board')
                    editedObject = (await this.$http.put(`/noticeboard/${editedObject.digitalNoticeBoardId}`, editedObject)).data;
            }
            catch (error) {

                var objectText = '';

                if (editedObject.nodeType == 'area')
                    objectText = `Area Id: ${editedObject.areaId}, name: ${editedObject.name}`;
                else if (editedObject.nodeType == 'group')
                    objectText = `Group Id: ${editedObject.groupId}, name: ${editedObject.name}`;
                else if (editedObject.nodeType == 'door')
                    objectText = `Door Id: ${editedObject.doorId}, name: ${editedObject.name}`;
                else if (editedObject.nodeType == 'notice-board')
                    objectText = `Notice Board Id: ${editedObject.digitalNoticeBoardId}, name: ${editedObject.name}`;


                if (error.response && error.response.data) {
                    this.editObjectErrors.push(objectText +
                        " Error -" + error.response.data.error ? error.response.data.error : error.response.data);
                }
                else {
                    this.editObjectErrors.push(objectText + " Error -" + error);
                }
                this.progressCounter++;
                return;
            }

            editedObject.done = true;
            this.progressCounter++;
        },
        
    }
}