export default class ViewObjectsDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'eye';
        this.title = this.dashboardComponent.$t('actions.view');
        this.data = [
            {
                display: this.dashboardComponent.$t('nav.reports'),
                icon: 'file-earmark-spreadsheet',
                url: '/monitoring/report'
            },
        ];
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        // Do nothing
    }

    getId(create) {
        return create.display;
    }

    getIcon(create) {
        return create.icon;
    }

    getDisplay(create) {
        return create.display;
    }

    getVariant(create) {
        return null;
    }

    getUrl(create) {
        return create.url;
    }

    async handleActionAsync(create) {
        // Do nothing
    }

    getValue(create) {
        return null;
    }

    getValueDisplay(create) {
        return null;
    }

    getMinValue(create) {
        return null;
    }

    getMaxValue(create) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}