export const SignalrButtonMixin = {

    data() {
        return {
            signalrReady: true,
            waiting: false,
            waitingGuid: '',
            successText: ''
        }
    },

    methods: {
        handleUpdateWebCommand(guid, webCommandResult) {
            if (guid == this.waitingGuid) {
                this.notifyActionSuccess();
            }
        },
        notifyActionSuccess() {
            this.$notify({ type: 'alert-success', title: this.successText });
            //this.waitingGuid = '';
        },
        notifyActionFail() {
            this.$notify({ type: 'alert-danger', text: ''/*failureText*/ });
            //this.waitingGuid = '';
        },

        confirmInvokeSignalr(confirmText, methodName, successText, failureText, okVariant, okTitle, paramsArray) {
            if (this.waiting) return;

            this.$bvModal.msgBoxConfirm(confirmText, {
                //title: 'Please Confirm',
                cancelVariant: 'white',
                okVariant: okVariant,
                okTitle: okTitle,
                cancelTitle: this.$t('actions.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                //centered: true
            })
            .then(value => {
                if (!value) return;
                this.invokeSignalr(methodName, successText, failureText, paramsArray);
            })
        },
        invokeSignalr(methodName, successText, failureText, paramsArray) {
            if (!this.signalrReady || this.waiting) return;
            this.waiting = true;

            var promise;

            if (paramsArray.length == 0)
                promise = this.$commsEngineHub.invoke(methodName)
            else if (paramsArray.length == 1)
                promise = this.$commsEngineHub.invoke(methodName, paramsArray[0])
            else if (paramsArray.length == 2)
                promise = this.$commsEngineHub.invoke(methodName, paramsArray[0], paramsArray[1])
            else if (paramsArray.length == 3)
                promise = this.$commsEngineHub.invoke(methodName, paramsArray[0], paramsArray[1], paramsArray[2])
            else
                console.error('signalr call with many parameters!')

            promise.then(response => {

                //this.$notify({ type: 'alert-success', title: successText });
                this.waitingGuid = response;
                this.successText = successText;

                //if (response.data.toLowerCase() == 'true')
                //    this.notifyActionSuccess();
                //else if (response.data.toLowerCase() == 'false')
                //    this.notifyActionFail();

                //if (response.data.success) {
                //    this.$notify({ type: 'alert-success', text: successText });
                //    this.waitingGuid = response.data.guid;
                //}
                //else this.$notify({ type: 'alert-danger', title: failureText, text: response.data.message });
            })
            .catch(error => {
                console.error(error);
                this.$notify({ type: 'alert-danger', title: failureText, text: error.statusText });
            })
            .finally(() => this.waiting = false);
        },
        signalRStart: function () {
            this.signalrReady = true
        }
    },

    mounted: function () {
        this.$commsEngineHubStartRegistration(this.signalRStart.bind(this));

        this.$commsEngineHub.on('updateWebCommand', this.handleUpdateWebCommand);

    },
    beforeDestroy() {
        this.$commsEngineHub.off('updateWebCommand', this.handleUpdateWebCommand);
    },
}


