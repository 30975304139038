import { render, staticRenderFns } from "./person-picker.vue?vue&type=template&id=b6897166"
import script from "./person-picker.vue?vue&type=script&lang=js"
export * from "./person-picker.vue?vue&type=script&lang=js"
import style0 from "./person-picker.vue?vue&type=style&index=0&id=b6897166&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports