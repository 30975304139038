export default class UsersDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'person';
        this.title = this.dashboardComponent.$tc('objects.plurals.user', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/user');
        if (!response || !response.data) {
            this.data = [];
            return
        };

        this.data = response.data;
    }

    getId(user) {
        return user.userId;
    }

    getIcon(user) {
        return 'person';
    }

    getDisplay(user) {
        return [user.firstName, user.surname].filter(n => n).join(' ');
    }

    getVariant(user) {
        return null;
    }

    getUrl(user) {
        return `/settings/user/${user.userId}/edit`;
    }

    async handleActionAsync(user) {
        // Do nothing
    }

    getValue(user) {
        return null;
    }

    getValueDisplay(user) {
        return null;
    }

    getMinValue(user) {
        return null;
    }

    getMaxValue(user) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}