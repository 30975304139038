
const Home = () => import('common/components/home/home-page');
const Login = () => import('common/components/account/login-page');
const ForgotPassword = () => import('common/components/account/forgot-password-page');
const ResetPassword = () => import('common/components/account/reset-password-page');

const LoginLand = () => import('@/views/login-land');
const BlankPage = () => import('common/components/common/blank-page');
const AboutPage = () => import('common/components/system/about-page');

const AreasPage = () => import('common/components/area/areas-page');
const AreaPage = () => import('common/components/area/area-page');
const AreaEditPage = () => import('common/components/area/area-edit-page');
const AreaDeletePage = () => import('common/components/area/area-delete-page');
const AreaGroupActivatePage = () => import('common/components/area/activate-all-fobs-to-group-page');
const AreaRenewEngineerPage = () => import('common/components/area/area-renew-engineer-page');
const AreaScanUdnsPage = () => import('common/components/area/area-scan-udns-page');

const ZonePage = () => import('common/components/zone/zone-page');
const ZonesPage = () => import('common/components/zone/zones-page');
const ZoneEditPage = () => import('common/components/zone/zone-edit-page');
const ZoneDeletePage = () => import('common/components/zone/zone-delete-page');
const ZoneCreatePage = () => import('common/components/zone/zone-create-page');

const SitePlansPage = () => import('common/components/site-plan/site-plans-page');
const SitePlanPage = () => import('common/components/site-plan/site-plan-page');
const SitePlanCreatePage = () => import('common/components/site-plan/site-plan-create-page');
const SitePlanEditPage = () => import('common/components/site-plan/site-plan-edit-page');

const GroupsPage = () => import('common/components/group/groups-page');
const GroupPage = () => import('common/components/group/group-page');
const GroupEditDoors = () => import('common/components/group/group-edit-access-doors');
const GroupCreatePage = () => import('common/components/group/group-create-page');
const GroupEditPage = () => import('common/components/group/group-edit-page');
const GroupDeletePage = () => import('common/components/group/group-delete-page');
const GroupActivatePersonPage = () => import('common/components/group/group-activate-person-to-group-page');
const GroupWizardPage = () => import('common/components/group/group-wizard-page');

const DoorGroupsPage = () => import('common/components/door-group/door-groups-page');
const DoorGroupCreatePage = () => import('common/components/door-group/door-group-create-page');
const DoorGroupPage = () => import('common/components/door-group/door-group-page');
const DoorGroupEditPage = () => import('common/components/door-group/door-group-edit-page');
const DoorGroupDeletePage = () => import('common/components/door-group/door-group-delete-page');

const PeoplePage = () => import('common/components/person/people-page');
const PersonPage = () => import('common/components/person/person-page');
const PersonCreatePage = () => import('common/components/person/person-create-page');
const PersonImportPage = () => import('common/components/person/person-import-page');
const PersonEditPage = () => import('common/components/person/person-edit-page');
const PersonDeletePage = () => import('common/components/person/person-delete-page');
const PersonAssignCredentialsPage = () => import('common/components/person/person-assign-credentials-page');
const PersonActivateToGroupPage = () => import('common/components/person/person-activate-to-group-page');
const PersonAssignFobsToPeoplePage = () => import('common/components/person/assign-fobs-to-people-page');
const PersonActivateToGroupEditPage = () => import('common/components/person/person-activate-to-group-edit-page');

const BadgeDesignsPage = () => import('common/components/badge-design/badge-designs-page');
const BadgeDesignPage = () => import('common/components/badge-design/badge-design-page');
const BadgeDesignCreatePage = () => import('common/components/badge-design/badge-design-create-page');
const BadgeDesignEditPage = () => import('common/components/badge-design/badge-design-edit-page');
const BadgeDesignDeletePage = () => import('common/components/badge-design/badge-design-delete-page');
const BadgeDesignDesignerPage = () => import('common/components/badge-design/badge-design-designer-page');
const BadgeDesignPrintPage = () => import('common/components/badge-design/badge-design-print-page');
const BadgeOrderPage = () => import('common/components/badge-design/badge-order-page');

const DoorUnlockInvitationsPage = () => import('common/components/door-unlock-invitation/door-unlock-invitations-page');
const DoorUnlockInvitationPage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-page');
const DoorUnlockInvitationCreatePage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-create-page');
const DoorUnlockInvitationEditPage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-edit-page');
const DoorUnlockInvitationDeletePage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-delete-page');
const DoorUnlockInvitationLandPage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-land-page');
const DoorUnlockInvitationStartPage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-start-page');
const DoorUnlockInvitationActivePage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-active-page');
const DoorUnlockInvitationLandRedirectPage = () => import('common/components/door-unlock-invitation/door-unlock-invitation-land-redirect-page');


const FobCreatePage = () => import('common/components/fob/fob-create-page');
const FobEditPage = () => import('common/components/fob/fob-edit-page');
const FobDeletePage = () => import('common/components/fob/fob-delete-page');
const FobEditActivationPage = () => import('common/components/fob/fob-edit-activation-page');
const FobCreateActivationPage = () => import('common/components/fob/fob-create-activation-page');
const FobAssignToPersonPage = () => import('common/components/fob/fob-assign-to-person-page');
const FobOverviewPage = () => import('common/components/fob/fob-overview-page');
const CloudFobsPage = () => import('common/components/fob/cloud-fobs-page');
const CreateCloudFobsPage = () => import('common/components/fob/create-cloud-fobs-page');

const FobPage = () => import('common/components/fob/fob-page');

const NoticeMessagesPage = () => import('common/components/notice-message/notice-messages-page');
const NoticeMessagePage = () => import('common/components/notice-message/notice-message-page');
const NoticeMessageCreatePage = () => import('common/components/notice-message/notice-message-create-page');
const NoticeMessageEditPage = () => import('common/components/notice-message/notice-message-edit-page');
const NoticeMessageDeletePage = () => import('common/components/notice-message/notice-message-delete-page');
const NoticeMessageDeleteUnusedPage = () => import('common/components/notice-message/notice-message-delete-unused-page');
const NoticeMessagePublishPage = () => import('common/components/notice-message/notice-message-publish');

const NoticeBoardsPage = () => import('common/components/notice-board/notice-boards-page');
const NoticeBoardPage = () => import('common/components/notice-board/notice-board-page');
const NoticeBoardEditPage = () => import('common/components/notice-board/notice-board-edit-page');
const NoticeBoardCreatePage = () => import('common/components/notice-board/notice-board-create-page');
const NoticeBoardDeletePage = () => import('common/components/notice-board/notice-board-delete-page');
const NoticeBoardSelectMessagePage = () => import('common/components/notice-board/notice-board-select-message-page');
const NoticeBoardChangeMessageOrderPage = () => import('common/components/notice-board/notice-board-change-message-order-page');

const NoticeBoardThemeLayoutPage = () => import('common/components/notice-board-theme-layout/notice-board-theme-layout-page');
const NoticeBoardThemeLayoutEditPage = () => import('common/components/notice-board-theme-layout/notice-board-theme-layout-edit-page');
const NoticeBoardThemeLayoutCreatePage = () => import('common/components/notice-board-theme-layout/notice-board-theme-layout-create-page');
const NoticeBoardThemeLayoutDeletePage = () => import('common/components/notice-board-theme-layout/notice-board-theme-layout-delete-page');

const NoticeBoardThemePage = () => import('common/components/notice-board-theme/notice-board-theme-page');
const NoticeBoardThemeEditPage = () => import('common/components/notice-board-theme/notice-board-theme-edit-page');
const NoticeBoardThemeCreatePage = () => import('common/components/notice-board-theme/notice-board-theme-create-page');
const NoticeBoardThemeDeletePage = () => import('common/components/notice-board-theme/notice-board-theme-delete-page');

const TimeProfilesPage = () => import('common/components/time-profile/time-profiles-page');
const TimeProfilePage = () => import('common/components/time-profile/time-profile-page');
const TimeProfileDeletePage = () => import('common/components/time-profile/time-profile-delete-page');
const TimeProfileEditPage = () => import('common/components/time-profile/time-profile-edit-page');
const TimeProfileCreatePage = () => import('common/components/time-profile/time-profile-create-page');

const TimeProfileAcsEditPage = () => import('common/components/time-profile-acs/time-profile-edit-page');
const TimeProfilesAcsPage = () => import('common/components/time-profile-acs/time-profiles-page');
const TimeProfileAcsPage = () => import('common/components/time-profile-acs/time-profile-page');
const TimeProfileAcsDeletePage = () => import('common/components/time-profile-acs/time-profile-delete-page');
const TimeProfileAcsCreatePage = () => import('common/components/time-profile-acs/time-profile-create-page');

const SimsPage = () => import('common/components/sim/sims-page');
const SimPage = () => import('common/components/sim/sim-page');
const SimCreatePage = () => import('common/components/sim/sim-create-page');
const SimEditPage = () => import('common/components/sim/sim-edit-page');
const SimDeletePage = () => import('common/components/sim/sim-delete-page');
const SimImportPage = () => import('common/components/sim/sim-import-page');
const SimImportConfirmPage = () => import('common/components/sim/sim-import-confirm');

const AlertPeoplePage = () => import('common/components/alert-person/alert-people-page');
const AlertPersonPage = () => import('common/components/alert-person/alert-person-page');
const AlertPersonCreatePage = () => import('common/components/alert-person/alert-person-create-page');
const AlertPersonEditPage = () => import('common/components/alert-person/alert-person-edit-page');
const AlertPersonDeletePage = () => import('common/components/alert-person/alert-person-delete-page');

const EventPage = () => import('common/components/event/event-page');

const AlertTriggersPage = () => import('common/components/alert-trigger/alert-triggers-page');
const AlertTriggerPage = () => import('common/components/alert-trigger/alert-trigger-page');
const AlertTriggerCreatePage = () => import('common/components/alert-trigger/alert-trigger-create-page');
const AlertTriggerEditPage = () => import('common/components/alert-trigger/alert-trigger-edit-page');
const AlertTriggerDeletePage = () => import('common/components/alert-trigger/alert-trigger-delete-page');

const CommDevicesPage = () => import('common/components/comm-device/comm-devices-page');
const CommDevicePage = () => import('common/components/comm-device/comm-device-page');
const CommDeviceCommissionPage = () => import('common/components/comm-device/comm-device-commission-page');
const CommDeviceEditPage = () => import('common/components/comm-device/comm-device-edit-page');
const CommDeviceDeletePage = () => import('common/components/comm-device/comm-device-delete-page');
const CommDeviceCreatePage = () => import('common/components/comm-device/comm-device-create-page');
const CommDeviceInspectionCreatePage = () => import('common/components/comm-device/comm-device-inspection-create');
const CommDeviceMapPage = () => import('common/components/comm-device/comm-device-map-page');
const CommDeviceProductionSetupPage = () => import('common/components/comm-device/comm-device-production-setup-page');
const CommDeviceApexImportPage = () => import('common/components/comm-device/comm-device-apex-import-page');


const ReportsPage = () => import('common/components/report/reports-page');
const ExpiredTokenReportPage = () => import('common/components/report/expired-token-report-page');
const AbsconsionReportPage = () => import('common/components/report/absconsion-report-page');
const UnusedTokenReportPage = () => import('common/components/report/unused-token-report-page');
const ExcessiveTokenUsageReportPage = () => import('common/components/report/excessive-token-usage-report-page');
const EventReportPage = () => import('common/components/report/event-report-page');
const AreaAccessReportPage = () => import('common/components/report/area-access-report-page');
const FobLastEventReportPage = () => import('common/components/report/fob-last-event-report-page');
const TimeAndAttendanceReportPage = () => import('common/components/report/time-and-attendance-report-page');
const RollCallReportPage = () => import('common/components/report/roll-call-report-page');

const ControllersPage = () => import('common/components/controller/controllers-page');
const ControllerPage = () => import('common/components/controller/controller-page');
const ControllerEditPage = () => import('common/components/controller/controller-edit-page');
const ControllerDeletePage = () => import('common/components/controller/controller-delete-page');
const ControllerCreatePage = () => import('common/components/controller/controller-create-page');
const ControllerScanUdnsPage = () => import('common/components/controller/controller-scan-udns-page');
const ControllerEditSettingsPage = () => import('common/components/controller/controller-edit-settings-page');

const ScheduledReportsPage = () => import('common/components/scheduled-report/scheduled-reports-page');
const ScheduledReportPage = () => import('common/components/scheduled-report/scheduled-report-page');
const ScheduledReportEditPage = () => import('common/components/scheduled-report/scheduled-report-edit-page');
const ScheduledReportCreatePage = () => import('common/components/scheduled-report/scheduled-report-create-page');
const ScheduledReportDeletePage = () => import('common/components/scheduled-report/scheduled-report-delete-page');

const DoorPage = () => import('common/components/door/door-page');
const DoorEditPage = () => import('common/components/door/door-edit-page');
const DoorDeletePage = () => import('common/components/door/door-delete-page');
const DoorCreatePage = () => import('common/components/door/door-create-page');


const DoorEntryLinkDoorCreatePage = () => import('common/components/door-entry-link-door/door-entry-link-door-create-page');
const DoorEntryLinkDoorPage = () => import('common/components/door-entry-link-door/door-entry-link-door-page');
const DoorEntryLinkDoorDeletePage = () => import('common/components/door-entry-link-door/door-entry-link-door-delete-page');
const DoorEntryLinkDoorEditPage = () => import('common/components/door-entry-link-door/door-entry-link-door-edit-page');


const AreaCreatePage = () => import('common/components/area/area-create-page');
const AreaBulkCreatePage = () => import('common/components/area/area-bulk-create-page');
const AreaCreateTokensPage = () => import('common/components/area/area-create-tokens-page');

const SimNetworkProvidersPage = () => import('common/components/sim-network-provider/sim-network-providers-page');
const SimNetworkProviderCreatePage = () => import('common/components/sim-network-provider/sim-network-provider-create-page');
const SimNetworkProviderPage = () => import('common/components/sim-network-provider/sim-network-provider-page');
const SimNetworkProviderDeletePage = () => import('common/components/sim-network-provider/sim-network-provider-delete-page');
const SimNetworkProviderEditPage = () => import('common/components/sim-network-provider/sim-network-provider-edit-page');

const SimTypesPage = () => import('common/components/sim-type/sim-types-page');
const SimTypeCreatePage = () => import('common/components/sim-type/sim-type-create-page');
const SimTypePage = () => import('common/components/sim-type/sim-type-page');
const SimTypeDeletePage = () => import('common/components/sim-type/sim-type-delete-page');
const SimTypeEditPage = () => import('common/components/sim-type/sim-type-edit-page');

const FobTypesPage = () => import('common/components/fob-type/fob-types-page');
const FobTypeCreatePage = () => import('common/components/fob-type/fob-type-create-page');
const FobTypePage = () => import('common/components/fob-type/fob-type-page');
const FobTypeDeletePage = () => import('common/components/fob-type/fob-type-delete-page');
const FobTypeEditPage = () => import('common/components/fob-type/fob-type-edit-page');

const FobColoursPage = () => import('common/components/fob-colour/fob-colours-page');
const FobColourCreatePage = () => import('common/components/fob-colour/fob-colour-create-page');
const FobColourPage = () => import('common/components/fob-colour/fob-colour-page');
const FobColourDeletePage = () => import('common/components/fob-colour/fob-colour-delete-page');
const FobColourEditPage = () => import('common/components/fob-colour/fob-colour-edit-page');


const DoorTypesPage = () => import('common/components/door-type/door-types-page');
const DoorTypeCreatePage = () => import('common/components/door-type/door-type-create-page');
const DoorTypePage = () => import('common/components/door-type/door-type-page');
const DoorTypeDeletePage = () => import('common/components/door-type/door-type-delete-page');
const DoorTypeEditPage = () => import('common/components/door-type/door-type-edit-page');

const LockTypesPage = () => import('common/components/lock-type/lock-types-page');
const LockTypeCreatePage = () => import('common/components/lock-type/lock-type-create-page');
const LockTypePage = () => import('common/components/lock-type/lock-type-page');
const LockTypeDeletePage = () => import('common/components/lock-type/lock-type-delete-page');
const LockTypeEditPage = () => import('common/components/lock-type/lock-type-edit-page');

const AreaTypesPage = () => import('common/components/area-type/area-types-page');
const AreaTypeCreatePage = () => import('common/components/area-type/area-type-create-page');
const AreaTypePage = () => import('common/components/area-type/area-type-page');
const AreaTypeDeletePage = () => import('common/components/area-type/area-type-delete-page');
const AreaTypeEditPage = () => import('common/components/area-type/area-type-edit-page');
const AreaTypeSettingsEditPage = () => import('common/components/area-type/area-type-settings-edit-page');


const InputCreatePage = () => import('common/components/input/input-create-page');
const InputPage = () => import('common/components/input/input-page');
const InputDeletePage = () => import('common/components/input/input-delete-page');
const InputEditPage = () => import('common/components/input/input-edit-page');

const OutputCreatePage = () => import('common/components/output/output-create-page');
const OutputPage = () => import('common/components/output/output-page');
const OutputDeletePage = () => import('common/components/output/output-delete-page');
const OutputEditPage = () => import('common/components/output/output-edit-page');

const ClientsPage = () => import('common/components/client/clients-page');
const ClientsEndUserPage = () => import('common/components/client/clients-page-end-user');
const ClientPage = () => import('common/components/client/client-page');
const ClientEditPage = () => import('common/components/client/client-edit-page');
const ClientCreatePage = () => import('common/components/client/client-create-page');
const ClientInstallerCreatePage = () => import('common/components/client/client-installer-create-page');
const ClientInstallerUpdatePage = () => import('common/components/client/client-installer-edit-page');
const ClientEditManagingPage = () => import('common/components/client/client-edit-managing-relationships');
const ClientEditEngineerPage = () => import('common/components/client/client-edit-engineer-relationships');
const ClientEditContractorPage = () => import('common/components/client/client-edit-contractor-relationships');
const ClientDeletePage = () => import('common/components/client/client-delete-page');

const ClientUserPage = () => import('common/components/client-page-user/client-user-page');
const ClientUserEditPage = () => import('common/components/client-page-user/client-user-edit-page');
const ClientUserCreditsPage = () => import('common/components/client-page-user/client-user-credits-page');

const CustomFieldsPage = () => import('common/components/client-custom-fields/custom-fields-page');
const CustomFieldPage = () => import('common/components/client-custom-fields/custom-field-page');
const CustomFieldCreatePage = () => import('common/components/client-custom-fields/custom-field-create-page');
const CustomFieldEditPage = () => import('common/components/client-custom-fields/custom-field-edit-page');
const CustomFieldDeletePage = () => import('common/components/client-custom-fields/custom-field-delete-page');

const CamerasPage = () => import('common/components/camera/cameras-page');
const CameraCreatePage = () => import('common/components/camera/camera-create-page');
const CameraPage = () => import('common/components/camera/camera-page');
const CameraEditPage = () => import('common/components/camera/camera-edit-page');
const CameraDeletePage = () => import('common/components/camera/camera-delete-page');

const ReaderCreatePage = () => import('common/components/reader/reader-create-page');
const ReaderPage = () => import('common/components/reader/reader-page');
const ReaderDeletePage = () => import('common/components/reader/reader-delete-page');
const ReaderEditPage = () => import('common/components/reader/reader-edit-page');

const ReaderInterfaceCreatePage = () => import('common/components/reader-interface/reader-interface-create-page');
const ReaderInterfacePage = () => import('common/components/reader-interface/reader-interface-page');
const ReaderInterfaceDeletePage = () => import('common/components/reader-interface/reader-interface-delete-page');
const ReaderInterfaceEditPage = () => import('common/components/reader-interface/reader-interface-edit-page');

const BusExpansionModuleCreatePage = () => import('common/components/bus-expansion-module/bus-expansion-module-create-page');
const BusExpansionModulePage = () => import('common/components/bus-expansion-module/bus-expansion-module-page');
const BusExpansionModuleDeletePage = () => import('common/components/bus-expansion-module/bus-expansion-module-delete-page');
const BusExpansionModuleEditPage = () => import('common/components/bus-expansion-module/bus-expansion-module-edit-page');

const LockControllerPage = () => import('common/components/lock-controller/lock-controller-page');
const LockControllerDeletePage = () => import('common/components/lock-controller/lock-controller-delete-page');
const LockControllerEditPage = () => import('common/components/lock-controller/lock-controller-edit-page');

const SemPage = () => import('common/components/sem/sem-page');
const SemDeletePage = () => import('common/components/sem/sem-delete-page');

const PowerHubPage = () => import('common/components/power-hub/power-hub-page');

const FirmwareUpdatesPage = () => import('common/components/system/firmware-updates-page');
const SystemSummaryPage = () => import('common/components/system/summary-page');
const SchematicPage = () => import('common/components/system/schematic-page')
const SystemTransferPage = () => import('common/components/system/system-transfer-page');

const SystemCreateWizard = () => import('common/components/system/system-create-wizard');

const ClientContactPage = () => import('common/components/client-contact/client-contact-page');
const ClientContactEditPage = () => import('common/components/client-contact/client-contact-edit-page');
const ClientContactCreatePage = () => import('common/components/client-contact/client-contact-create-page');
const ClientContactDeletePage = () => import('common/components/client-contact/client-contact-delete-page');

const AddressPage = () => import('common/components/address/address-page');
const AddressEditPage = () => import('common/components/address/address-edit-page');
const AddressCreatePage = () => import('common/components/address/address-create-page');
const AddressDeletePage = () => import('common/components/address/address-delete-page');


const AccountPage = () => import('common/components/account/account-page');
const ChangePasswordPage = () => import('common/components/account/change-password-page');

const AnnouncementsPage = () => import('common/components/announcement/announcements-page');
const AnnouncementCreatePage = () => import('common/components/announcement/announcement-create-page');
const AnnouncementPage = () => import('common/components/announcement/announcement-page');
const AnnouncementDeletePage = () => import('common/components/announcement/announcement-delete-page');
const AnnouncementEditPage = () => import('common/components/announcement/announcement-edit-page');

const UsersPage = () => import('common/components/user/users-page');
const UserPage = () => import('common/components/user/user-page');
const UserCreatePage = () => import('common/components/user/user-create-page');
const UserDeletePage = () => import('common/components/user/user-delete-page');
const UserEditPage = () => import('common/components/user/user-edit-page');
const UserSetup2FaPage = () => import('common/components/user/setup-2fa');
const UserLinkCreatePage = () => import('common/components/user-link/user-link-create-page');
const UserLinkEditPage = () => import('common/components/user-link/user-link-edit-page');

const UserGroupsPage = () => import('common/components/user-group/user-groups-page');
const UserGroupPage = () => import('common/components/user-group/user-group-page');
const UserGroupCreatePage = () => import('common/components/user-group/user-group-create-page');
const UserGroupDeletePage = () => import('common/components/user-group/user-group-delete-page');
const UserGroupEditPage = () => import('common/components/user-group/user-group-edit-page');
const UserChangePasswordPage = () => import('common/components/user/change-password-page');

const FeaturesPage = () => import('common/components/feature/features-page');
const FeaturePage = () => import('common/components/feature/feature-page');
const FeatureCreatePage = () => import('common/components/feature/feature-create-page');
const FeatureDeletePage = () => import('common/components/feature/feature-delete-page');
const FeatureEditPage = () => import('common/components/feature/feature-edit-page');

const ShopItemsPage = () => import('common/components/shop/shop-items-page');
const ShopOrdersPage = () => import('common/components/shop/shop-orders-page');
const ShopItemPage = () => import('common/components/shop/shop-item-page');
const ShopOrderPage = () => import('common/components/shop/shop-order-page');
const ShopCartPage = () => import('common/components/shop/shop-cart-page');

const ShopOrderInternalPage = () => import('common/components/shop/shop-order-internal-page');
const ShopOrderPrintProcessingPage = () => import('common/components/shop/shop-order-print-processing-page');
const ShopOrderPrintDeliveryPage = () => import('common/components/shop/shop-order-print-delivery-page');
const ShopOrderEditPage = () => import('common/components/shop/shop-order-edit-page');

const ClientMessagesPage = () => import('common/components/client-message/client-messages-page');
const ClientMessageCreatePage = () => import('common/components/client-message/client-message-create-page');
const ClientMessagePage = () => import('common/components/client-message/client-message-page');
const ClientMessageDeletePage = () => import('common/components/client-message/client-message-delete-page');
const ClientMessageEditPage = () => import('common/components/client-message/client-message-edit-page');


const ClientMismatch = () => import('common/components/common/client-mismatch-error')
const PageNotFound = () => import('common/components/common/page-not-found')

const EngineerClientPage = () => import('common/components/engineer/engineer-client-page');
const EngineerRelationsPage = () => import('common/components/engineer/engineer-relations-page');

const HostingContractsPage = () => import('common/components/hosting-contract/hosting-contracts-page');
const HostingContractPage = () => import('common/components/hosting-contract/hosting-contract-page');

const TimeAndAttendancePage = () => import('common/components/time-and-attendance/time-and-attendance-page');
const ExcessiveUsageDashboardPage = () => import('common/components/excessive-usage-dashboard/excessive-usage-dashboard-page');

const NotificationAlertTriggersPage = () => import('common/components/notification/alert-trigger-events-page');

const StockroomSummaryPage = () => import('common/components/stockroom/stockroom-summary-page');
const StockroomHardwareTransferPage = () => import('common/components/stockroom/stockroom-hardware-transfer-page');
const StockroomFobsPage = () => import('common/components/stockroom/stockroom-fobs-page');
const StockroomHardwarePage = () => import('common/components/stockroom/stockroom-hardware-page');
const StockroomFobsTransferPage = () => import('common/components/stockroom/stockroom-fobs-transfer-page');

const EngineersPage = () => import('common/components/engineer/engineers-page');
const EngineerPage = () => import('common/components/engineer/engineer-page');
const EngineerGrantAccessPage = () => import('common/components/engineer/grant-engineers-page');

const SearchPage = () => import('common/components/search/search-page');

const EventAlarmsPage = () => import('common/components/event-alarm/event-alarms-page');
const EventAlertsPage = () => import('common/components/event-alarm/event-alerts-page');

const PublicRegistrationCreatePage = () => import('@/views/public-registration-create-page');
const PublicRegistrationStatusPage = () => import('@/views/public-registration-status-page');
const PublicRegistrationsPage = () => import('common/components/public-registration/public-registrations-page');
const PublicRegistrationPage = () => import('common/components/public-registration/public-registration-page');
const PublicRegistrationEditPage = () => import('common/components/public-registration/public-registration-edit-page');
const PublicRegistrationDeletePage = () => import('common/components/public-registration/public-registration-delete-page');

const DashboardPage = () => import('common/components/dashboard/dashboard-page');


const CredentialUserAppRedirect = () => import('common/components/public/credential-user-app-redirect.vue')

const mainTitle = "CAME KMS Simplekey Web";

export const routes = [
    { name: 'home', path: '/', alias: '/home', component: Home, meta: { auth: true, title: route => mainTitle } },


    { path: '/credential-user-app-redirect', component: CredentialUserAppRedirect, props: { themeColour: '#00b0ed' }, meta: { title: route => mainTitle } },


    { path: '/login', alias: '/account/login', component: Login, props: { productName: 'Simplekey Web', themeColour: '#00b0ed', redirectPath: '/login-land' }, meta: { title: route => mainTitle } },
    { path: '/forgot-password', component: ForgotPassword, props: { productName: 'Simplekey Web', themeColour: '#00b0ed' }, meta: { title: route => mainTitle } },
    { path: '/reset-password', alias: '/account/password-reset', component: ResetPassword, props: { productName: 'Simplekey Web', themeColour: '#00b0ed' }, meta: { title: route => mainTitle } },

    { name: 'login-land', path: '/login-land', component: LoginLand, meta: { title: route => mainTitle } },
    { name: 'blank-page', path: '/blank', component: BlankPage, meta: { title: route => mainTitle } },
    { name: 'about-page', path: '/settings/about', component: AboutPage, meta: { auth: true, title: route => "About - " + mainTitle } },

    { name: 'alert-trigger-events-page', path: '/notifications/alert-trigger', component: NotificationAlertTriggersPage, props: true, meta: { auth: true, title: route => "Notification - Alert Triggers - " + mainTitle } },

    { name: 'stockroom-summary-page', path: '/management/stockroom', component: StockroomSummaryPage, props: true, meta: { auth: true, title: route => "Stockroom Summary - " + mainTitle } },
    { name: 'stockroom-hardware-transfer-page', path: '/management/stockroom/hardware-transfer', component: StockroomHardwareTransferPage, props: true, meta: { auth: true, title: route => "Transfer Stock - " + mainTitle } },
    { name: 'stockroom-hardware-page', path: '/management/stockroom/hardware', component: StockroomHardwarePage, props: true, meta: { auth: true, title: route => "Stockroom Hardware - " + mainTitle } },
    { name: 'stockroom-credential-page', path: '/management/stockroom/credential', component: StockroomFobsPage, props: true, meta: { auth: true, title: route => "Credential Stock - " + mainTitle } },
    { name: 'stockroom-fobs-transfer-page', path: '/management/stockroom/credential-transfer', component: StockroomFobsTransferPage, props: true, meta: { auth: true, title: route => "Credential Transfer - " + mainTitle } },
    
    { name: 'area-create-page', path: '/administration/area/create', component: AreaCreatePage, props: true, meta: { auth: true, title: route => "Create Area - " + mainTitle } },

    { name: 'area-bulk-create-page', path: '/administration/area/:areaId/bulk-create', component: AreaBulkCreatePage, props: true, meta: { auth: true, title: route => "Bulk Create Area - " + mainTitle } },
    { name: 'area-create-tokens-page', path: '/administration/area/:areaId/create-tokens', component: AreaCreateTokensPage, props: true, meta: { auth: true, title: route => "Create Tokens for Area - " + mainTitle } },

    { name: 'areas-page', path: '/administration/areas', alias: '/administration/area', component: AreasPage, meta: { auth: true, title: route => "Areas - " + mainTitle } },
    { name: 'area-page', path: '/administration/area/:areaId', component: AreaPage, props: true, meta: { auth: true, title: route => "Area - " + mainTitle } },
    { name: 'area-edit-page', path: '/administration/area/:areaId/edit', component: AreaEditPage, props: true, meta: { auth: true, title: route => "Edit Area - " + mainTitle } },
    { name: 'area-delete-page', path: '/administration/area/:areaId/delete', component: AreaDeletePage, props: true, meta: { auth: true, title: route => "Delete Area - " + mainTitle } },
    { name: 'area-group-activate', path: '/administration/area/:areaId/group-activate', component: AreaGroupActivatePage, props: true, meta: { auth: true, title: route => "Area Group Activate - " + mainTitle } },
    { name: 'area-scan-udns', path: '/administration/area/:areaId/scan-udns', component: AreaScanUdnsPage, props: true, meta: { auth: true, title: route => "Area Scan UDNs - " + mainTitle } },
    { name: 'area-renew-engineer-page', path: '/administration/area/:areaId/renew-engineer', component: AreaRenewEngineerPage, props: true, meta: { auth: true, title: route => "Renew Installer - " + mainTitle } },

    { name: 'engineers-page', path: '/settings/engineers', alias: '/settings/engineer', component: EngineersPage, meta: { auth: true, title: route => "Engineers - " + mainTitle } },
    { name: 'engineer-grant-access-page', path: '/settings/engineer/grant-access', component: EngineerGrantAccessPage, props: true, meta: { auth: true, title: route => "Grant Engineer Access - " + mainTitle } },
    { name: 'engineer-page', path: '/settings/engineer/:engineerId', component: EngineerPage, props: true, meta: { auth: true, title: route => "Engineer - " + mainTitle } },
    

    { name: 'custom-field-create-page', path: '/settings/custom-field/create', component: CustomFieldCreatePage, meta: { auth: true, title: route => "Create Custom Field - " } },
    { name: 'custom-fields-page', path: '/settings/custom-fields', alias: '/settings/custom-field', component: CustomFieldsPage, meta: { auth: true, title: route => "Custom Field - " + mainTitle } },
    { name: 'custom-field-page', path: '/settings/custom-field/:customFieldId', component: CustomFieldPage, props: true, meta: { auth: true, title: route => "Custom Field - " + mainTitle } },
    { name: 'custom-field-edit-page', path: '/settings/custom-field/:customFieldId/edit', component: CustomFieldEditPage, props: true, meta: { auth: true, title: route => "Edit Custom Field - " + mainTitle } },
    { name: 'custom-field-delete-page', path: '/settings/custom-field/:customFieldId/delete', component: CustomFieldDeletePage, props: true, meta: { auth: true, title: route => "Delete Custom Field - " + mainTitle } },

    { name: 'cameras-page', path: '/settings/cameras', component: CamerasPage, meta: { auth: true, title: route => "Cameras - " + mainTitle } },
    { name: 'camera-create-page', path: '/settings/camera/create', component: CameraCreatePage, meta: { auth: true, title: route => "Camera - " + mainTitle } },
    { name: 'camera-page', path: '/settings/camera/:cameraId', component: CameraPage, props: true, meta: { auth: true, title: route => "Camera - " + mainTitle } },
    { name: 'camera-edit-page', path: '/settings/camera/:cameraId/edit', component: CameraEditPage, props: true, meta: { auth: true, title: route => "Camera - " + mainTitle } },
    { name: 'camera-delete-page', path: '/settings/camera/:cameraId/delete', component: CameraDeletePage, props: true, meta: { auth: true, title: route => "Camera - " + mainTitle } },

    { name: 'cloud-fobs-page', path: '/administration/fob/cloud-fobs', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Fob - " + mainTitle } },
    { name: 'available-cloud-fobs-page', path: '/administration/fob/available-cloud-fobs', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Fob - " + mainTitle } },
    { name: 'recently-deactivated-cloud-fobs-page', path: '/administration/fob/recently-deactivated', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Fob - " + mainTitle } },
    { name: 'recently-activated-cloud-fobs-page', path: '/administration/fob/recently-activated', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Fob - " + mainTitle } },

    
    { name: 'create-cloud-fobs-page', path: '/administration/fob/create-cloud-fobs', component: CreateCloudFobsPage, props: true, meta: { auth: true, title: route => "Create Cloud Fobs - " + mainTitle } },
    { name: 'fob-overview-page', path: '/administration/fob/overview', component: FobOverviewPage, props: true, meta: { auth: true, title: route => "Fob Overview - " + mainTitle } },
    { name: 'fob-create', path: '/administration/fob/create', component: FobCreatePage, props: true, meta: { auth: true, title: route => "Create Fob - " + mainTitle } },
    { name: 'fob-edit', path: '/administration/fob/:fobId/edit', component: FobEditPage, props: true, meta: { auth: true, title: route => "Edit Fob - " + mainTitle } },
    { name: 'fob-delete', path: '/administration/fob/:fobId/delete', component: FobDeletePage, props: true, meta: { auth: true, title: route => "Delete Fob - " + mainTitle } },
    { name: 'fob-page', path: '/administration/fob/:fobId', component: FobPage, props: true, meta: { auth: true, title: route => "Fob - " + mainTitle } },
    { name: 'fob-edit-activation-page', path: '/administration/fob/:fobId/edit-activation/:activationId', component: FobEditActivationPage, props: true, meta: { auth: true, title: route => "Edit Fob Activation - " + mainTitle } },
    { name: 'fob-create-area-activation-page', path: '/administration/fob/:fobId/activate-to-area', component: FobCreateActivationPage, props: true, meta: { auth: true, title: route => "Create Fob Activation - " + mainTitle } },
    { name: 'fob-create-group-activation-page', path: '/administration/fob/:fobId/activate-to-group', component: FobCreateActivationPage, props: true, meta: { auth: true, title: route => "Create Fob Activation - " + mainTitle } },
    { name: 'fob-assign-to-person-page', path: '/administration/fob/:fobId/assign-to-person', component: FobAssignToPersonPage, props: true, meta: { auth: true, title: route => "Assign Fob to Person - " + mainTitle } },


    { name: 'cloud-tokens-page', path: '/administration/token/cloud-tokens', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Token - " + mainTitle } },
    { name: 'available-cloud-tokens-page', path: '/administration/token/available-cloud-tokens', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Token - " + mainTitle } },
    { name: 'recently-deactivated-cloud-tokens-page', path: '/administration/token/recently-deactivated', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Token - " + mainTitle } },
    { name: 'recently-activated-cloud-tokens-page', path: '/administration/token/recently-activated', component: CloudFobsPage, props: true, meta: { auth: true, title: route => "Cloud Token - " + mainTitle } },


    { name: 'create-cloud-tokens-page', path: '/administration/token/create-cloud-tokens', component: CreateCloudFobsPage, props: true, meta: { auth: true, title: route => "Create Cloud Tokens - " + mainTitle } },
    { name: 'token-create', path: '/administration/token/create', component: FobCreatePage, props: true, meta: { auth: true, title: route => "Create Token - " + mainTitle } },
    { name: 'token-edit', path: '/administration/token/:fobId/edit', component: FobEditPage, props: true, meta: { auth: true, title: route => "Edit Token - " + mainTitle } },
    { name: 'token-delete', path: '/administration/token/:fobId/delete', component: FobDeletePage, props: true, meta: { auth: true, title: route => "Delete Token - " + mainTitle } },
    { name: 'token-page', path: '/administration/token/:fobId', component: FobPage, props: true, meta: { auth: true, title: route => "Token - " + mainTitle } },
    { name: 'token-edit-activation-page', path: '/administration/token/:fobId/edit-activation/:activationId', component: FobEditActivationPage, props: true, meta: { auth: true, title: route => "Edit Token Activation - " + mainTitle } },
    { name: 'token-create-area-activation-page', path: '/administration/token/:fobId/activate-to-area', component: FobCreateActivationPage, props: true, meta: { auth: true, title: route => "Create Token Activation - " + mainTitle } },
    { name: 'token-create-group-activation-page', path: '/administration/token/:fobId/activate-to-group', component: FobCreateActivationPage, props: true, meta: { auth: true, title: route => "Create Token Activation - " + mainTitle } },
    { name: 'token-assign-to-person-page', path: '/administration/token/:fobId/assign-to-person', component: FobAssignToPersonPage, props: true, meta: { auth: true, title: route => "Assign Token to Person - " + mainTitle } },


    { name: 'site-plans-page', path: '/administration/site-plans', component: SitePlansPage, meta: { auth: true, title: route => "Site Plans - " + mainTitle } },
    { name: 'site-plan-create', path: '/administration/site-plan/create', component: SitePlanCreatePage, meta: { auth: true, title: route => "Create Site Plan - " + mainTitle } },
    { name: 'site-plan-edit-page', path: '/administration/site-plan/:sitePlanId/edit', component: SitePlanEditPage, props: true, meta: { auth: true, title: route => "Edit Site Plan - " + mainTitle } },
    { name: 'site-plan-page', path: '/administration/site-plan/:sitePlanId', component: SitePlanPage, props: true, meta: { auth: true, title: route => "Site Plan - " + mainTitle } },


    { name: 'group-create', path: '/administration/group/create', component: GroupCreatePage, props: true, meta: { auth: true, title: route => "Create Group - " + mainTitle } },
    { name: 'group-wizard-page', path: '/administration/group/group-wizard', component: GroupWizardPage, props: true, meta: { auth: true, title: route => "Group Wizard - " + mainTitle } },
    { name: 'group-edit', path: '/administration/group/:groupId/edit', component: GroupEditPage, props: true, meta: { auth: true, title: route => "Edit Group - " + mainTitle } },
    { name: 'groups-page', path: '/administration/groups', alias: '/administration/group', component: GroupsPage, meta: { auth: true, title: route => "Groups - " + mainTitle } },
    { name: 'group-page', path: '/administration/group/:groupId', component: GroupPage, props: true, meta: { auth: true, title: route => "Group - " + mainTitle } },
    { name: 'group-edit-access-doors', path: '/administration/group/:groupId/edit-access-doors', component: GroupEditDoors, props: true, meta: { auth: true, title: route => "Group edit access doors - " + mainTitle } },
    { name: 'group-delete-page', path: '/administration/group/:groupId/delete', component: GroupDeletePage, props: true, meta: { auth: true, title: route => "Delete Group - " + mainTitle } },
    { name: 'group-activate-person-page', path: '/administration/group/:groupId/activate-person', component: GroupActivatePersonPage, props: true, meta: { auth: true, title: route => "Activate Person - " + mainTitle } },

    { name: 'zones-page', path: '/administration/zone', component: ZonesPage, props: true, meta: { auth: true, title: route => "All Zones - " + mainTitle } },
    { name: 'zone-create', path: '/administration/zone/create', component: ZoneCreatePage, props: true, meta: { auth: true, title: route => "Create Zone - " + mainTitle } },
    { name: 'zone-edit', path: '/administration/zone/:zoneId/edit', component: ZoneEditPage, props: true, meta: { auth: true, title: route => "Edit Zone - " + mainTitle } },
    { name: 'zone-page', path: '/administration/zone/:zoneId', component: ZonePage, props: true, meta: { auth: true, title: route => "Zone - " + mainTitle } },
    { name: 'zone-delete-page', path: '/administration/zone/:zoneId/delete', component: ZoneDeletePage, props: true, meta: { auth: true, title: route => "Delete Zone - " + mainTitle } },
    
    { name: 'door-groups-page', path: '/administration/door-groups', component: DoorGroupsPage, meta: { auth: true, title: route => "Door Groups - " + mainTitle } },
    { name: 'door-group-create-page', path: '/administration/door-group/create', component: DoorGroupCreatePage, meta: { auth: true, title: route => "Create Door Group - " + mainTitle } },
    { name: 'door-group-page', path: '/administration/door-group/:doorGroupId', component: DoorGroupPage, props: true, meta: { auth: true, title: route => "Door Group - " + mainTitle } },
    { name: 'door-group-edit-page', path: '/administration/door-group/:doorGroupId/edit', component: DoorGroupEditPage, props: true, meta: { auth: true, title: route => "Edit Door Group - " + mainTitle } },
    { name: 'door-group-delete-page', path: '/administration/door-group/:doorGroupId/delete', component: DoorGroupDeletePage, props: true, meta: { auth: true, title: route => "Delete Door Group - " + mainTitle } },


    { name: 'assign-fobs-to-people', path: '/administration/person/assign-fobs-to-people', component: PersonAssignFobsToPeoplePage, props: true, meta: { auth: true, title: route => "Assign Credentials to People - " + mainTitle } },
    { name: 'person-create', path: '/administration/person/create', component: PersonCreatePage, props: true, meta: { auth: true, title: route => "Create Person - " + mainTitle } },
    { name: 'person-import', path: '/administration/person/import', component: PersonImportPage, props: true, meta: { auth: true, title: route => "Import People - " + mainTitle } },
    { name: 'person-edit', path: '/administration/person/:personId/edit', component: PersonEditPage, props: true, meta: { auth: true, title: route => "Edit Person - " + mainTitle } },
    { name: 'people-page', path: '/administration/persons', alias: '/administration/people', component: PeoplePage, meta: { auth: true, title: route => "People - " + mainTitle } },
    { name: 'person-page', path: '/administration/person/:personId', component: PersonPage, props: true, meta: { auth: true, title: route => "Person - " + mainTitle } },
    { name: 'person-delete-page', path: '/administration/person/:personId/delete', component: PersonDeletePage, props: true, meta: { auth: true, title: route => "Delete Person - " + mainTitle } },
    { name: 'person-assign-credentials-page', path: '/administration/person/:personId/assign-credentials', component: PersonAssignCredentialsPage, props: true, meta: { auth: true, title: route => "Assign Credentials to Person - " + mainTitle } },
    { name: 'person-activate-to-group-page', path: '/administration/person/:personId/activate-to-group', component: PersonActivateToGroupPage, props: true, meta: { auth: true, title: route => "Activate Person to Group - " + mainTitle } },
    { name: 'person-activate-to-group-edit-page', path: '/administration/person/:personId/edit-activation/:activationId', component: PersonActivateToGroupEditPage, props: true, meta: { auth: true, title: route => "Edit Person Activation - " + mainTitle } },
    

    
    


    { name: 'badge-order', path: '/administration/badge-design/order', component: BadgeOrderPage, meta: { auth: true, title: route => "Card Printing - " + mainTitle } },
    { name: 'badge-designs-page', path: '/administration/badge-designs', component: BadgeDesignsPage, meta: { auth: true, title: route => "Cards - " + mainTitle } },
    { name: 'badge-design-create', path: '/administration/badge-design/create', component: BadgeDesignCreatePage, meta: { auth: true, title: route => "Card - " + mainTitle } },
    { name: 'badge-design-page', path: '/administration/badge-design/:badgeDesignId', component: BadgeDesignPage, props: true, meta: { auth: true, title: route => "Card - " + mainTitle } },
    { name: 'badge-design-edit', path: '/administration/badge-design/:badgeDesignId/edit', component: BadgeDesignEditPage, props: true, meta: { auth: true, title: route => "Card - " + mainTitle } },
    { name: 'badge-design-delete', path: '/administration/badge-design/:badgeDesignId/delete', component: BadgeDesignDeletePage, props: true, meta: { auth: true, title: route => "Card - " + mainTitle } },
    { name: 'badge-design-designer', path: '/administration/badge-design/:badgeDesignId/designer', component: BadgeDesignDesignerPage, props: true, meta: { auth: true, title: route => "Card Designer - " + mainTitle } },
    { name: 'badge-design-print', path: '/administration/badge-design/:badgeDesignId/print', component: BadgeDesignPrintPage, props: true, meta: { auth: true, title: route => "Card - " + mainTitle } },


    { name: 'door-unlock-invitation-create', path: '/administration/door-unlock-invitation/create', component: DoorUnlockInvitationCreatePage, props: true, meta: { auth: true, title: route => "Create Door Unlock Invitation - " + mainTitle } },
    { name: 'door-unlock-invitation-edit', path: '/administration/door-unlock-invitation/:doorUnlockInvitationId/edit', component: DoorUnlockInvitationEditPage, props: true, meta: { auth: true, title: route => "Edit Door Unlock Invitation - " + mainTitle } },
    { name: 'door-unlock-invitations-page', path: '/administration/door-unlock-invitation', alias: '/administration/door-unlock-invitations', component: DoorUnlockInvitationsPage, meta: { auth: true, title: route => "All Door Unlock Invitation - " + mainTitle } },
    { name: 'door-unlock-invitation-page', path: '/administration/door-unlock-invitation/:doorUnlockInvitationId', component: DoorUnlockInvitationPage, props: true, meta: { auth: true, title: route => "Door Unlock Invitation - " + mainTitle } },
    { name: 'door-unlock-invitation-delete-page', path: '/administration/door-unlock-invitation/:doorUnlockInvitationId/delete', component: DoorUnlockInvitationDeletePage, props: true, meta: { auth: true, title: route => "Delete Door Unlock Invitation - " + mainTitle } },

    { name: 'door-unlock-invitation-land-page', path: '/door-unlock-invitation/land', component: DoorUnlockInvitationLandPage, props: true, meta: { auth: false, title: route => "Door Unlock Invitation Land - " + mainTitle } },
    { name: 'door-unlock-invitation-start-page', path: '/door-unlock-invitation/start', component: DoorUnlockInvitationStartPage, props: true, meta: { auth: false, title: route => "Door Unlock Invitation Start - " + mainTitle } },
    { name: 'door-unlock-invitation-active-page', path: '/door-unlock-invitation/active', component: DoorUnlockInvitationActivePage, props: true, meta: { auth: false, title: route => "Door Unlock Invitation - " + mainTitle } },


    { name: 'notice-message-create', path: '/administration/notice-message/create', component: NoticeMessageCreatePage, meta: { auth: true, title: route => "Create Notice Message - " + mainTitle } },
    { name: 'notice-message-delete-unused-page', path: '/administration/notice-message/delete-unused', component: NoticeMessageDeleteUnusedPage, props: true, meta: { auth: true, title: route => "Delete Notice Message - " + mainTitle } },
    { name: 'notice-messages-page', path: '/administration/notice-messages', component: NoticeMessagesPage, meta: { auth: true, title: route => "Notice Messages - " + mainTitle } },
    { name: 'notice-message-page', path: '/administration/notice-message/:noticeMessageId', props: true, component: NoticeMessagePage, meta: { auth: true, title: route => "Notice Message - " + mainTitle } },
    { name: 'notice-message-edit', path: '/administration/notice-message/:noticeMessageId/edit', component: NoticeMessageEditPage, props: true, meta: { auth: true, title: route => "Edit Notice Message - " + mainTitle } },
    { name: 'notice-message-delete-page', path: '/administration/notice-message/:noticeMessageId/delete', component: NoticeMessageDeletePage, props: true, meta: { auth: true, title: route => "Delete Notice Message - " + mainTitle } },
    { name: 'notice-message-publish', path: '/administration/notice-message/:noticeMessageId/publish', component: NoticeMessagePublishPage, props: true, meta: { auth: true, title: route => "Publish Notice Message - " + mainTitle } },

    { name: 'notice-board-create-page', path: '/management/notice-board/create', component: NoticeBoardCreatePage, meta: { auth: true, title: route => "Create NoticeBoard - " + mainTitle } },
    { name: 'notice-boards-page', path: '/management/notice-boards', component: NoticeBoardsPage, meta: { auth: true, title: route => "Notice Messages - " + mainTitle } },
    { name: 'notice-board-page', path: '/management/notice-board/:digitalNoticeBoardId', props: true, component: NoticeBoardPage, meta: { auth: true, title: route => "Notice Board - " + mainTitle } },
    { name: 'notice-board-edit-page', path: '/management/notice-board/:digitalNoticeBoardId/edit', component: NoticeBoardEditPage, props: true, meta: { auth: true, title: route => "Edit Notice Board - " + mainTitle } },
    { name: 'notice-board-delete-page', path: '/management/notice-board/:digitalNoticeBoardId/delete', component: NoticeBoardDeletePage, props: true, meta: { auth: true, title: route => "Delete Notice Board - " + mainTitle } },
    { name: 'notice-board-select-message-page', path: '/management/notice-board/:digitalNoticeBoardId/select-message', component: NoticeBoardSelectMessagePage, props: true, meta: { auth: true, title: route => "Select Notice Message - " + mainTitle } },
    { name: 'notice-board-change-message-order-page', path: '/management/notice-board/:digitalNoticeBoardId/change-message-order', component: NoticeBoardChangeMessageOrderPage, props: true, meta: { auth: true, title: route => "Change Notice Message Order - " + mainTitle } },

    { name: 'notice-board-theme-layout-create-page', path: '/management/notice-board-theme-layout/create', component: NoticeBoardThemeLayoutCreatePage, meta: { auth: true, title: route => "Create Theme Layout - " + mainTitle } },
    { name: 'notice-board-theme-layout-page', path: '/management/notice-board-theme-layout/:layoutId', props: true, component: NoticeBoardThemeLayoutPage, meta: { auth: true, title: route => "Notice Board Theme Layout - " + mainTitle } },
    { name: 'notice-board-theme-layout-edit-page', path: '/management/notice-board-theme-layout/:layoutId/edit', component: NoticeBoardThemeLayoutEditPage, props: true, meta: { auth: true, title: route => "Edit Theme Layout - " + mainTitle } },
    { name: 'notice-board-theme-layout-delete-page', path: '/management/notice-board-theme-layout/:layoutId/delete', component: NoticeBoardThemeLayoutDeletePage, props: true, meta: { auth: true, title: route => "Delete Theme Layout - " + mainTitle } },

    { name: 'notice-board-theme-create-page', path: '/management/notice-board-theme/create', component: NoticeBoardThemeCreatePage, meta: { auth: true, title: route => "Create Theme - " + mainTitle } },
    { name: 'notice-board-theme-page', path: '/management/notice-board-theme/:themeId', props: true, component: NoticeBoardThemePage, meta: { auth: true, title: route => "Notice Board Theme - " + mainTitle } },
    { name: 'notice-board-theme-edit-page', path: '/management/notice-board-theme/:themeId/edit', component: NoticeBoardThemeEditPage, props: true, meta: { auth: true, title: route => "Edit Theme - " + mainTitle } },
    { name: 'notice-board-theme-delete-page', path: '/management/notice-board-theme/:themeId/delete', component: NoticeBoardThemeDeletePage, props: true, meta: { auth: true, title: route => "Delete Theme - " + mainTitle } },

    { name: 'time-profile-create-page', path: '/management/time-profile/create', props: false, component: TimeProfileCreatePage, meta: { auth: true, title: route => "Create Time Profile - " + mainTitle } },
    { name: 'time-profiles-page', path: '/management/time-profiles', alias: '/management/time-profile', component: TimeProfilesPage, meta: { auth: true, title: route => "Time Profiles - " + mainTitle } },
    { name: 'time-profile-page', path: '/management/time-profile/:timeProfileId', props: true, component: TimeProfilePage, meta: { auth: true, title: route => "Time Profile - " + mainTitle } },
    { name: 'time-profile-delete-page', path: '/management/time-profile/:timeProfileId/delete', component: TimeProfileDeletePage, props: true, meta: { auth: true, title: route => "Delete Time Profile - " + mainTitle } },
    { name: 'time-profile-edit-page', path: '/management/time-profile/:timeProfileId/edit', component: TimeProfileEditPage, props: true, meta: { auth: true, title: route => "Edit Time Profile - " + mainTitle } },

    { name: 'time-profile-acs-create-page', path: '/management/time-profile-acs/create', props: false, component: TimeProfileAcsCreatePage, meta: { auth: true, title: route => "Create Time Profile - " + mainTitle } },
    { name: 'time-profiles-acs-page', path: '/management/time-profiles-acs', alias: '/management/time-profile-acs', component: TimeProfilesAcsPage, meta: { auth: true, title: route => "Time Profiles - " + mainTitle } },
    { name: 'time-profile-acs-edit-page', path: '/management/time-profile-acs/:timeProfileId/edit', component: TimeProfileAcsEditPage, props: true, meta: { auth: true, title: route => "Edit Time Profile - " + mainTitle } },
    { name: 'time-profile-acs-page', path: '/management/time-profile-acs/:timeProfileId', props: true, component: TimeProfileAcsPage, meta: { auth: true, title: route => "Time Profile - " + mainTitle } },
    { name: 'time-profile-acs-delete-page', path: '/management/time-profile-acs/:timeProfileId/delete', component: TimeProfileAcsDeletePage, props: true, meta: { auth: true, title: route => "Delete Time Profile - " + mainTitle } },


    { name: 'sim-create-page', path: '/management/sim/create', component: SimCreatePage, meta: { auth: true, title: route => "Create Sim - " + mainTitle } },
    { name: 'sim-import-page', path: '/management/sim/import', component: SimImportPage, meta: { auth: true, title: route => "Import Sims - " + mainTitle } },
    { name: 'sim-import-confirm', path: '/management/sim/import-confirm', component: SimImportConfirmPage, props: true, meta: { auth: true, title: route => "Confirm Import Sims - " + mainTitle } },
    { name: 'sims-page', path: '/management/sims', alias: '/management/sim', component: SimsPage, meta: { auth: true, title: route => "Sims - " + mainTitle } },
    { name: 'sim-page', path: '/management/sim/:simId', props: true, component: SimPage, meta: { auth: true, title: route => "Sim - " + mainTitle } },
    { name: 'sim-edit-page', path: '/management/sim/:simId/edit', props: true, component: SimEditPage, meta: { auth: true, title: route => "Edit Sim - " + mainTitle } },
    { name: 'sim-delete-page', path: '/management/sim/:simId/delete', props: true, component: SimDeletePage, meta: { auth: true, title: route => "Delete Sim - " + mainTitle } },

    { name: 'alert-person-create-page', path: '/monitoring/alert-person/create', component: AlertPersonCreatePage, meta: { auth: true, title: route => "Create Alert Person - " + mainTitle } },
    { name: 'alert-people-page', path: '/monitoring/alert-person', component: AlertPeoplePage, meta: { auth: true, title: route => "Alert People - " + mainTitle } },
    { name: 'alert-person-page', path: '/monitoring/alert-person/:alertPersonId', props: true, component: AlertPersonPage, meta: { auth: true, title: route => "Alert Person - " + mainTitle } },
    { name: 'alert-person-edit-page', path: '/monitoring/alert-person/:alertPersonId/edit', props: true, component: AlertPersonEditPage, meta: { auth: true, title: route => "Edit Alert Person - " + mainTitle } },
    { name: 'alert-person-delete-page', path: '/monitoring/alert-person/:alertPersonId/delete', props: true, component: AlertPersonDeletePage, meta: { auth: true, title: route => "Delete Alert Person - " + mainTitle } },

    { name: 'event-page', path: '/monitoring/event/:eventId', props: true, component: EventPage, meta: { auth: true, title: route => "Event - " + mainTitle } },

    { name: 'hosting-contracts-page', path: '/management/hosting-contracts', props: true, component: HostingContractsPage, meta: { auth: true, title: route => "Hosting Contracts - " + mainTitle } },
    { name: 'hosting-contract-page', path: '/management/hosting-contract/:hostingContractId', props: true, component: HostingContractPage, meta: { auth: true, title: route => "Hosting Contract - " + mainTitle } },

    { name: 'alert-trigger-create-page', path: '/monitoring/alert-trigger/create', component: AlertTriggerCreatePage, meta: { auth: true, title: route => "Create Alert Trigger - " + mainTitle } },
    { name: 'alert-triggers-page', path: '/monitoring/alert-trigger', component: AlertTriggersPage, meta: { auth: true, title: route => "Alert Triggers - " + mainTitle } },
    { name: 'alert-trigger-page', path: '/monitoring/alert-trigger/:alertTriggerId', props: true, component: AlertTriggerPage, meta: { auth: true, title: route => "Alert Trigger - " + mainTitle } },
    { name: 'alert-trigger-edit-page', path: '/monitoring/alert-trigger/:alertTriggerId/edit', props: true, component: AlertTriggerEditPage, meta: { auth: true, title: route => "Edit Alert Trigger - " + mainTitle } },
    { name: 'alert-trigger-delete-page', path: '/monitoring/alert-trigger/:alertTriggerId/delete', props: true, component: AlertTriggerDeletePage, meta: { auth: true, title: route => "Delete Alert Trigger - " + mainTitle } },

    { name: 'comm-devices-page', path: '/management/comm-devices', alias: '/management/comm-device', component: CommDevicesPage, meta: { auth: true, title: route => "Comm Devices - " + mainTitle } },
    { name: 'comm-device-commission-page', path: '/management/comm-device/:commDeviceId/commission', component: CommDeviceCommissionPage, props: true, meta: { auth: true, title: route => "Comm Device Commission - " + mainTitle } },
    { name: 'comm-device-edit-page', path: '/management/comm-device/:commDeviceId/edit', component: CommDeviceEditPage, props: true, meta: { auth: true, title: route => "Edit Comm Device - " + mainTitle } },
    { name: 'comm-device-delete-page', path: '/management/comm-device/:commDeviceId/delete', component: CommDeviceDeletePage, props: true, meta: { auth: true, title: route => "Delete Comm Device - " + mainTitle } },
    { name: 'comm-device-map-page', path: '/management/comm-device/map', component: CommDeviceMapPage, props: true, meta: { auth: true, title: route => "Comm Device Map - " + mainTitle } },

    { name: 'comm-device-create-page', path: '/management/comm-device/create', component: CommDeviceCreatePage, props: true, meta: { auth: true, title: route => "Create Comm Device - " + mainTitle } },
    { name: 'comm-device-page', path: '/management/comm-device/:commDeviceId', alias: '/management/commdevice/:commDeviceId', component: CommDevicePage, props: true, meta: { auth: true, title: route => "Comm Device - " + mainTitle } },
    { name: 'comm-device-inspection-create', path: '/management/comm-device/:commDeviceId/submit-inspection', component: CommDeviceInspectionCreatePage, props: true, meta: { auth: true, title: route => "Submit Comm Device Inspection - " + mainTitle } },
    { name: 'comm-device-production-setup-page', path: '/management/comm-device/:commDeviceId/production-setup', component: CommDeviceProductionSetupPage, props: true, meta: { auth: true, title: route => "Comm Device Production Setup - " + mainTitle } },
    { name: 'comm-device-apex-import-page', path: '/management/comm-device/:commDeviceId/apex-import', component: CommDeviceApexImportPage, props: true, meta: { auth: true, title: route => "Comm Device Apex Import - " + mainTitle } },




    { name: 'controllers-page', path: '/management/controllers', component: ControllersPage, meta: { auth: true, title: route => "Controllers - " + mainTitle } },
    { name: 'controller-scan-udns-page', path: '/management/controller/:controllerId/scan-udns', component: ControllerScanUdnsPage, props: true, meta: { auth: true, title: route => "Controller Scan UDNs - " + mainTitle } },
    { name: 'controller-edit-page', path: '/management/controller/:controllerId/edit', component: ControllerEditPage, props: true, meta: { auth: true, title: route => "Edit Controller - " + mainTitle } },
    { name: 'controller-delete-page', path: '/management/controller/:controllerId/delete', component: ControllerDeletePage, props: true, meta: { auth: true, title: route => "Delete Controller - " + mainTitle } },
    { name: 'controller-create-page', path: '/management/controller/create', component: ControllerCreatePage, props: true, meta: { auth: true, title: route => "Create Controller - " + mainTitle } },
    { name: 'controller-page', path: '/management/controller/:controllerId', component: ControllerPage, props: true, meta: { auth: true, title: route => "Controller - " + mainTitle } },
    { name: 'controller-edit-settings-page', path: '/management/controller/:controllerId/edit-controller-settings', component: ControllerEditSettingsPage, props: true, meta: { auth: true, title: route => "Edit Controller Settings - " + mainTitle } },


    { name: 'reports-page', path: '/monitoring/report', component: ReportsPage, meta: { auth: true, title: route => "Reports - " + mainTitle } },
    { name: 'expired-token-report-page', path: '/monitoring/report/expired-token', component: ExpiredTokenReportPage, meta: { auth: true, title: route => "Expired Token Report - " + mainTitle } },
    { name: 'absconsion-report-page', path: '/monitoring/report/absconsion', component: AbsconsionReportPage, meta: { auth: true, title: route => "Absconsion Report - " + mainTitle } },
    { name: 'unused-token-report-page', path: '/monitoring/report/unused-token', component: UnusedTokenReportPage, meta: { auth: true, title: route => "Unused Token Report - " + mainTitle } },
    { name: 'excessive-token-usage-report-page', path: '/monitoring/report/excessive-token-usage', component: ExcessiveTokenUsageReportPage, meta: { auth: true, title: route => "Excessive Token Usage Report - " + mainTitle } },
    { name: 'event-report-page', path: '/monitoring/report/event', component: EventReportPage, meta: { auth: true, title: route => "Event Report - " + mainTitle } },
    { name: 'area-access-report-page', path: '/monitoring/report/area-access', component: AreaAccessReportPage, meta: { auth: true, title: route => "Area Access Report - " + mainTitle } },
    { name: 'fob-last-event-report-page', path: '/monitoring/report/last-event', component: FobLastEventReportPage, meta: { auth: true, title: route => "Fob Last Event Report - " + mainTitle } },
    { name: 'time-and-attendance-report-page', path: '/monitoring/report/time-and-attendance', component: TimeAndAttendanceReportPage, meta: { auth: true, title: route => "Time and Attendance Report - " + mainTitle } },
    { name: 'roll-call-page', path: '/monitoring/report/roll-call', component: RollCallReportPage, meta: { auth: true, title: route => "Roll Call Report - " + mainTitle } },

    { name: 'door-edit-page', path: '/management/door/:doorId/edit', component: DoorEditPage, props: true, meta: { auth: true, title: route => "Edit Door - " + mainTitle } },
    { name: 'door-delete-page', path: '/management/door/:doorId/delete', component: DoorDeletePage, props: true, meta: { auth: true, title: route => "Delete Door - " + mainTitle } },
    { name: 'door-create-page', path: '/management/door/create', component: DoorCreatePage, props: true, meta: { auth: true, title: route => "Create Door - " + mainTitle } },
    { name: 'door-page', path: '/management/door/:doorId', component: DoorPage, props: true, meta: { auth: true, title: route => "Door - " + mainTitle } },


    { name: 'door-entry-link-door-create-page', path: '/management/door-entry-link-door/create', component: DoorEntryLinkDoorCreatePage, meta: { auth: true, title: route => "Create Door Entry Link Door - " + mainTitle } },
    { name: 'door-entry-link-door-page', path: '/management/door-entry-link-door/:doorEntryLinkDoorId', props: true, component: DoorEntryLinkDoorPage, meta: { auth: true, title: route => "Door Entry Link Door - " + mainTitle } },
    { name: 'door-entry-link-door-edit-page', path: '/management/door-entry-link-door/:doorEntryLinkDoorId/edit', props: true, component: DoorEntryLinkDoorEditPage, meta: { auth: true, title: route => "Edit Door Entry Link Door - " + mainTitle } },
    { name: 'door-entry-link-door-delete-page', path: '/management/door-entry-link-door/:doorEntryLinkDoorId/delete', props: true, component: DoorEntryLinkDoorDeletePage, meta: { auth: true, title: route => "Delete Door Entry Link Door - " + mainTitle } },


    { name: 'scheduled-report-create-page', path: '/monitoring/scheduled-report/create', component: ScheduledReportCreatePage, props: true, meta: { auth: true, title: route => "Create Scheduled Report - " + mainTitle } },
    { name: 'scheduled-reports-page', path: '/monitoring/scheduled-report', component: ScheduledReportsPage, meta: { auth: true, title: route => "Scheduled Reports - " + mainTitle } },
    { name: 'scheduled-report-page', path: '/monitoring/scheduled-report/:scheduledReportId', props: true, component: ScheduledReportPage, meta: { auth: true, title: route => "Scheduled Report - " + mainTitle } },
    { name: 'scheduled-report-edit-page', path: '/monitoring/scheduled-report/:scheduledReportId/edit', component: ScheduledReportEditPage, props: true, meta: { auth: true, title: route => "Edit Scheduled Report - " + mainTitle } },
    { name: 'scheduled-report-delete-page', path: '/monitoring/scheduled-report/:scheduledReportId/delete', component: ScheduledReportDeletePage, props: true, meta: { auth: true, title: route => "Delete Scheduled Report - " + mainTitle } },

    { name: 'sim-network-provider-create-page', path: '/configuration/sim-network-provider/create', component: SimNetworkProviderCreatePage, meta: { auth: true, title: route => "Create Sim Network Provider - " + mainTitle } },
    { name: 'sim-network-providers-page', path: '/configuration/sim-network-providers', alias: '/configuration/sim-network-provider', component: SimNetworkProvidersPage, meta: { auth: true, title: route => "Sim Network Provider - " + mainTitle } },
    { name: 'sim-network-provider-page', path: '/configuration/sim-network-provider/:simNetworkProviderId', props: true, component: SimNetworkProviderPage, meta: { auth: true, title: route => "Sim Network Provider - " + mainTitle } },
    { name: 'sim-network-provider-edit-page', path: '/configuration/sim-network-provider/:simNetworkProviderId/edit', props: true, component: SimNetworkProviderEditPage, meta: { auth: true, title: route => "Edit Sim Network Provider - " + mainTitle } },
    { name: 'sim-network-provider-delete-page', path: '/configuration/sim-network-provider/:simNetworkProviderId/delete', props: true, component: SimNetworkProviderDeletePage, meta: { auth: true, title: route => "Delete Sim Network Provider - " + mainTitle } },

    { name: 'sim-type-create-page', path: '/configuration/sim-type/create', component: SimTypeCreatePage, meta: { auth: true, title: route => "Create Sim Type - " + mainTitle } },
    { name: 'sim-types-page', path: '/configuration/sim-type', alias: '/configuration/sim-types', component: SimTypesPage, meta: { auth: true, title: route => "Sim Type - " + mainTitle } },
    { name: 'sim-type-page', path: '/configuration/sim-type/:simTypeId', props: true, component: SimTypePage, meta: { auth: true, title: route => "Sim Type - " + mainTitle } },
    { name: 'sim-type-edit-page', path: '/configuration/sim-type/:simTypeId/edit', props: true, component: SimTypeEditPage, meta: { auth: true, title: route => "Edit Sim Type - " + mainTitle } },
    { name: 'sim-type-delete-page', path: '/configuration/sim-type/:simTypeId/delete', props: true, component: SimTypeDeletePage, meta: { auth: true, title: route => "Delete Sim Type - " + mainTitle } },

    { name: 'fob-type-create-page', path: '/configuration/fob-type/create', component: FobTypeCreatePage, meta: { auth: true, title: route => "Create Fob Type - " + mainTitle } },
    { name: 'fob-types-page', path: '/configuration/fob-type', alias: '/configuration/fob-types', component: FobTypesPage, meta: { auth: true, title: route => "Fob Type - " + mainTitle } },
    { name: 'fob-type-page', path: '/configuration/fob-type/:fobTypeId', props: true, component: FobTypePage, meta: { auth: true, title: route => "Fob Type - " + mainTitle } },
    { name: 'fob-type-edit-page', path: '/configuration/fob-type/:fobTypeId/edit', props: true, component: FobTypeEditPage, meta: { auth: true, title: route => "Edit Fob Type - " + mainTitle } },
    { name: 'fob-type-delete-page', path: '/configuration/fob-type/:fobTypeId/delete', props: true, component: FobTypeDeletePage, meta: { auth: true, title: route => "Delete Fob Type - " + mainTitle } },
    { name: 'token-type-create-page', path: '/configuration/token-type/create', component: FobTypeCreatePage, meta: { auth: true, title: route => "Create Fob Type - " + mainTitle } },
    { name: 'token-types-page', path: '/configuration/token-type', alias: '/configuration/token-types', component: FobTypesPage, meta: { auth: true, title: route => "Fob Type - " + mainTitle } },
    { name: 'token-type-page', path: '/configuration/token-type/:fobTypeId', props: true, component: FobTypePage, meta: { auth: true, title: route => "Fob Type - " + mainTitle } },
    { name: 'token-type-edit-page', path: '/configuration/token-type/:fobTypeId/edit', props: true, component: FobTypeEditPage, meta: { auth: true, title: route => "Edit Fob Type - " + mainTitle } },
    { name: 'token-type-delete-page', path: '/configuration/token-type/:fobTypeId/delete', props: true, component: FobTypeDeletePage, meta: { auth: true, title: route => "Delete Fob Type - " + mainTitle } },



    { name: 'token-colour-create-page', path: '/configuration/token-colour/create', component: FobColourCreatePage, meta: { auth: true, title: route => "Create Token Colour - " + mainTitle } },
    { name: 'token-colours-page', path: '/configuration/token-colour', alias: '/configuration/token-colours', component: FobColoursPage, meta: { auth: true, title: route => "Token Colour - " + mainTitle } },
    { name: 'token-colour-page', path: '/configuration/token-colour/:fobColourId', props: true, component: FobColourPage, meta: { auth: true, title: route => "Token Colour - " + mainTitle } },
    { name: 'token-colour-edit-page', path: '/configuration/token-colour/:fobColourId/edit', props: true, component: FobColourEditPage, meta: { auth: true, title: route => "Edit Token Colour - " + mainTitle } },
    { name: 'token-colour-delete-page', path: '/configuration/token-colour/:fobColourId/delete', props: true, component: FobColourDeletePage, meta: { auth: true, title: route => "Delete Token Colour - " + mainTitle } },
    { name: 'fob-colour-create-page', path: '/configuration/fob-colour/create', component: FobColourCreatePage, meta: { auth: true, title: route => "Create Token Colour - " + mainTitle } },
    { name: 'fob-colours-page', path: '/configuration/fob-colour', alias: '/configuration/token-colours', component: FobColoursPage, meta: { auth: true, title: route => "Token Colour - " + mainTitle } },
    { name: 'fob-colour-page', path: '/configuration/fob-colour/:fobColourId', props: true, component: FobColourPage, meta: { auth: true, title: route => "Token Colour - " + mainTitle } },
    { name: 'fob-colour-edit-page', path: '/configuration/fob-colour/:fobColourId/edit', props: true, component: FobColourEditPage, meta: { auth: true, title: route => "Edit Token Colour - " + mainTitle } },
    { name: 'fob-colour-delete-page', path: '/configuration/fob-colour/:fobColourId/delete', props: true, component: FobColourDeletePage, meta: { auth: true, title: route => "Delete Token Colour - " + mainTitle } },



    { name: 'door-type-create-page', path: '/configuration/door-type/create', component: DoorTypeCreatePage, meta: { auth: true, title: route => "Create Door Type - " + mainTitle } },
    { name: 'door-types-page', path: '/configuration/door-type', alias: '/configuration/door-types', component: DoorTypesPage, meta: { auth: true, title: route => "Door Type - " + mainTitle } },
    { name: 'door-type-page', path: '/configuration/door-type/:doorTypeId', props: true, component: DoorTypePage, meta: { auth: true, title: route => "Door Type - " + mainTitle } },
    { name: 'door-type-edit-page', path: '/configuration/door-type/:doorTypeId/edit', props: true, component: DoorTypeEditPage, meta: { auth: true, title: route => "Edit Door Type - " + mainTitle } },
    { name: 'door-type-delete-page', path: '/configuration/door-type/:doorTypeId/delete', props: true, component: DoorTypeDeletePage, meta: { auth: true, title: route => "Delete Door Type - " + mainTitle } },

    { name: 'lock-type-create-page', path: '/configuration/lock-type/create', component: LockTypeCreatePage, meta: { auth: true, title: route => "Create Lock Type - " + mainTitle } },
    { name: 'lock-types-page', path: '/configuration/lock-type', alias: '/configuration/lock-types', component: LockTypesPage, meta: { auth: true, title: route => "Lock Type - " + mainTitle } },
    { name: 'lock-type-page', path: '/configuration/lock-type/:lockTypeId', props: true, component: LockTypePage, meta: { auth: true, title: route => "Lock Type - " + mainTitle } },
    { name: 'lock-type-edit-page', path: '/configuration/lock-type/:lockTypeId/edit', props: true, component: LockTypeEditPage, meta: { auth: true, title: route => "Edit Lock Type - " + mainTitle } },
    { name: 'lock-type-delete-page', path: '/configuration/lock-type/:lockTypeId/delete', props: true, component: LockTypeDeletePage, meta: { auth: true, title: route => "Delete Lock Type - " + mainTitle } },

    { name: 'area-type-create-page', path: '/configuration/area-type/create', component: AreaTypeCreatePage, meta: { auth: true, title: route => "Create Area Type - " + mainTitle } },
    { name: 'area-types-page', path: '/configuration/area-type', alias: '/configuration/area-types', component: AreaTypesPage, meta: { auth: true, title: route => "Area Type - " + mainTitle } },
    { name: 'area-type-page', path: '/configuration/area-type/:areaTypeId', props: true, component: AreaTypePage, meta: { auth: true, title: route => "Area Type - " + mainTitle } },
    { name: 'area-type-edit-page', path: '/configuration/area-type/:areaTypeId/edit', props: true, component: AreaTypeEditPage, meta: { auth: true, title: route => "Edit Area Type - " + mainTitle } },
    { name: 'area-type-delete-page', path: '/configuration/area-type/:areaTypeId/delete', props: true, component: AreaTypeDeletePage, meta: { auth: true, title: route => "Delete Area Type - " + mainTitle } },
    { name: 'area-type-settings-edit-page', path: '/configuration/area-type/:areaTypeId/edit-area-type-settings', props: true, component: AreaTypeSettingsEditPage, meta: { auth: true, title: route => "Edit Area Type Settings - " + mainTitle } },

    { name: 'client-create-page', path: '/configuration/client/create', component: ClientCreatePage, meta: { auth: true, title: route => "Create Client - " + mainTitle } },
    { name: 'client-installer-create-page', path: '/management/client/create-installer-client', component: ClientInstallerCreatePage, meta: { auth: true, title: route => "Create Site Owner - " + mainTitle } },
    { name: 'client-installer-edit-page', path: '/management/client/:clientId/edit', props:true, component: ClientInstallerUpdatePage, meta: { auth: true, title: route => "Update Site Owner - " + mainTitle } },

    { name: 'clients-page', path: '/configuration/client', alias: '/management/clients', component: ClientsPage, meta: { auth: true, title: route => "Client - " + mainTitle } },
    { name: 'clients-end-user-page', path: '/management/client', alias: '/configuration/clients', component: ClientsEndUserPage, meta: { auth: true, title: route => "Client - " + mainTitle } },
    { name: 'client-page', path: '/configuration/client/:clientId', props: true, component: ClientPage, meta: { auth: true, title: route => "Client - " + mainTitle } },
    { name: 'client-edit-page', path: '/configuration/client/:clientId/edit', props: true, component: ClientEditPage, meta: { auth: true, title: route => "Edit Client - " + mainTitle } },
    { name: 'client-edit-managing-relations-page', path: '/configuration/client/:clientId/edit-managing-relationships', props: true, component: ClientEditManagingPage, meta: { auth: true, title: route => "Edit Managing Relations - " + mainTitle } },
    { name: 'client-edit-engineer-relations-page', path: '/configuration/client/:clientId/edit-engineer-relationships', props: true, component: ClientEditEngineerPage, meta: { auth: true, title: route => "Edit Engineer Relations - " + mainTitle } },
    { name: 'client-edit-contractor-relations-page', path: '/configuration/client/:clientId/edit-contractor-relationships', props: true, component: ClientEditContractorPage, meta: { auth: true, title: route => "Edit Contractor Relations - " + mainTitle } },
    { name: 'client-delete-page', path: '/configuration/client/:clientId/delete', props: true, component: ClientDeletePage, meta: { auth: true, title: route => "Delete Client - " + mainTitle } },

    { name: 'client-user-page', path: '/my-client', props: true, component: ClientUserPage, meta: { auth: true, title: route => "My Client - " + mainTitle } },
    { name: 'client-user-edit-page', path: '/my-client/edit', props: true, component: ClientUserEditPage, meta: { auth: true, title: route => "Edit Client - " + mainTitle } },
    { name: 'client-user-credits-page', path: '/my-client/credits', component: ClientUserCreditsPage, meta: { auth: true, title: route => "My Credits - " + mainTitle } },


    { name: 'client-contact-create-page', path: '/configuration/client-contact/create/:clientId', component: ClientContactCreatePage, props: true, meta: { auth: true, title: route => "Create Client Contact - " + mainTitle } },
    { name: 'client-contact-page', path: '/configuration/client-contact/:clientContactId', props: true, component: ClientContactPage, meta: { auth: true, title: route => "Client Contact - " + mainTitle } },
    { name: 'client-contact-edit-page', path: '/configuration/client-contact/:clientContactId/edit', props: true, component: ClientContactEditPage, meta: { auth: true, title: route => "Edit Client Contact - " + mainTitle } },
    { name: 'client-contact-delete-page', path: '/configuration/client-contact/:clientContactId/delete', props: true, component: ClientContactDeletePage, meta: { auth: true, title: route => "Delete Client Contact - " + mainTitle } },

    { name: 'address-create-page', path: '/configuration/address/create/:clientId', component: AddressCreatePage, props: true, meta: { auth: true, title: route => "Create Address - " + mainTitle } },
    { name: 'address-page', path: '/configuration/address/:addressId', props: true, component: AddressPage, meta: { auth: true, title: route => "Address - " + mainTitle } },
    { name: 'address-edit-page', path: '/configuration/address/:addressId/edit', props: true, component: AddressEditPage, meta: { auth: true, title: route => "Edit Address - " + mainTitle } },
    { name: 'address-delete-page', path: '/configuration/address/:addressId/delete', props: true, component: AddressDeletePage, meta: { auth: true, title: route => "Delete Address - " + mainTitle } },

    //{ name: 'dashboard',                        path: '/dashboard',                         component: Dashboard,                               meta: { auth: true, title: route => "Dashboard | " + mainTitle } },


    { name: 'input-create-page', path: '/management/input/create', component: InputCreatePage, meta: { auth: true, title: route => "Create Input - " + mainTitle } },
    { name: 'input-page', path: '/management/input/:inputId', props: true, component: InputPage, meta: { auth: true, title: route => "Input - " + mainTitle } },
    { name: 'input-edit-page', path: '/management/input/:inputId/edit', props: true, component: InputEditPage, meta: { auth: true, title: route => "Edit Input - " + mainTitle } },
    { name: 'input-delete-page', path: '/management/input/:inputId/delete', props: true, component: InputDeletePage, meta: { auth: true, title: route => "Delete Input - " + mainTitle } },


    { name: 'output-create-page', path: '/management/output/create', component: OutputCreatePage, meta: { auth: true, title: route => "Create Output - " + mainTitle } },
    { name: 'output-page', path: '/management/output/:outputId', props: true, component: OutputPage, meta: { auth: true, title: route => "Output - " + mainTitle } },
    { name: 'output-edit-page', path: '/management/output/:outputId/edit', props: true, component: OutputEditPage, meta: { auth: true, title: route => "Edit Output - " + mainTitle } },
    { name: 'output-delete-page', path: '/management/output/:outputId/delete', props: true, component: OutputDeletePage, meta: { auth: true, title: route => "Delete Output - " + mainTitle } },


    { name: 'reader-create-page', path: '/management/reader/create', component: ReaderCreatePage, meta: { auth: true, title: route => "Create Reader - " + mainTitle } },
    { name: 'reader-page', path: '/management/reader/:readerId', props: true, component: ReaderPage, meta: { auth: true, title: route => "Reader - " + mainTitle } },
    { name: 'reader-edit-page', path: '/management/reader/:readerId/edit', props: true, component: ReaderEditPage, meta: { auth: true, title: route => "Edit Reader - " + mainTitle } },
    { name: 'reader-delete-page', path: '/management/reader/:readerId/delete', props: true, component: ReaderDeletePage, meta: { auth: true, title: route => "Delete Reader - " + mainTitle } },


    { name: 'reader-interface-create-page', path: '/management/reader-interface/create', component: ReaderInterfaceCreatePage, meta: { auth: true, title: route => "Create Reader Interface - " + mainTitle } },
    { name: 'reader-interface-page', path: '/management/reader-interface/:readerInterfaceId', props: true, component: ReaderInterfacePage, meta: { auth: true, title: route => "Reader Interface - " + mainTitle } },
    { name: 'reader-interface-edit-page', path: '/management/reader-interface/:readerInterfaceId/edit', props: true, component: ReaderInterfaceEditPage, meta: { auth: true, title: route => "Edit Reader Interface - " + mainTitle } },
    { name: 'reader-interface-delete-page', path: '/management/reader-interface/:readerInterfaceId/delete', props: true, component: ReaderInterfaceDeletePage, meta: { auth: true, title: route => "Delete Reader Interface - " + mainTitle } },

    { name: 'bus-expansion-module-create-page', path: '/management/bus-expansion-module/create', component: BusExpansionModuleCreatePage, meta: { auth: true, title: route => "Create Bus Expansion Module - " + mainTitle } },
    { name: 'bus-expansion-module-page', path: '/management/bus-expansion-module/:busExpansionModuleId', props: true, component: BusExpansionModulePage, meta: { auth: true, title: route => "Bus Expansion Module - " + mainTitle } },
    { name: 'bus-expansion-module-edit-page', path: '/management/bus-expansion-module/:busExpansionModuleId/edit', props: true, component: BusExpansionModuleEditPage, meta: { auth: true, title: route => "Edit Bus Expansion Module - " + mainTitle } },
    { name: 'bus-expansion-module-delete-page', path: '/management/bus-expansion-module/:busExpansionModuleId/delete', props: true, component: BusExpansionModuleDeletePage, meta: { auth: true, title: route => "Delete Bus Expansion Module - " + mainTitle } },


    { name: 'lock-controller-page', path: '/management/lock-controller/:lockControllerId', props: true, component: LockControllerPage, meta: { auth: true, title: route => "Lock Controller - " + mainTitle } },
    { name: 'lock-controller-edit-page', path: '/management/lock-controller/:lockControllerId/edit', props: true, component: LockControllerEditPage, meta: { auth: true, title: route => "Edit Lock Controller - " + mainTitle } },
    { name: 'lock-controller-delete-page', path: '/management/lock-controller/:lockControllerId/delete', props: true, component: LockControllerDeletePage, meta: { auth: true, title: route => "Delete Lock Controller - " + mainTitle } },

    { name: 'sem-page', path: '/management/sem/:ioxId', props: true, component: SemPage, meta: { auth: true, title: route => "SEM - " + mainTitle } },
    { name: 'sem-delete-page', path: '/management/sem/:ioxId/delete', props: true, component: SemDeletePage, meta: { auth: true, title: route => "Delete SEM - " + mainTitle } },

    { name: 'power-hub-page', path: '/management/power-hub/:powerHubId', props: true, component: PowerHubPage, meta: { auth: true, title: route => "Power Hub - " + mainTitle } },

    { name: 'announcement-create-page', path: '/settings/announcement/create', component: AnnouncementCreatePage, meta: { auth: true, title: route => "Create Announcement - " + mainTitle } },
    { name: 'announcements-page', path: '/settings/announcement', alias: '/setting/announcements', component: AnnouncementsPage, meta: { auth: true, title: route => "Announcement - " + mainTitle } },
    { name: 'announcement-page', path: '/settings/announcement/:announcementId', props: true, component: AnnouncementPage, meta: { auth: true, title: route => "Announcement - " + mainTitle } },
    { name: 'announcement-edit-page', path: '/settings/announcement/:announcementId/edit', props: true, component: AnnouncementEditPage, meta: { auth: true, title: route => "Edit Announcement - " + mainTitle } },
    { name: 'announcement-delete-page', path: '/settings/announcement/:announcementId/delete', props: true, component: AnnouncementDeletePage, meta: { auth: true, title: route => "Delete Announcement - " + mainTitle } },

    { name: 'account-page', path: '/account', component: AccountPage, meta: { auth: true, title: route => "Account - " + mainTitle } },
    { name: 'change-password-page', path: '/account/change-password', component: ChangePasswordPage, meta: { auth: true, title: route => "Change Password - " + mainTitle } },

    { name: 'user-create-page', path: '/settings/user/create', component: UserCreatePage, meta: { auth: true, title: route => "Create User - " + mainTitle } },
    { name: 'users-page', path: '/settings/user', alias: '/settings/users', component: UsersPage, meta: { auth: true, title: route => "Users - " + mainTitle } },
    { name: 'user-page', path: '/settings/user/:userId', props: true, component: UserPage, meta: { auth: true, title: route => "User - " + mainTitle } },
    { name: 'user-edit-page', path: '/settings/user/:userId/edit', props: true, component: UserEditPage, meta: { auth: true, title: route => "Edit User - " + mainTitle } },
    { name: 'user-delete-page', path: '/settings/user/:userId/delete', props: true, component: UserDeletePage, meta: { auth: true, title: route => "Delete User - " + mainTitle } },
    { name: 'user-setup-2fa-page', path: '/settings/account/:userId/setup-2fa', props: true, component: UserSetup2FaPage, meta: { auth: true, title: route => "Setup Two Factor Authentication - " + mainTitle } },
    { name: 'user-link-create-page', path: '/settings/user-link/create', component: UserLinkCreatePage, meta: { auth: true, title: route => "Edit User Link - " + mainTitle } },
    { name: 'user-link-edit-page', path: '/settings/user-link/:userLinkId', props: true, component: UserLinkEditPage, meta: { auth: true, title: route => "Edit User Link - " + mainTitle } },

    { name: 'user-group-create-page', path: '/settings/user-group/create', component: UserGroupCreatePage, meta: { auth: true, title: route => "Create User Group - " + mainTitle } },
    { name: 'user-groups-page', path: '/settings/user-group', alias: '/settings/user-groups', component: UserGroupsPage, meta: { auth: true, title: route => "User Groups - " + mainTitle } },
    { name: 'user-group-page', path: '/settings/user-group/:roleId', props: true, component: UserGroupPage, meta: { auth: true, title: route => "User Group - " + mainTitle } },
    { name: 'user-group-edit-page', path: '/settings/user-group/:roleId/edit', props: true, component: UserGroupEditPage, meta: { auth: true, title: route => "Edit User Group - " + mainTitle } },
    { name: 'user-group-delete-page', path: '/settings/user-group/:roleId/delete', props: true, component: UserGroupDeletePage, meta: { auth: true, title: route => "Delete User Group - " + mainTitle } },

    { name: 'feature-create-page', path: '/settings/feature/create', component: FeatureCreatePage, meta: { auth: true, title: route => "Create Feature - " + mainTitle } },
    { name: 'features-page', path: '/settings/feature', alias: '/settings/features', component: FeaturesPage, meta: { auth: true, title: route => "Features - " + mainTitle } },
    { name: 'feature-page', path: '/settings/feature/:featureId', props: true, component: FeaturePage, meta: { auth: true, title: route => "Feature - " + mainTitle } },
    { name: 'feature-edit-page', path: '/settings/feature/:featureId/edit', props: true, component: FeatureEditPage, meta: { auth: true, title: route => "Edit Feature - " + mainTitle } },
    { name: 'feature-delete-page', path: '/settings/feature/:featureId/delete', props: true, component: FeatureDeletePage, meta: { auth: true, title: route => "Delete Feature - " + mainTitle } },

    { name: 'web-shop-cart-page', path: '/shop/cart', component: ShopCartPage, meta: { auth: true, title: route => "Shopping Cart - " + mainTitle } },
    { name: 'web-shop-items-page', path: '/shop', component: ShopItemsPage, meta: { auth: true, title: route => "Web Shop - " + mainTitle } },
    { name: 'web-shop-orders-page', path: '/shop/order', component: ShopOrdersPage, meta: { auth: true, title: route => "Orders - " + mainTitle } },
    { name: 'web-shop-item-page', path: '/shop/:id', props: true, component: ShopItemPage, meta: { auth: true, title: route => "Web Shop - " + mainTitle } },
    { name: 'web-shop-order-page', path: '/shop/order/:id', props: true, component: ShopOrderPage, meta: { auth: true, title: route => "Order - " + mainTitle } },

    { name: 'shop-orders-page', path: '/configuration/shop-order', component: ShopOrdersPage, meta: { auth: true, title: route => "Shop Orders - " + mainTitle } },
    { name: 'shop-order-page', path: '/configuration/shop-order/:id', props: true, component: ShopOrderInternalPage, meta: { auth: true, title: route => "Shop Order - " + mainTitle } },
    { name: 'shop-order-print-processing-page', path: '/configuration/shop-order/:id/print-processing', props: true, component: ShopOrderPrintProcessingPage, meta: { auth: true, title: route => "Shop Order - " + mainTitle } },
    { name: 'shop-order-print-delivery-page', path: '/configuration/shop-order/:id/print-delivery', props: true, component: ShopOrderPrintDeliveryPage, meta: { auth: true, title: route => "Shop Order - " + mainTitle } },
    { name: 'shop-order-edit-page', path: '/configuration/shop-order/:id/edit', props: true, component: ShopOrderEditPage, meta: { auth: true, title: route => "Shop Order - " + mainTitle } },


    { name: 'client-message-create-page', path: '/notifications/client-message/create', component: ClientMessageCreatePage, meta: { auth: true, title: route => "Create Client Message - " + mainTitle } },
    { name: 'client-messages-page', path: '/notifications/client-message', alias: '/notifications/client-messages', component: ClientMessagesPage, meta: { auth: true, title: route => "Features Client Message - " + mainTitle } },
    { name: 'client-message-page', path: '/notifications/client-message/:clientMessageId', props: true, component: ClientMessagePage, meta: { auth: true, title: route => "Client Message - " + mainTitle } },
    { name: 'client-message-edit-page', path: '/notifications/client-message/:clientMessageId/edit', props: true, component: ClientMessageEditPage, meta: { auth: true, title: route => "Edit Client Message - " + mainTitle } },
    { name: 'client-message-delete-page', path: '/notifications/client-message/:clientMessageId/delete', props: true, component: ClientMessageDeletePage, meta: { auth: true, title: route => "Delete Client Message - " + mainTitle } },


    { name: 'engineer-client-page', path: '/settings/engineer-client', component: EngineerClientPage, meta: { auth: true, title: route => "Engineer - " + mainTitle } },
    { name: 'engineer-relations-page', path: '/configuration/engineer-relations', component: EngineerRelationsPage, meta: { auth: true, title: route => "Engineer Relations - " + mainTitle } },

    { name: 'firmware-updates-page', path: '/management/system/firmware-updates', props: true, component: FirmwareUpdatesPage, meta: { auth: true, title: route => "Firmware Updates - " + mainTitle } },

    { name: 'system-summary-page', path: '/management/system/summary', props: true, component: SystemSummaryPage, meta: { auth: true, title: route => "System Summary - " + mainTitle } },
    { name: 'schematic-page', path: '/management/system/schematic', props: true, component: SchematicPage, meta: { auth: true, title: route => "Schematic - " + mainTitle } },
    { name: 'system-transfer-page', path: '/management/system/transfer', component: SystemTransferPage, props: route => ({ baseAreaId: route.query.baseAreaId, destinationClientId: route.query.destinationClientId }), meta: { auth: true, title: route => "Transfer System - " + mainTitle } },

    { name: 'system-create-wizard', path: '/management/system/create-wizard', component: SystemCreateWizard, props: route => ({ areaId: route.query.area }), meta: { auth: true, title: route => "Create System - " + mainTitle } },

    { name: 'user-change-password-page', path: '/settings/user/:userId/set-user-password', props: true, component: UserChangePasswordPage, meta: { auth: true, title: route => "Change User Password - " + mainTitle } },

    { name: 'time-and-attendance-page', path: '/monitoring/time-and-attendance', props: true, component: TimeAndAttendancePage, meta: { auth: true, title: route => "Time and Attendance - " + mainTitle } },
    { name: 'excessive-usage-dashboard-page', path: '/monitoring/excessive-usage-dashboard', props: true, component: ExcessiveUsageDashboardPage, meta: { auth: true, title: route => "Excessive Usage Dashboard - " + mainTitle } },

    { name: 'search-page', path: '/search', component: SearchPage, meta: { auth: true, title: route => "Search - " + mainTitle } },

    { name: 'alarms-page', path: '/notifications/alarms', component: EventAlarmsPage, meta: { auth: true, title: route => "Alarms - " + mainTitle } },
    { name: 'alerts-page', path: '/notifications/alerts', component: EventAlertsPage, meta: { auth: true, title: route => "Alerts - " + mainTitle } },

    { path: '/register', component: PublicRegistrationCreatePage, meta: { title: route => mainTitle } },
    { path: '/register/:reference', component: PublicRegistrationStatusPage, props: true, meta: { title: route => mainTitle } },
    { path: '/configuration/public-registrations', component: PublicRegistrationsPage, meta: { auth: true, title: route => "Public Registrations - " + mainTitle } },
    { path: '/configuration/public-registration/:publicRegistrationId', props: true, component: PublicRegistrationPage, meta: { auth: true, title: route => "Public Registration - " + mainTitle } },
    { path: '/configuration/public-registration/:publicRegistrationId/edit', props: true, component: PublicRegistrationEditPage, meta: { auth: true, title: route => "Edit Public Registration - " + mainTitle } },
    { path: '/configuration/public-registration/:publicRegistrationId/delete', props: true, component: PublicRegistrationDeletePage, meta: { auth: true, title: route => "Delete Public Registration - " + mainTitle } },

    { name: 'dashboard-page', path: '/dashboard', component: DashboardPage, meta: { auth: true, title: route => "Dashboard - " + mainTitle } },

    { name: 'client-mismatch-error', path: "/client-mismatch", component: ClientMismatch, meta: { title: route => "Error: Client Mismatch - " + mainTitle } },
    { path: "*", component: PageNotFound, meta: { title: route => "Error: Not Found - " + mainTitle } }

]
