import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title(to)
    })
})


export default router
