import DataList from 'common/components/common/data-list';

export default {
    props: {
        showCheckboxes: false,
        overrideUrl: null,
        initialItems: null,
        paged: false,
        hideFilter: false,
        disableLinks: false
    },
    components: {
        'data-list': DataList,
    },
    computed: {
        theme() {
            return 'light';
        },
        slotNames() {
            var slotNames = {};

            this.fields.map(f => slotNames[f.name] = f.name);

            return slotNames;
        }
    },
    methods: {
        refresh() {
            if (this.objectType)
                this.$refs[this.objectType].refresh()
        }
        
    },
    data() {
        
    },
    mounted() {
        
    }
}