    export default {
        props: {
            
        },
        components: {},
        data() {
            return {
                icons: [
                    {
                        name: "heart",
                        display: "Heart"
                    },
                    {
                        name: "heart-fill",
                        display: "Filled Heart"
                    },
                    {
                        name: "broadcast",
                        display: "Broadcast"
                    },
                    {
                        name: "building",
                        display: "Building"
                    },
                    {
                        name: "camera-video",
                        display: "Camera"
                    },
                    {
                        name: "people",
                        display: "People"
                    },
                    {
                        name: "key",
                        display: "Key"
                    },
                    {
                        name: "cpu",
                        display: "Cpu"
                    },
                    {
                        name: "door-closed",
                        display: "Door"
                    },
                    {
                        name: "easel",
                        display: "Easel"
                    },
                    {
                        name: "person",
                        display: "Person"
                    },
                    {
                        name: "file-earmark-spreadsheet",
                        display: "Report"
                    },
                    {
                        name: "collection",
                        display: "Group"
                    },
                    {
                        name: "chat-square-text",
                        display: "Message"
                    },
                    {
                        name: "calendar2-check",
                        display: "Calendar"
                    }
                    ,
                    {
                        name: "door-open",
                        display: "Door Open"
                    }
                    ,
                    {
                        name: "pin-map",
                        display: "Site Plan"
                    }
                    ,
                    {
                        name: "diagram3",
                        display: "Customer"
                    }
                    ,
                    {
                        name: "person-badge",
                        display: "Person Badge"
                    }
                    ,
                    {
                        name: "envelope",
                        display: "Envelope"
                    }
                    ,
                    {
                        name: "lightning-charge",
                        display: "Lightning"
                    },
                    {
                        name: "sim",
                        display: "Sim"
                    },
                    {
                        name: "search",
                        display: "Search"
                    },
                    {
                        name: "cash-coin",
                        display: "Hosting Contract",
                    },
                    {
                        name: "tools",
                        display: "Engineer",
                    },
                    {
                        name: "exclamation-triangle-fill",
                        display: "Alarm",
                    },
                    {
                        name: "columns-gap",
                        display: "Dashboard",
                    },
                ]
            }
        },
    }