let lastTemporaryMarkerId = 0;

//'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark';
// display of themes will be initialised in getter
let themes = {
    primary:   { key: 'primary',   displayKey: 'site-plan.primary',   display: null },
    secondary: { key: 'secondary', displayKey: 'site-plan.secondary', display: null },
    success:   { key: 'success',   displayKey: 'site-plan.success',   display: null },
    info:      { key: 'info',      displayKey: 'site-plan.info',      display: null },
    warning:   { key: 'warning',   displayKey: 'site-plan.warning',   display: null },
    danger:    { key: 'danger',    displayKey: 'site-plan.danger',    display: null },
    light:     { key: 'light',     displayKey: 'site-plan.light',     display: null },
    dark:      { key: 'dark',      displayKey: 'site-plan.dark',      display: null },
};

//'broadcast', 'cpu', 'c:dc1', 'c:dc2', 'c:dc10', 'door-closed', 'g:settings-remote', 'building', 'camera-video', 'info-circle'
// display of icons will be initialised in getter
let icons = {
    commDevice:     { key: 'commDevice',     icon: 'broadcast',         displayKey: 'objects.comm-device',                                            display: null },
    controller:     { key: 'controller',     icon: 'cpu',               displayKey: 'objects.controller',                                             display: null },
    controllerDC01: { key: 'controllerDC01', icon: 'c:dc1',             displayKey: 'site-plan.controller-dc01',                                      display: null },
    controllerDC02: { key: 'controllerDC02', icon: 'c:dc2',             displayKey: 'site-plan.controller-dc02',                                      display: null },
    controllerDC10: { key: 'controllerDC10', icon: 'c:dc10',            displayKey: 'site-plan.controller-dc10',                                      display: null },
    door:           { key: 'door',           icon: 'door-closed',       displayKey: 'objects.door',                                                   display: null },
    reader:         { key: 'reader',         icon: 'g:settings-remote', displayKey: 'objects.reader',                                                 display: null },
    area:           { key: 'area',           icon: 'building',          displayKey: 'objects.area',                                                   display: null },
    camera:         { key: 'camera',         icon: 'camera-video',      displayKey: 'objects.camera',                                                 display: null },
    label:          { key: 'label',          icon: 'info-circle',       displayKey: 'object-properties.site-plan-marker.site-plan-marker-type-label', display: null },
};

export default {
    data() {
        return {
        };
    },

    methods: {
        getHost() {
            var parent = this.$parent;

            while (parent) {
                if (typeof parent.showPopup == 'function') return parent;
                parent = parent.$parent;
            }

            return null;
        },

        getDragging() {
            var host = this.getHost();
            if (host && typeof host.isDragging == 'function' && host.isDragging()) return true;
            return false;
        },

        getThemes() {
            if (themes.primary.display == null) {
                for (var themeKey in themes) {
                    themes[themeKey].display = this.$t(themes[themeKey].displayKey);
                }
            }

            return themes;
        },

        getIcons() {
            if (icons.commDevice.display == null) {
                for (var iconKey in icons) {
                    icons[iconKey].display = this.$t(icons[iconKey].displayKey);
                }
            }

            return icons;
        },

        createSitePlan() {
            return {
                sitePlanId: 0,
                clientId: this.$auth.user().userData.workingClientId,
                name: null,
                areaId: null,
                area: null,
                sitePlanType: 2,
                sitePlanTypeName: 'Image',
                mapLongitude: null,
                mapLatitude: null,
                mapZoom: null,
                mapShowName: false,
                imageFloor: null,
                imageAssetId: null,
                imageName: null,
                imageType: null,
                imageBase64: null,
                imageDirection: null,
                imageTilt: null,
                imageX: null,
                imageY: null,
                imageZoom: null,
                imageShowName: false,
                sitePlanMarkers: [],
                imageAsset: null,
            };
        },

        getTemporaryMarkerId() {
            return --lastTemporaryMarkerId;
        },

        createCommDeviceMarker(commDevice) {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 1,
                sitePlanMarkerTypeName: 'CommDevice',
                commDeviceId: commDevice.commDeviceId,
                commDevice: commDevice,
                name: commDevice.name,
            };
        },

        createControllerMarker(controller) {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 2,
                sitePlanMarkerTypeName: 'Controller',
                controllerId: controller.controllerId,
                controller: controller,
                name: controller.name,
            };
        },

        createDoorMarker(door) {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 3,
                sitePlanMarkerTypeName: 'Door',
                doorId: door.doorId,
                door: door,
                name: door.name,
            };
        },

        createReaderMarker(reader) {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 4,
                sitePlanMarkerTypeName: 'Reader',
                readerId: reader.readerId,
                reader: reader,
                name: `${reader.directionName} ${this.$t('objects.reader')}`,
            };
        },

        createAreaMarker(area) {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 5,
                sitePlanMarkerTypeName: 'Area',
                areaId: area.areaId,
                area: area,
                name: area.name,
            };
        },

        createCameraMarker(camera) {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 6,
                sitePlanMarkerTypeName: 'Camera',
                cameraId: camera.cameraId,
                camera: camera,
                name: camera.name,
            };
        },

        createLabelMarker() {
            return {
                sitePlanMarkerId: this.getTemporaryMarkerId(),
                sitePlanMarkerType: 11,
                sitePlanMarkerTypeName: 'Label',
                name: this.$t('object-properties.site-plan-marker.site-plan-marker-type-label'),
                labelIcon: 'info-circle',
                labelText: 'primary',
                labelAction: null,
                labelActionTarget: null,
            };
        },

        cloneMarker(marker) {
            return {
                ...marker,
            }
        },

        compareMarkers(a, b) {
            if (!a && !b) return true;
            if (!a || !b) return false;
            if (a.sitePlanMarkerType != b.sitePlanMarkerType) return false;

            switch (a.sitePlanMarkerType) {
                case 1: // CommDevice
                    return a.commDeviceId == b.commDeviceId;
                case 2: // Controller
                    return a.controllerId == b.controllerId;
                case 3: // Door
                    return a.doorId == b.doorId;
                case 4: // Reader
                    return a.readerId == b.readerId;
                case 5: // Area
                    return a.areaId == b.areaId;
                case 6: // Camera
                    return a.cameraId == b.cameraId;
                case 11: // Label
                    return a.sitePlanMarkerId == b.sitePlanMarkerId;
                default:
                    return false;
            }
        },

        toVueMarkers(markers) {
            return markers.map(item => {
                return {
                    ...item,
                    longitude: item.mapLongitude,
                    latitude: item.mapLatitude,
                    bearing: item.mapBearing,
                    y: item.imageY,
                    x: item.imageX,
                    rotation: item.imageRotation,
                };
            });
        },

        toApiMarkers(markers) {
            return markers.map(item => {
                return {
                    ...item,
                    sitePlanMarkerId: item.sitePlanMarkerId < 0 ? 0 : item.sitePlanMarkerId,
                    mapLongitude: item.longitude,
                    mapLatitude: item.latitude,
                    mapBearing: item.bearing,
                    imageY: item.y,
                    imageX: item.x,
                    imageRotation: item.rotation,
                };
            });
        },

        getMapBoundsByMarkers(markers) {
            var rtn = {
                min: { longitude: 999, latitude: 999 },
                max: { longitude: -999, latitude: -999 },
            }

            for (var marker of markers) {
                if (marker.longitude) {
                    if (marker.longitude < rtn.min.longitude) rtn.min.longitude = marker.longitude
                    if (marker.longitude > rtn.max.longitude) rtn.max.longitude = marker.longitude
                }
                if (marker.latitude) {
                    if (marker.latitude < rtn.min.latitude) rtn.min.latitude = marker.latitude
                    if (marker.latitude > rtn.max.latitude) rtn.max.latitude = marker.latitude
                }
            }

            if (rtn.min.longitude == 999 || rtn.min.latitude == 999 || rtn.max.longitude == -999 || rtn.max.latitude == -999) return null;

            if (rtn.min.longitude == rtn.max.longitude && rtn.min.latitude == rtn.max.latitude) {
                rtn.min.longitude = rtn.min.longitude - 0.025;
                rtn.min.latitude = rtn.min.latitude - 0.025;
                rtn.max.longitude = rtn.max.longitude + 0.025;
                rtn.max.latitude = rtn.max.latitude + 0.025;
            }
            return rtn;
        },

        async getMapBoundsByCommDevices() {
            var rtn = {
                min: { longitude: 999, latitude: 999 },
                max: { longitude: -999, latitude: -999 },
            }

            try {
                var response = await this.$http.get('/communicationdevice');
                if (response && response.data) {
                    for (var commDevice of response.data) {
                        if (commDevice.longitude) {
                            if (commDevice.longitude < rtn.min.longitude) rtn.min.longitude = commDevice.longitude
                            if (commDevice.longitude > rtn.max.longitude) rtn.max.longitude = commDevice.longitude
                        }
                        if (commDevice.latitude) {
                            if (commDevice.latitude < rtn.min.latitude) rtn.min.latitude = commDevice.latitude
                            if (commDevice.latitude > rtn.max.latitude) rtn.max.latitude = commDevice.latitude
                        }
                    }
                }
            } catch (error) {

            } finally {

            }

            if (rtn.min.longitude == 999 || rtn.min.latitude == 999 || rtn.max.longitude == -999 || rtn.max.latitude == -999) return null;

            if (rtn.min.longitude == rtn.max.longitude && rtn.min.latitude == rtn.max.latitude) {
                rtn.min.longitude = rtn.min.longitude - 0.025;
                rtn.min.latitude = rtn.min.latitude - 0.025;
                rtn.max.longitude = rtn.max.longitude + 0.025;
                rtn.max.latitude = rtn.max.latitude + 0.025;
            }
            return rtn;
        },

        async getMapBoundsByGeolocation() {
            var rtn = {
                min: { longitude: 999, latitude: 999 },
                max: { longitude: -999, latitude: -999 },
            }

            // Location not available in comm device, use browser's geolocation
            // This triggers user premission
            if ('geolocation' in navigator) {
                var getCurrentPositon = new Promise((resolve) => {
                    navigator.geolocation.getCurrentPosition(resolve);
                });
                var position = await getCurrentPositon;
                if (position.coords) {
                    rtn.min.longitude = position.coords.longitude;
                    rtn.min.latitude = position.coords.latitude;
                    rtn.max.longitude = position.coords.longitude;
                    rtn.max.latitude = position.coords.latitude;
                }
            }

            if (rtn.min.longitude == 999 || rtn.min.latitude == 999 || rtn.max.longitude == -999 || rtn.max.latitude == -999) return null;

            if (rtn.min.longitude == rtn.max.longitude && rtn.min.latitude == rtn.max.latitude) {
                rtn.min.longitude = rtn.min.longitude - 0.025;
                rtn.min.latitude = rtn.min.latitude - 0.025;
                rtn.max.longitude = rtn.max.longitude + 0.025;
                rtn.max.latitude = rtn.max.latitude + 0.025;
            }
            return rtn;
        },

        getMapBoundsByUK() {
            var rtn = {
                min: { longitude: 999, latitude: 999 },
                max: { longitude: -999, latitude: -999 },
            }

            // A fallback map bounds to the whole UK
            rtn.min.longitude = -9;
            rtn.min.latitude = 50;
            rtn.max.longitude = 2;
            rtn.max.latitude = 58;

            return rtn;
        },
    },
}