    export default {
    props: {
        theme: {
            type: Object,
            default: function () {
                return {

                }
            }
        },

    },
        data() {
            return {
                clockFormats: [
                    ["Full including weekday and year", "FullDayYear"],
                    ["Full including weekday", "FullDay"],
                    ["Full", "Full"],
                    ["Medium", "Med"],
                    ["Short", "Short"],
                ],
                tickerSpeeds: [
                    ["Slow", 150],
                    ["Normal", 200],
                    ["Fast", 250]
                ],
            }
        },
    }