import { render, staticRenderFns } from "./reader-detail-compact.vue?vue&type=template&id=41b0e586"
import script from "./reader-detail-compact.vue?vue&type=script&lang=js"
export * from "./reader-detail-compact.vue?vue&type=script&lang=js"
import style0 from "./reader-detail-compact.vue?vue&type=style&index=0&id=41b0e586&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports