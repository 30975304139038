export default {
    props: {
        report: {
            type: Object
        },
    },
    data() {
        return {
            validated: false
        }
    },
    computed: {
        timeOptions() {
            var options = ["1 day", "3 days", "5 days", "1 week", "2 weeks", "3 weeks", "1 month", "2 months", "3 months", "6 months", "12 months", "24 months", "Custom"];

            if (this.reportType == 'expired-fob')
                options.unshift("Expired");

            return options;
        }
    },
    watch: {
        report: {
            deep: true,
            handler(report) 
            {
                console.log(report)
                this.$emit('report-changed', report);
            }
        },
        validated(newValidationState) {
            this.$emit('validation-state-changed', newValidationState);
        }
    }
}