import DataSourceColumn from 'common/components/dashboard/data-source/data-source-column';

export default class UnacknowledgedAlertsDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'exclamation-triangle';
        this.title = this.dashboardComponent.$tc('objects.plurals.event-alert', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;

        let column = null;

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event.date-time');
        column.getDisplay = (eventAlarm) => {
            return this.dashboardComponent.$root.$options.filters.formatDateTime(eventAlarm.event.dateTime);
        };
        this.columns.push(column);

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event.location');
        column.getDisplay = (eventAlarm) => {
            if (!eventAlarm || !eventAlarm.event)
                return null;

            if (eventAlarm.event.door)
                return eventAlarm.event.door.name;

            if (eventAlarm.event.area)
                return eventAlarm.event.area.name;

            if (eventAlarm.event.controller)
                return eventAlarm.event.controller.name;

            return null;
        };
        column.getUrl = (eventAlarm) => {
            if (!eventAlarm || !eventAlarm.event)
                return null;

            if (eventAlarm.event.door)
                return `/management/door/${eventAlarm.event.door.doorId}`;

            if (eventAlarm.event.area)
                return `/administration/area/${eventAlarm.event.area.areaId}`;

            if (eventAlarm.event.controller)
                return `/management/controller/${eventAlarm.event.controller.controllerId}`;

            return null;
        };
        this.columns.push(column);

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event.event');
        column.getBadgeVariant = (eventAlarm) => {
            return eventAlarm.acknowledgedDateTime ? 'secondary'
                : eventAlarm.alarmPriority == 20 ? 'danger'
                    : eventAlarm.alarmPriority == 10 ? 'warning'
                        : null
        };
        column.getBadgeDisplay = (eventAlarm) => {
            return eventAlarm.event.eventType;
        };
        this.columns.push(column);

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event-alarm.acknowledgement');
        column.getDisplay = (eventAlarm) => {
            return eventAlarm.acknowledgedDateTime ? `${this.dashboardComponent.$root.$options.filters.formatDateTime(eventAlarm.acknowledgedDateTime)} ${eventAlarm.acknowledgedUser ? 'by ' + eventAlarm.acknowledgedUser.firstName + ' ' + eventAlarm.acknowledgedUser.surname : ''}` : null
        };
        column.getButtonVariant = (eventAlarm) => {
            return eventAlarm.acknowledgedDateTime ? null
                : eventAlarm.alarmPriority == 20 ? 'danger'
                    : eventAlarm.alarmPriority == 10 ? 'warning'
                        : 'primary'
        };
        column.getButtonDisplay = (evnetAlarm) => {
            return this.dashboardComponent.$t('event-alarm.acknowledge');
        };
        column.handleButtonActionAsync = async (eventAlarm) => {
            if (this.dashboardComponent.acknowledgeEventAlarm)
                await this.dashboardComponent.acknowledgeEventAlarm(eventAlarm);
        };
        this.columns.push(column);
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/controller');
        if (!response || !response.data) {
            this.data = [];
            return;
        }

        let controllerIds = response.data.map(c => c.controllerId);
        if (!controllerIds || controllerIds.length == 0) {
            this.data = [];
            return;
        }

        let params = new URLSearchParams();
        for (const controllerId of controllerIds) {
            params.append('controller_id', controllerId);
        }
        params.set('mode', 'unacknowledged');
        // No start date
        //let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6);
        //params.set('start', startDate.toISOString());
        // No end date
        //params.set('end', endDate.toISOString());
        params.set('alarm_priority', 'Alert');

        response = await this.dashboardComponent.$http.get(`/event/alarm?${params.toString()}`);
        if (!response || !response.data) response = { data: [] };
        this.data = response.data;

        if (typeof this.dashboardComponent.subscribeEventAlarmUpdates == 'function')
            this.dashboardComponent.subscribeEventAlarmUpdates(controllerIds);
    }

    getId(eventAlarm) {
        return eventAlarm.eventAlarmId;
    }

    getIcon(eventAlarm) {
        return 'exclamation-triangle';
    }

    getDisplay(eventAlarm) {
        return eventAlarm.event.eventType;
    }

    getVariant(eventAlarm) {
        return eventAlarm.acknowledgedDateTime ? 'muted' : null;
    }

    getUrl(eventAlarm) {
        return `/monitoring/event/${eventAlarm.event.eventId}`;
    }

    async handleActionAsync(eventAlarm) {
        // Do nothing
    }

    getValue(eventAlarm) {
        return null;
    }

    getValueDisplay(eventAlarm) {
        return null;
    }

    getMinValue(eventAlarm) {
        return null;
    }

    getMaxValue(eventAlarm) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
            case 'eventAlarm':
                return await this.processEventAlarmDataAsync(data);
        }
    }

    async processEventAlarmDataAsync(eventAlarm) {
        let index = this.data.findIndex(d => d.eventAlarmId == eventAlarm.eventAlarmId);
        if (index < 0) {
            // Add to the array if it is an unacknowledged alert
            if (eventAlarm.alarmPriority == 10 && !eventAlarm.acknowledgedDateTime)
                this.data.unshift(eventAlarm);
        } else {
            // Update existing alert
            this.data.splice(index, 1, eventAlarm);
            if (eventAlarm.acknowledgedDateTime) {
                // Remove the eventAlarm after 5 seconds if it is acknowledged
                await new Promise(resolve => window.setTimeout(resolve, 5000));
                index = this.data.findIndex(d => d.eventAlarmId == eventAlarm.eventAlarmId);
                if (index >= 0)
                    this.data.splice(index, 1);
            }
        }
    }
}
