// 1 day interval, total 30 days data
const startTime = function () { return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) }
const loadDataDays = 30;
const intervalMilliseconds = 1000 * 60 * 60 * 24;
const intervalCount = 30;

//// 10 second interval, total 1 minute data
//const startTime = function () { return new Date() }
//const loadDataDays = 1;
//const intervalMilliseconds = 1000 * 10;
//const intervalCount = 6;


export default class AlarmOverviewDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'exclamation-triangle-fill';
        this.title = this.dashboardComponent.$tc('objects.plurals.event-alarm', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        var response = await this.dashboardComponent.$http.get('/controller');
        if (!response || !response.data) {
            this.data = [];
            return;
        }

        var controllerIds = response.data.map(c => c.controllerId);
        if (!controllerIds || controllerIds.length == 0) {
            this.data = [];
            return;
        }

        var params = new URLSearchParams();
        for (var controllerId of controllerIds) {
            params.append('controller_id', controllerId);
        }
        var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - loadDataDays + 1);
        params.set('start', startDate.toISOString());
        // No end date
        //params.set('end', endDate.toISOString());
        params.set('alarm_priority', 'Alarm');

        response = await this.dashboardComponent.$http.get(`/event/alarm?${params.toString()}`);
        if (!response || !response.data) response = { data: [] };

        // Populate a initial set of data
        await this.processDateDataAsync(startTime());

        for (var eventAlarm of response.data) {
            await this.processEventAlarmDataAsync(eventAlarm);
        }

        if (typeof this.dashboardComponent.subscribeEventAlarmUpdates == 'function')
            this.dashboardComponent.subscribeEventAlarmUpdates(controllerIds);
    }

    getId(overview) {
        return overview.dateTime;
    }

    getIcon(overview) {
        return 'exclamation-triangle-fill';
    }

    getDisplay(overview) {
        return this.dashboardComponent.$root.$options.filters.formatDate(overview.dateTime);
        //return this.dashboardComponent.$root.$options.filters.formatDateTime(overview.dateTime);
    }

    getVariant(overview) {
        return null;
    }

    getUrl(overview) {
        return null;
    }

    async handleActionAsync(overview) {
        // Do nothing
    }

    getValue(overview) {
        return overview.eventAlarms.length;
    }

    getValueDisplay(overview) {
        return overview.eventAlarms.length;
    }

    getMinValue(overview) {
        return null;
    }

    getMaxValue(overview) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
            case 'date':
                return await this.processDateDataAsync(data);
            case 'eventAlarm':
                return await this.processEventAlarmDataAsync(data);
        }
    }

    async processDateDataAsync(date) {
        if (!date) return;

        let addingOverviews = [];
        let fromTime = null;
        let toTime = null;

        if (!this.data) {
            // Populate data with date range in the past
            fromTime = date.getTime();
            toTime = fromTime + intervalMilliseconds;

            while (addingOverviews.length < intervalCount) {
                addingOverviews.push({
                    dateTime: new Date(fromTime).toISOString(),
                    dateTimeFromInclusive: new Date(fromTime),
                    dateTimeToExclusive: new Date(toTime),
                    eventAlarms: [],
                });

                toTime = fromTime;
                fromTime = toTime - intervalMilliseconds;
            }

            this.data = addingOverviews;
        } else {
            // Move the data window to include the date passed in
            fromTime = Math.max.apply(Math, this.data.map(overview => overview.dateTimeToExclusive.getTime()));

            while (date.getTime() >= fromTime) {
                toTime = fromTime + intervalMilliseconds;

                addingOverviews.unshift({
                    dateTime: new Date(fromTime).toISOString(),
                    dateTimeFromInclusive: new Date(fromTime),
                    dateTimeToExclusive: new Date(toTime),
                    eventAlarms: [],
                });

                fromTime = toTime;
            }

            if (addingOverviews.length) {
                addingOverviews = addingOverviews.concat(this.data).slice(0, intervalCount);
                this.data.splice.apply(this.data, [0, intervalCount].concat(addingOverviews));
            }
        }
    }

    async processEventAlarmDataAsync(eventAlarm) {
        if (!eventAlarm) return;

        let eventDate = new Date(eventAlarm.event.dateTime)
        let eventTime = eventDate.getTime();
        let overview = this.data.find(o => o.dateTimeToExclusive.getTime() > eventTime && eventTime >= o.dateTimeFromInclusive.getTime());

        if (!overview) {
            await this.processDateDataAsync(eventDate);
            overview = this.data.find(o => o.dateTimeToExclusive.getTime() > eventTime && eventTime >= o.dateTimeFromInclusive.getTime());
        }

        if (overview) {
            let index = overview.eventAlarms.findIndex(ea => ea.eventAlarmId == eventAlarm.eventAlarmId);
            if (index < 0) {
                if (eventAlarm.alarmPriority == 20)
                    overview.eventAlarms.push(eventAlarm);
            } else {
                if (eventAlarm.alarmPriority == 20) {
                    overview.eventAlarms.splice(index, 1, eventAlarm);
                } else {
                    overview.eventAlarms.splice(index, 1);
                }
            }

        }
    }
}