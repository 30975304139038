import { render, staticRenderFns } from "./activity-log-table.vue?vue&type=template&id=5e51705c&scoped=true"
import script from "./activity-log-table.vue?vue&type=script&lang=js"
export * from "./activity-log-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e51705c",
  null
  
)

export default component.exports