export default {
    props: {
        defaultLabel: {
            type: Object,
            default: function () {
                return {
                    type: 'group',
                    kind: 'label',
                    text: 'CAME ACS Cloud',
                    fontSize: 36,
                    fontWeight: 'normal',
                    underline: false,
                    fontStyle: 'normal',
                    fontFamily: 'sans-serif',
                    fill: '#ffffffff',
                    textAlign: 'left',
                    offset: 10,
                    cornerRadius: 10,
                    backgroundColor: '#00000077',
                    data: {
                        text: null,
                        fill: null,
                        backgroundColor: null,
                    },
                };
            },
        },
        defaultImage: {
            type: Object,
            default: function () {
                return {
                    type: 'image',
                    src: 'data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNjAiIGhlaWdodD0iMTYwIiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1jYXJkLWltYWdlIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik02LjAwMiA1LjVhMS41IDEuNSAwIDEgMS0zIDAgMS41IDEuNSAwIDAgMSAzIDB6Ii8+CiAgPHBhdGggZD0iTTEuNSAyQTEuNSAxLjUgMCAwIDAgMCAzLjV2OUExLjUgMS41IDAgMCAwIDEuNSAxNGgxM2ExLjUgMS41IDAgMCAwIDEuNS0xLjV2LTlBMS41IDEuNSAwIDAgMCAxNC41IDJoLTEzem0xMyAxYS41LjUgMCAwIDEgLjUuNXY2bC0zLjc3NS0xLjk0N2EuNS41IDAgMCAwLS41NzcuMDkzbC0zLjcxIDMuNzEtMi42Ni0xLjc3MmEuNS41IDAgMCAwLS42My4wNjJMMS4wMDIgMTJ2LjU0QS41MDUuNTA1IDAgMCAxIDEgMTIuNXYtOWEuNS41IDAgMCAxIC41LS41aDEzeiIvPgo8L3N2Zz4=',
                    isDefaultImage: true,
                    cutter: 'rectangle',
                    data: { src: null },
                };
            },
        },
        defaultShape: {
            type: Object,
            default: function () {
                return {
                    type: 'path',
                    kind: 'shape',
                    shape: 'rectangle',
                    width: 160,
                    height: 90,
                    fill: {
                        type: 'linear',
                        gradientUnits: 'percentage',
                        coords: { x1: 0, y1: 0.5, x2: 1, y2: 0.5, r1: 0, r2: 1, },
                        colorStops: [
                            { offset: 0, color: '#000000ff' },
                        ],
                    },
                    strokeWidth: 0,
                    strokeLineJoin: 'miter',
                    stroke: {
                        type: 'linear',
                        gradientUnits: 'percentage',
                        coords: { x1: 0, y1: 0.5, x2: 1, y2: 0.5, r1: 0, r2: 1, },
                        colorStops: [
                            { offset: 0, color: '#ff0000ff' },
                        ],
                    },
                    data: {},
                };
            },
        },
        defaultBadge: {
            type: Object,
            default: function () {
                return {
                    size: 'id1',
                    orientation: 'landscape',
                    lanyardHole: 'none',
                    edge: 'exact',
                };
            },
        },
    },

    computed: {
        wrapperStyle() {
            var maxWidth = Math.max(this.model.width, this.model.underlayPath ? this.model.underlayPath.width : 0, this.model.overlayPath ? this.model.overlayPath.width : 0);
            var maxHeight = Math.max(this.model.height, this.model.underlayPath ? this.model.underlayPath.height : 0, this.model.overlayPath ? this.model.overlayPath.height : 0);
            maxWidth = Math.round(maxWidth * 1000) / 1000;
            maxHeight = Math.round(maxHeight * 1000) / 1000;
            return {
                margin: 'auto',
                position: 'relative',
                width: maxWidth + 'px',
                height: maxHeight + 'px',
                filter: 'drop-shadow(0 0 5px var(--secondary))',
            };
        },
        underlayStyle() {
            if (!this.model.underlayPath) return null;
            var maxWidth = Math.max(this.model.width, this.model.underlayPath ? this.model.underlayPath.width : 0, this.model.overlayPath ? this.model.overlayPath.width : 0);
            var maxHeight = Math.max(this.model.height, this.model.underlayPath ? this.model.underlayPath.height : 0, this.model.overlayPath ? this.model.overlayPath.height : 0);
            maxWidth = Math.round(maxWidth * 1000) / 1000;
            maxHeight = Math.round(maxHeight * 1000) / 1000;
            var style = {
                position: 'absolute',
                top: ((maxHeight - this.model.underlayPath.height) / 2) + 'px',
                left: ((maxWidth - this.model.underlayPath.width) / 2) + 'px',
                width: this.model.underlayPath.width + 'px',
                height: this.model.underlayPath.height + 'px',
                'background-color': this.model.underlayPath.fill,
            };
            if (this.model.underlayPath.clipPath) style['clip-path'] = `path('${this.model.underlayPath.clipPath}')`;
            return style;
        },
        canvasStyle() {
            var maxWidth = Math.max(this.model.width, this.model.underlayPath ? this.model.underlayPath.width : 0, this.model.overlayPath ? this.model.overlayPath.width : 0);
            var maxHeight = Math.max(this.model.height, this.model.underlayPath ? this.model.underlayPath.height : 0, this.model.overlayPath ? this.model.overlayPath.height : 0);
            maxWidth = Math.round(maxWidth * 1000) / 1000;
            maxHeight = Math.round(maxHeight * 1000) / 1000;
            return {
                position: 'absolute',
                top: ((maxHeight - this.model.height) / 2) + 'px',
                left: ((maxWidth - this.model.width) / 2) + 'px',
                width: this.model.width + 'px',
                height: this.model.height + 'px',
            };
        },
        overlayStyle() {
            if (!this.model.overlayPath) return null;
            var maxWidth = Math.max(this.model.width, this.model.underlayPath ? this.model.underlayPath.width : 0, this.model.overlayPath ? this.model.overlayPath.width : 0);
            var maxHeight = Math.max(this.model.height, this.model.underlayPath ? this.model.underlayPath.height : 0, this.model.overlayPath ? this.model.overlayPath.height : 0);
            maxWidth = Math.round(maxWidth * 1000) / 1000;
            maxHeight = Math.round(maxHeight * 1000) / 1000;
            var style = {
                position: 'absolute',
                'pointer-events': 'none',
                top: ((maxHeight - this.model.overlayPath.height) / 2) + 'px',
                left: ((maxWidth - this.model.overlayPath.width) / 2) + 'px',
                width: this.model.overlayPath.width + 'px',
                height: this.model.overlayPath.height + 'px',
                'background-color': this.model.overlayPath.fill,
            };
            if (this.model.overlayPath.clipPath) style['clip-path'] = `path('${this.model.overlayPath.clipPath}')`;
            return style;
        },
    },

    methods: {
        mergeModel(value) {
            if (value.width) this.model.width = value.width;
            if (value.height) this.model.height = value.height;

            if (value.background) this.model.background = value.background;
            if (value.data && value.data.background) this.model.data.background = value.data.background;

            if (value.backgroundImage && value.backgroundImage.src) {
                this.model.backgroundImage = {
                    src: value.backgroundImage.src,
                    isBackgroundImage: value.backgroundImage.isBackgroundImage ? value.backgroundImage.isBackgroundImage : true,
                    data: {
                        src: value.backgroundImage.data?.src ? value.backgroundImage.data.src : null,
                    },
                }
            } else {
                this.model.backgroundImage = {
                    src: null,
                    isBackgroundImage: true,
                    data: {
                        src: null,
                    }
                }
            }

            if (value.badge) this.model.badge = {
                size: value.badge.size ? value.badge.size : this.defaultBadge.size,
                orientation: value.badge.orientation ? value.badge.orientation : this.defaultBadge.orientation,
                lanyardHole: value.badge.lanyardHole ? value.badge.lanyardHole : this.defaultBadge.lanyardHole,
                edge: value.badge.edge ? value.badge.edge : this.defaultBadge.edge,
            };
            else this.model.badge = null;

            if (value.underlayPath) this.model.underlayPath = {
                width: value.underlayPath.width,
                height: value.underlayPath.height,
                fill: value.underlayPath.fill,
                clipPath: value.underlayPath.clipPath,
            };
            else this.model.underlayPath = null;

            if (value.overlayPath) this.model.overlayPath = {
                width: value.overlayPath.width,
                height: value.overlayPath.height,
                fill: value.overlayPath.fill,
                clipPath: value.overlayPath.clipPath,
            };
            else this.model.overlayPath = null;

            if (value.objects && Array.isArray(value.objects)) {
                if (value.objects.length) this.lastObjectId = Math.max.apply(null, value.objects.map(object => object.objectId ? object.objectId : 0));
                else this.lastObjectId = 0;

                this.model.objects = value.objects.map(object => {
                    switch (object.type) {
                        case 'group':
                            if (object.kind == 'label') {
                                var textObject = object;
                                var rectObject = object;
                                var backgroundColor = object.backgroundColor;
                                if (Array.isArray(object.objects)) {
                                    rectObject = object.objects[0];
                                    textObject = object.objects[1];

                                    backgroundColor = rectObject.fill;
                                }

                                return {
                                    objectId: object.objectId ? object.objectId : ++this.lastObjectId,
                                    type: object.type,
                                    kind: object.kind,
                                    text: textObject.text !== undefined ? textObject.text : this.defaultLabel.text,
                                    textAlign: textObject.textAlign !== undefined ? textObject.textAlign : this.defaultLabel.textAlign,
                                    fontFamily: textObject.fontFamily !== undefined ? textObject.fontFamily : this.defaultLabel.fontFamily,
                                    fontSize: textObject.fontSize !== undefined ? textObject.fontSize : this.defaultLabel.fontSize,
                                    fontWeight: textObject.fontWeight !== undefined ? textObject.fontWeight : this.defaultLabel.fontWeight,
                                    underline: textObject.underline !== undefined ? textObject.underline : this.defaultLabel.underline,
                                    fontStyle: textObject.fontStyle !== undefined ? textObject.fontStyle : this.defaultLabel.fontStyle,
                                    fill: textObject.fill !== undefined ? textObject.fill : this.defaultLabel.fill,
                                    offset: object.offset !== undefined ? object.offset : this.defaultLabel.offset,
                                    cornerRadius: object.cornerRadius !== undefined ? object.cornerRadius : this.defaultLabel.cornerRadius,
                                    backgroundColor: backgroundColor !== undefined ? backgroundColor : this.defaultLabel.backgroundColor,
                                    data: {
                                        text: object.data.text ? object.data.text : this.defaultLabel.data.text,
                                        fill: object.data.fill ? object.data.fill : this.defaultLabel.data.fill,
                                        backgroundColor: object.data.backgroundColor ? object.data.backgroundColor : this.defaultLabel.data.backgroundColor,
                                    },

                                    originX: object.originX,
                                    originY: object.originY,
                                    top: object.top,
                                    left: object.left,
                                    scaleX: object.scaleX,
                                    scaleY: object.scaleY,
                                    scalePercentageX: object.scaleX * 100,
                                    scalePercentageY: object.scaleY * 100,
                                    angle: object.angle,

                                    vue: this,
                                };
                            } else return {};
                        case 'image':
                            if (object.src) {
                                return {
                                    objectId: object.objectId ? object.objectId : ++this.lastObjectId,
                                    type: object.type,
                                    src: object.src,
                                    isDefaultImage: object.isDefaultImage,
                                    cutter: object.cutter ? object.cutter : this.defaultImage.cutter,
                                    data: {
                                        src: object.data.src ? object.data.src : this.defaultImage.data.src
                                    },

                                    originX: object.originX,
                                    originY: object.originY,
                                    top: object.top,
                                    left: object.left,
                                    scaleX: object.scaleX,
                                    scaleY: object.scaleY,
                                    scalePercentageX: object.scaleX * 100,
                                    scalePercentageY: object.scaleY * 100,
                                    angle: object.angle,

                                    vue: this,
                                };
                            } else return {};
                        case 'path':
                            if (object.kind == 'shape') {
                                return {
                                    objectId: object.objectId ? object.objectId : ++this.lastObjectId,
                                    type: object.type,
                                    kind: object.kind,
                                    shape: object.shape ? object.shape : this.defaultShape.shape,
                                    width: object.width ? object.width : this.defaultShape.width,
                                    height: object.height ? object.height : this.defaultShape.height,
                                    fill: {
                                        type: object.fill && object.fill.type ? object.fill.type : this.defaultShape.fill.type,
                                        gradientUnits: object.fill && object.fill.gradientUnits ? object.fill.gradientUnits : this.defaultShape.fill.gradientUnits,
                                        coords: {
                                            x1: object.fill && object.fill.coords && object.fill.coords.x1 ? object.fill.coords.x1 : this.defaultShape.fill.coords.x1,
                                            y1: object.fill && object.fill.coords && object.fill.coords.y1 ? object.fill.coords.y1 : this.defaultShape.fill.coords.y1,
                                            x2: object.fill && object.fill.coords && object.fill.coords.x2 ? object.fill.coords.x2 : this.defaultShape.fill.coords.x2,
                                            y2: object.fill && object.fill.coords && object.fill.coords.y2 ? object.fill.coords.y2 : this.defaultShape.fill.coords.y2,
                                            r1: object.fill && object.fill.coords && object.fill.coords.r1 ? object.fill.coords.r1 : this.defaultShape.fill.coords.r1,
                                            r2: object.fill && object.fill.coords && object.fill.coords.r2 ? object.fill.coords.r2 : this.defaultShape.fill.coords.r2,
                                        },
                                        colorStops: (object.fill && object.fill.colorStops ? object.fill.colorStops : this.defaultShape.fill.colorStops).map((item, index) => {
                                            return {
                                                offset: item.offset ? item.offset : this.defaultShape.fill.colorStops[index].offset,
                                                color: item.color ? item.color : this.defaultShape.fill.colorStops[index].color,
                                            };
                                        }),
                                    },
                                    strokeWidth: object.strokeWidth ? object.strokeWidth : this.defaultShape.strokeWidth,
                                    strokeLineJoin: object.strokeLineJoin ? object.strokeLineJoin : this.defaultShape.strokeLineJoin,
                                    stroke: {
                                        type: object.stroke && object.stroke.type ? object.stroke.type : this.defaultShape.stroke.type,
                                        gradientUnits: object.stroke && object.stroke.gradientUnits ? object.stroke.gradientUnits : this.defaultShape.stroke.gradientUnits,
                                        coords: {
                                            x1: object.stroke && object.stroke.coords && object.stroke.coords.x1 ? object.stroke.coords.x1 : this.defaultShape.stroke.coords.x1,
                                            y1: object.stroke && object.stroke.coords && object.stroke.coords.y1 ? object.stroke.coords.y1 : this.defaultShape.stroke.coords.y1,
                                            x2: object.stroke && object.stroke.coords && object.stroke.coords.x2 ? object.stroke.coords.x2 : this.defaultShape.stroke.coords.x2,
                                            y2: object.stroke && object.stroke.coords && object.stroke.coords.y2 ? object.stroke.coords.y2 : this.defaultShape.stroke.coords.y2,
                                            r1: object.stroke && object.stroke.coords && object.stroke.coords.r1 ? object.stroke.coords.r1 : this.defaultShape.stroke.coords.r1,
                                            r2: object.stroke && object.stroke.coords && object.stroke.coords.r2 ? object.stroke.coords.r2 : this.defaultShape.stroke.coords.r2,
                                        },
                                        colorStops: (object.stroke && object.stroke.colorStops ? object.stroke.colorStops : this.defaultShape.stroke.colorStops).map((item, index) => {
                                            return {
                                                offset: item.offset ? item.offset : this.defaultShape.stroke.colorStops[index].offset,
                                                color: item.color ? item.color : this.defaultShape.stroke.colorStops[index].color,
                                            };
                                        }),
                                    },
                                    data: {},

                                    originX: object.originX,
                                    originY: object.originY,
                                    top: object.top,
                                    left: object.left,
                                    scaleX: object.scaleX,
                                    scaleY: object.scaleY,
                                    scalePercentageX: object.scaleX * 100,
                                    scalePercentageY: object.scaleY * 100,
                                    angle: object.angle,

                                    vue: this,
                                };
                            } else return {};
                        default:
                            return {};
                    }
                }).filter(object => object);
            } else {
                this.lastObjectId = 0;
                this.model.objects = [];
            }
        },
    },
}