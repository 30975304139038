 function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}


function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

    const messages = {}
    const keys = locales.keys()

    const addKeysFunction = key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]

            if (messages[locale] == undefined) {
                messages[locale] = locales(key)
            }
            else {
                messages[locale] = mergeDeep(messages[locale], locales(key));
            }
        }
    }

    const baseLocaleKeys = keys.filter(key => key.split('.').length == 3);
    baseLocaleKeys.forEach(addKeysFunction)

    if (process.env.VUE_APP_PROJECT_ACS == "true") {
        const overrideLocaleKeys = keys.filter(key => (key.match(/.acs+/g) || []).length == 1);
        overrideLocaleKeys.forEach(addKeysFunction)
    }

    return messages
}

export {
    loadLocaleMessages
}