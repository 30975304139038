<template>
    <div class="bg-white d-flex justify-content-center align-items-center"
         style="position: fixed; top:0; left:0; bottom: 0; right:0;">
        <div style="position: fixed; top:0; left:0; bottom: 0; right:0;"
             id="public-registration-status-page-background">
        </div>

        <div v-if="message == 'success'"
             class="card m-3"
             style="max-height: 80%; max-width: 24rem;">
            <div class="card-body">
                <h5 class="card-text">{{$t('public-registration.success-title')}}</h5>
                <p class="card-text">{{$t('public-registration.success-message-1')}}</p>
                <h1 class="card-text text-center">{{reference}}</h1>
                <p class="card-text">{{$t('public-registration.success-message-2')}}</p>
                <p class="card-text">{{$t('public-registration.success-message-3')}}</p>
            </div>
        </div>

        <div v-if="!message && publicRegistration"
             class="card m-3"
             style="max-height: 80%; max-width: 36rem;">
            <div class="card-header bg-white d-flex flex-gap-2 align-items-center justify-content-between">
                <h5 class="m-0">{{$t('objects.public-registration')}}</h5>
                <h1 class="text-nowrap m-0">{{publicRegistration.reference}}</h1>
            </div>

            <div class="card-body">
                <div class="card-text">
                    <b-icon icon="person" class="inline" />
                    {{publicRegistration.firstName}} {{publicRegistration.surname}}
                </div>
                <div class="card-text">
                    <b-icon icon="envelope" class="inline" />
                    {{publicRegistration.emailAddress}}
                </div>
                <div class="card-text" v-if="publicRegistration.mobileNumber">
                    <b-icon icon="phone" class="inline" />
                    {{publicRegistration.mobileNumber}}
                </div>
                <div class="card-text" v-if="publicRegistration.phoneNumber">
                    <b-icon icon="telephone" class="inline" />
                    {{publicRegistration.phoneNumber}}
                </div>
                <div class="card-text" v-if="publicRegistration.name">
                    <b-icon icon="shop" class="inline" />
                    {{publicRegistration.name}}
                </div>

                <div v-if="publicRegistration.isEngineer">
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.verifyDatetime">
                        <b-icon icon="check-circle" />
                        {{$t('object-properties.public-registration.status-verified')}}
                    </div>
                    <div class="alert alert-info h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="!publicRegistration.approveDatetime">
                        <b-icon icon="question-circle" />
                        {{$t('object-properties.public-registration.status-pending-approval')}}
                    </div>
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.approveDatetime">
                        <b-icon icon="check-circle" />
                        {{$t('object-properties.public-registration.status-approved')}}
                    </div>
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.completeDatetime">
                        <b-icon icon="check-circle" />
                        {{$t('object-properties.public-registration.status-completed')}}
                    </div>
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.cancelDatetime">
                        <b-icon icon="x-circle" />
                        {{$t('object-properties.public-registration.status-cancelled')}}
                    </div>
                </div>

                <div v-if="!publicRegistration.isEngineer">
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.verifyDatetime">
                        <b-icon icon="check-circle" />
                        {{$t('object-properties.public-registration.status-verified')}}
                    </div>
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.completeDatetime">
                        <b-icon icon="check-circle" />
                        {{$t('object-properties.public-registration.status-completed')}}
                    </div>
                    <div class="alert alert-success h2 d-flex flex-gap-2 align-items-center mt-3 mb-0"
                         v-if="publicRegistration.cancelDatetime">
                        <b-icon icon="x-circle" />
                        {{$t('object-properties.public-registration.status-cancelled')}}
                    </div>
                </div>

                <div class="mt-3" v-if="publicRegistration.remarks">
                    <h5>{{$t('object-properties.public-registration.remarks')}}</h5>
                    <pre class="card-text mb-0 font-inherit">{{publicRegistration.remarks}}</pre>
                </div>

                <div class="mt-3">
                    <router-link to="/login">{{ $t('login.back-to-login') }}</router-link>
                </div>
            </div>
        </div>

        <div v-if="!message && !publicRegistration && loading">
            <loading v-if="loading" />
        </div>

        <div v-if="!message && !publicRegistration && !loading"
             class="card m-3"
             style="max-height: 80%; max-width: 24rem;">
            <div class="card-body">
                <h5 class="card-text">{{$t('public-registration.not-found-title')}}</h5>
                <p class="card-text">{{$t('public-registration.not-found-message')}}</p>
                <h1 class="card-text text-center">{{reference}}</h1>
            </div>
        </div>
    </div>
</template>

<script>
    import ParticlesMixin from 'common/mixins/particles';
    import ApiData from 'common/mixins/api-data';

    export default {
        mixins: [ParticlesMixin, ApiData],

        props: {
            reference: null,
        },

        components: {
        },

        data() {
            return {
                publicRegistration: null,
                loading: false,
            }
        },

        computed: {
            message() {
                if ('message' in this.$route.query) return this.$route.query['message'];
                return null;
            },
            token() {
                if ('token' in this.$route.query) return this.$route.query['token'];
                return null;
            },
            publicRegistrationUrl() {
                if (this.message) return null;

                return `/publicregistration/reference/${this.reference}?token=${this.token}`;
            },
        },

        methods: {
            logout() {
                try {
                    this.$auth.logout({
                        makeRequest: false,
                        params: {},
                        success: () => { },
                        error: () => { },
                        redirect: null,
                    });
                } catch (error) {
                    this.$debugLog(error);
                }
            },
            async loadPublicRegistration() {
                try {
                    this.loading = true;
                    this.apiError = [];

                    if (this.publicRegistrationUrl == null) {
                        this.publicRegistration = null;
                        return;
                    }

                    var response = await this.$http.get(this.publicRegistrationUrl);

                    if (!response || !response.data) this.throwNoResponseApiError();
                    this.publicRegistration = response.data;
                } catch (error) {
                    this.publicRegistration = null;
                } finally {
                    this.loading = false;
                }
            }
        },

        watch: {
            async publicRegistrationUrl() {
                await this.loadPublicRegistration();
            }
        },

        async mounted() {
            if (this.isACS) await this.createParticles('public-registration-status-page-background');

            this.logout();

            await this.loadPublicRegistration();
        },
    };
</script>
