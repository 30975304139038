let setupFetchify = async function () {

    var url = "https://cc-cdn.com/generic/scripts/v1/cc_c2a.min.js";

    var scriptLoaded = false;
    var scripts = document.getElementsByTagName('script');

    for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) {
            scriptLoaded = true;
            break;
        }
    }

    if (scriptLoaded == true)
        return Promise.resolve();  

    var plugin = document.createElement("script");
    plugin.setAttribute(
        "src",
        url
    );
    plugin.async = false;

    return new Promise(resolve => {

        plugin.addEventListener('load', () => {
            //console.log("loaded fetchify script");
            resolve();
        })

        document.head.appendChild(plugin);
    });
}

let globalFetchify = null;

export default async function () {

    await setupFetchify();

    if (globalFetchify == null) {
        globalFetchify = new clickToAddress({
            accessToken: '7423f-92b33-73ee7-0958b',
            domMode: 'id',
            countrySelector: false,
            getIpLocation: false,
            showLogo: false,
            enabledCountries: ['gbr'],
            defaultCountry: 'gbr',
        });
    }

    return globalFetchify;
}