    export default {
        props: {
            noticeBoard: {
                type: Object,
                default() {
                    return {
                        themeId: '',
                        type: 3,
                        transition: 2
                    }
                }
            }
        },
        components: {},
        data() {
            return {
                selectedNightMode: "auto",
                nightModeOptions: [
                    ["Auto switch night/day", "auto"],
                    ["Night mode only", "night"],
                    ["Light mode only", "light"]
                ],
                newNoticeBoardTypes: [
                    ["IP/4G Modem", 3],
                    ["Comms Module", 4]
                ],
                existingNoticeBoardTypes: [
                    ["Existing IP", 1],
                    ["Serial", 2]
                ],
                transitions: [
                    ["None", 0],
                    ["Swipe", 1],
                    ["Fade", 2]
                ]
            }
        },
    }