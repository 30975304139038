import { render, staticRenderFns } from "./person-invite-to-app-button.vue?vue&type=template&id=5f356f9c"
import script from "./person-invite-to-app-button.vue?vue&type=script&lang=js"
export * from "./person-invite-to-app-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports