let htmlElementIds = [];

export default {
    methods: {
        async createParticles(htmlElementId) {
            if (!this.isACS)
                return;

            if (htmlElementIds.includes(htmlElementId))
                return;

            if (!window.particlesJS) {
                await import('particles.js');
            }

            // Wait for element to be rendered
            await this.$nextTick();
            if (!document.getElementById(htmlElementId)) return;

            var particlesJsonConfiguration = {
                "particles": {
                    "number": {
                        "value": 100,
                        "density": {
                            "enable": true,
                            "value_area": 800
                        }
                    },
                    "color": {
                        "value": "#00b0ed"
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                        "polygon": {
                            "nb_sides": 7
                        },
                        "image": {
                            "src": "img/github.svg",
                            "width": 100,
                            "height": 100
                        }
                    },
                    "opacity": {
                        "value": 0,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.5,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 3,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 191.92323070771698,
                        "color": "#00b0ed",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 6,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                            "mode": "repulse"
                        },
                        "onclick": {
                            "enable": false,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 400,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            }

            /* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
            window.particlesJS(htmlElementId, particlesJsonConfiguration);
            htmlElementIds.push(htmlElementId);
        },

        destroyParticles(htmlElementId) {
            if (!Array.isArray(window.pJSDom)) return;

            let pjsIndex = null;
            for (let index = 0; index < window.pJSDom.length; index++) {
                if (window.pJSDom[index] && window.pJSDom[index].pJS && window.pJSDom[index].pJS.canvas && window.pJSDom[index].pJS.canvas.el) {
                    let canvas = window.pJSDom[index].pJS.canvas.el;
                    let parent = canvas.parentElement;
                    if (parent && parent.id == htmlElementId) {
                        pjsIndex = index;
                    }
                }
            }

            if (pjsIndex == null) return;

            if (window.pJSDom[pjsIndex].pJS && window.pJSDom[pjsIndex].pJS.fn && window.pJSDom[pjsIndex].pJS.fn.particlesEmpty) {
                window.pJSDom[pjsIndex].pJS.fn.particlesEmpty();
                window.pJSDom.splice(pjsIndex, 1);
            }

            let index = htmlElementIds.indexOf(htmlElementId);
            if (index >= 0)
                htmlElementIds.splice(index, 1);
        },
    },

    beforeDestroy() {
        for (let htmlElementId of htmlElementIds)
            this.destroyParticles(htmlElementId);
    }
}