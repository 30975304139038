function calculateTotal(entries) {
    var reduced = entries.reduce((previous, current) => {
        var total = 0;

        if (typeof previous.total == 'number') total += previous.total;
        if ((typeof previous.unitPrice == 'number') && (typeof previous.quantity == 'number')) total += previous.unitPrice * previous.quantity;

        if (typeof current.total == 'number') total += current.total;
        if ((typeof current.unitPrice == 'number') && (typeof current.quantity == 'number')) total += current.unitPrice * current.quantity;

        return {
            total: total,
        }
    }, {
        total: 0,
    });

    return reduced.total;
}

export default {
    states: {
        shoppingCartEntries: [],
        shoppingCartEntriesGrandTotal: 0,
        shopOrdersPageSelectedStatuses: [40, 41, 50],
    },

    mutations: {
        addToShoppingCart(state, entry) {
            if (!entry) return;

            var find = state.shoppingCartEntries.find(item => item.partNumberId == entry.partNumberId && item.typeData == entry.typeData);
            if (find) {
                find.quantity += entry.quantity;
            } else {
                entry.shopOrderId = 0;
                entry.shopOrderItemId = state.shoppingCartEntries.reduce((acc, item) => {
                    if (item.shopOrderItemId < acc) {
                        return item.shopOrderItemId;
                    } else {
                        return acc;
                    }
                }, 0) - 1;

                state.shoppingCartEntries.push(entry);
            }

            state.shoppingCartEntriesGrandTotal = calculateTotal(state.shoppingCartEntries);
        },

        setShoppingCartItemQuantity(state, parameters) {
            var find = state.shoppingCartEntries.find(item => item.shopOrderItemId == parameters.entry.shopOrderItemId);
            if (find) {
                find.quantity = parameters.quantity;

                state.shoppingCartEntriesGrandTotal = calculateTotal(state.shoppingCartEntries);
            }
        },

        removeShoppingCart(state, entry) {
            var index = state.shoppingCartEntries.findIndex(item => item.shopOrderItemId == entry.shopOrderItemId);
            if (index >= 0) {
                state.shoppingCartEntries.splice(index, 1);

                state.shoppingCartEntriesGrandTotal = calculateTotal(state.shoppingCartEntries);
            }
        },

        clearShoppingCart(state) {
            state.shoppingCartEntries.splice(0, state.shoppingCartEntries.length);
            state.shoppingCartEntriesGrandTotal = 0;
        },

        setShopOrdersPageSelectedStatuses(state, statuses) {
            state.shopOrdersPageSelectedStatuses.splice(0, state.shopOrdersPageSelectedStatuses.length);
            Array.prototype.push.apply(state.shopOrdersPageSelectedStatuses, statuses);
        },
    },
}