export default class CreateObjectsDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'plus-circle';
        this.title = this.dashboardComponent.$t('actions.create');
        this.data = [
            {
                display: `${this.dashboardComponent.$t('actions.add')} ${this.dashboardComponent.$t('objects.fob')}`,
                icon: 'g:vpn key',
                url: '/administration/fob/create',
            },
            {
                display: this.dashboardComponent.$t('objects.person'),
                icon: 'people',
                url: '/administration/person/create',
            },
            {
                display: this.dashboardComponent.$t('objects.user'),
                icon: 'person',
                url: '/settings/user/create',
            },
        ];
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        // Do nothing
    }

    getId(create) {
        return create.display;
    }

    getIcon(create) {
        return create.icon;
    }

    getDisplay(create) {
        return create.display;
    }

    getVariant(create) {
        return null;
    }

    getUrl(create) {
        return create.url;
    }

    async handleActionAsync(create) {
        // Do nothing
    }

    getValue(create) {
        return null;
    }

    getValueDisplay(create) {
        return null;
    }

    getMinValue(create) {
        return null;
    }

    getMaxValue(create) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}