import DataSourceColumn from 'common/components/dashboard/data-source/data-source-column';

export default class DataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'g:calendar-month';
        this.title = this.dashboardComponent.$t('dashboard.recent-events.name');
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;

        let column = null;

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event.date-time');
        column.getDisplay = (event) => {
            return this.dashboardComponent.$root.$options.filters.formatDateTime(event.dateTime);
        };
        this.columns.push(column);

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('objects.person');
        column.getDisplay = (event) => {
            if (!event || !event.fob || !event.fob.person) return '';
            return `${event.fob.person.firstName} ${event.fob.person.surname}`;
        };
        column.getUrl = (event) => {
            if (!event || !event.fob || !event.fob.person) return null;
            return `/administration/people/${event.fob.person.personId}`;
        };
        this.columns.push(column);

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event.location');
        column.getDisplay = (event) => {
            if (!event)
                return null;

            if (event.door)
                return event.door.name;

            if (event.area)
                return event.area.name;

            if (event.controller)
                return event.controller.name;

            return null;
        };
        column.getUrl = (event) => {
            if (!event)
                return null;

            if (event.door)
                return `/management/door/${event.door.doorId}`;

            if (event.area)
                return `/administration/area/${event.area.areaId}`;

            if (event.controller)
                return `/management/controller/${event.controller.controllerId}`;

            return null;
        };
        this.columns.push(column);

        column = new DataSourceColumn(this);
        column.header = this.dashboardComponent.$t('object-properties.event.event');
        column.getBadgeVariant = (event) => {
            return event.eventTypeClass;
        };
        column.getBadgeDisplay = (event) => {
            return event.eventType;
        };
        this.columns.push(column);
    }

    async loadDataAsync() {
        var response = await this.dashboardComponent.$http.get(`/event/recent-events`);
        if (!response || !response.data) {
            this.data = [];
            return;
        }

        this.data = response.data;

        var subscribe = {};
        for (const headerKey in response.headers) {
            if (headerKey.startsWith('subscribe-')) {
                const values = JSON.parse(response.headers[headerKey]);
                if (Array.isArray(values)) {
                    subscribe[headerKey] = values;
                }
            }
        }

        if (subscribe && typeof this.dashboardComponent.subscribeEventReceived == 'function')
            this.dashboardComponent.subscribeEventReceived(subscribe);
    }

    getId(event) {
        return event.eventId;
    }

    getIcon(event) {
        return null;
    }

    getDisplay(event) {
        return null;
    }

    getVariant(event) {
        return null;
    }

    getUrl(event) {
        return `/monitoring/event/${event.eventId}`;
    }

    async handleActionAsync(event) {
        // Do nothing
    }

    getValue(event) {
        return null;
    }

    getValueDisplay(event) {
        return null;
    }

    getMinValue(event) {
        return null;
    }

    getMaxValue(event) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
            case 'event':
                return await this.processEventDataAsync(data);
        }
    }

    async processEventDataAsync(event) {
        let index = this.data.findIndex(d => d.eventId == event.eventId);
        if (index < 0) {
            // Add new event at the start of the data
            this.data.unshift(event);
            // Stores maximum 20 events in data
            if (this.data.length > 20)
                this.data.splice(index, this.data.length - 20);
        } else {
            // Update existing event
            this.data.splice(index, 1, event);
        }
    }
}