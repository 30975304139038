export default {
    methods: {
        loadCanvasModel(fabricModel, vueModel) {
            return new Promise((resolve) => {
                this.canvas.loadFromJSON(JSON.stringify(fabricModel), resolve, (object, fabricObject) => {
                    var vueObject;

                    switch (object.type) {
                        case 'group':
                            if (object.kind === 'label') {
                                vueObject = vueModel.objects.find(item => item.objectId == object.objectId);
                                this.setupFabricGroupLabel(fabricObject, vueObject);
                            }
                            break;
                        case 'image':
                            vueObject = vueModel.objects.find(item => item.objectId == object.objectId);
                            this.setupFabricImage(fabricObject, vueObject);
                            break;
                        case 'path':
                            if (object.kind === 'shape') {
                                vueObject = vueModel.objects.find(item => item.objectId == object.objectId);
                                this.setupFabricShape(fabricObject, vueObject);
                            }
                            break;
                        default:
                    }
                });
            });
        },

        setupFabricGroupLabel(fabricGroup, vueLabel) {
            // Override toObject() to save additional properties
            var groupOriginalToObject = fabricGroup.toObject;
            fabricGroup.toObject = function (additionalProperties) {
                return groupOriginalToObject.call(this, ['objectId', 'kind', 'cornerRadius', 'offset', 'data'].concat(additionalProperties));
            };

            vueLabel.fabric = fabricGroup;
            vueLabel.updateSize = function (vueComponent) {
                // "this" is vueLabel now, use "vueComponent" to "this" of this mixin
                var fabricRect = this.fabric.item(0);
                var fabricText = this.fabric.item(1);

                var width = fabricText.width + this.offset * 2;
                var height = fabricText.height + this.offset * 2;

                fabricRect.set('width', width);
                fabricRect.set('height', height);

                this.fabric.set('width', width);
                this.fabric.set('height', height);
                this.fabric.clipPath = vueComponent.toFabricPath(vueComponent.getRoundedRectangleShapeCommand(width, height, this.cornerRadius));
            }

            this.setupVueObjectPositionReactivity(vueLabel);
        },

        setupFabricImage(fabricImage, vueImage) {
            // Override toObject() to save additional properties
            var originalToObject = fabricImage.toObject;
            fabricImage.toObject = function (additionalProperties) {
                return originalToObject.call(this, ['objectId', 'isDefaultImage', 'cutter', 'data'].concat(additionalProperties));
            }

            vueImage.fabric = fabricImage;
            vueImage.updateSize = function (vueComponent) {
                // "this" is vueImage now, use "vueComponent" to "this" of this mixin
                vueComponent.applyVuePropertyString(vueImage, 'cutter');
            }

            this.setupVueObjectPositionReactivity(vueImage);
        },

        setupFabricShape(fabricPath, vueShape) {
            // Override toObject() to save additional properties
            var originalToObject = fabricPath.toObject;
            fabricPath.toObject = function (additionalProperties) {
                return originalToObject.call(this, ['objectId', 'kind', 'shape', 'data'].concat(additionalProperties));
            }

            vueShape.fabric = fabricPath;
            vueShape.updateSize = function (vueComponent) {
                // "this" is vueShape now, use "vueComponent" to "this" of this mixin
                vueComponent.applyVuePropertyString(vueShape, 'shape');
            }

            this.setupVueObjectPositionReactivity(vueShape);
        },

        setupFabricBackgroundImage(fabricImage, vueImage) {
            // Override toObject() to save additional properties
            var originalToObject = fabricImage.toObject;
            fabricImage.toObject = function (additionalProperties) {
                return originalToObject.call(this, ['isBackgroundImage', 'data'].concat(additionalProperties));
            }

            vueImage.fabric = fabricImage;

            this.setupVueObjectPositionReactivity(vueImage);
        },

        setupVueObjectPositionReactivity(vueObject) {
            this.setVueObjectPosition(vueObject);

            vueObject.fabric.on('modified', () => this.setVueObjectPosition(vueObject));
        },

        setVueObjectPosition(vueObject, position) {
            if (position) {
                if (position.originX !== undefined) vueObject.fabric.originX = position.originX;
                if (position.originY !== undefined) vueObject.fabric.originY = position.originY;
                if (position.angle !== undefined) vueObject.fabric.angle = position.angle;
                if (position.top !== undefined) vueObject.fabric.top = position.top;
                if (position.left !== undefined) vueObject.fabric.left = position.left;
                if (position.scaleX !== undefined) vueObject.fabric.scaleX = position.scaleX;
                if (position.scaleY !== undefined) vueObject.fabric.scaleY = position.scaleY;

                vueObject.fabric.set('dirty', true);
            }

            this.$set(vueObject, 'angle', vueObject.fabric.angle);
            this.$set(vueObject, 'top', vueObject.fabric.top);
            this.$set(vueObject, 'left', vueObject.fabric.left);
            this.$set(vueObject, 'scalePercentageX', vueObject.fabric.scaleX * 100);
            this.$set(vueObject, 'scalePercentageY', vueObject.fabric.scaleY * 100);
        },

        setFabricImageSrc(fabricImage, src) {
            return new Promise((resolve) => {
                fabricImage.setSrc(src, resolve);
            });
        },

        readFileAsync(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = reject;

                reader.readAsDataURL(file);
            })
        },

        getRectangleShapeCommand(width, height) {
            var t = - height / 2;
            var l = - width / 2;
            var b = height / 2;
            var r = width / 2;
            return this.getRectanglePathCommand(t, l, b, r);
        },
        getRoundedRectangleShapeCommand(width, height, cornerRadius) {
            var t = - height / 2;
            var l = - width / 2;
            var b = height / 2;
            var r = width / 2;
            return this.getRoundedRectanglePathCommand(t, l, b, r, cornerRadius);
        },
        getSquareShapeCommand(width, height) {
            var size = Math.min(width, height);
            return this.getRectangleShapeCommand(size, size);
        },
        getRoundedSquareShapeCommand(width, height, cornerRadius) {
            var size = Math.min(width, height);
            return this.getRoundedRectangleShapeCommand(size, size, cornerRadius);
        },
        getCircleShapeCommand(width, height) {
            var size = Math.min(width, height);
            return this.getEllipseShapeCommand(size, size);
        },
        getEllipseShapeCommand(width, height) {
            var t = - height / 2;
            var l = - width / 2;
            var b = height / 2;
            var r = width / 2;
            return this.getEllipsePathCommand(t, l, b, r);
        },
        getRegularOctagonShapeCommand(width, height) {
            var size = Math.min(width, height);
            return this.getOctagonShapeCommand(size, size);
        },
        getOctagonShapeCommand(width, height) {
            var t = - height / 2;
            var l = - width / 2;
            var b = height / 2;
            var r = width / 2;
            return this.getOctagonPathCommand(t, l, b, r);
        },
        getRegularHexagonShapeCommand(width, height) {
            var size = Math.min(width, height);
            var t = - height / 2;
            var l = - width / 2;
            var b = height / 2;
            var r = width / 2;
            if (width >= height) {
                var h = width * Math.cos(Math.PI / 6);
                if (height > h) {
                    t = - h / 2;
                    b = h / 2;
                } else {
                    l = - size / Math.cos(Math.PI / 6) / 2;
                    r = size / Math.cos(Math.PI / 6) / 2;
                }
                return this.getLandscapeHexagonPathCommand(t, l, b, r);
            } else {
                var w = height * Math.cos(Math.PI / 6);
                if (width > w) {
                    l = - w / 2;
                    r = w / 2;
                } else {
                    t = - size / Math.cos(Math.PI / 6) / 2;
                    b = size / Math.cos(Math.PI / 6) / 2;
                }
                return this.getPortraitHexagonPathCommand(t, l, b, r);
            }
        },
        getHexagonShapeCommand(width, height) {
            var t = - height / 2;
            var l = - width / 2;
            var b = height / 2;
            var r = width / 2;
            if (width >= height) {
                var h = width * Math.cos(Math.PI / 6);
                if (height > h) {
                    t = - h / 2;
                    b = h / 2;
                }
                return this.getLandscapeHexagonPathCommand(t, l, b, r);
            } else {
                var w = height * Math.cos(Math.PI / 6);
                if (width > w) {
                    l = - w / 2;
                    r = w / 2;
                }
                return this.getPortraitHexagonPathCommand(t, l, b, r);
            }
        },

        getRectanglePathCommand(t, l, b, r) {
            return [
                ['M', l, t],
                ['L', r, t],
                ['L', r, b],
                ['L', l, b],
                ['z'],
            ];
        },
        getRoundedRectanglePathCommand(t, l, b, r, a) {
            a = Math.min((r - l) / 2, (b - t) / 2, a);
            if (a === 0) return this.getRectanglePathCommand(t, l, b, r);
            var o = a * 0.55228474983;
            return [
                ['M', l, t + a],
                //['A', a, a, 0, 0, 1, l + a, t],
                ['C', l, t + a - o, l + a - o, t, l + a, t],
                ['L', r - a, t],
                //['A', a, a, 0, 0, 1, r, t + a],
                ['C', r - a + o, t, r, t + a - o, r, t + a],
                ['L', r, b - a],
                //['A', a, a, 0, 0, 1, r - a, b],
                ['C', r, b - a + o, r - a + o, b, r - a, b],
                ['L', l + a, b],
                //['A', a, a, 0, 0, 1, l, b - a],
                ['C', l + a - o, b, l, b - a + o, l, b - a],
                ['z'],
            ];
        },
        getRoundedRectanglePathCommandAntiClockwise(t, l, b, r, a) {
            a = Math.min((r - l) / 2, (b - t) / 2, a);
            var o = a * 0.55228474983;
            return [
                ['M', r, t + a],
                //['A', a, a, 0, 0, 0, r - a, t],
                ['C', r, t + a - o, r - a + o, t, r - a, t],
                ['L', l + a, t],
                //['A', a, a, 0, 0, 0, l, t + a],
                ['C', l + a - o, t, l, t + a - o, l, t + a],
                ['L', l, b - a],
                //['A', a, a, 0, 0, 0, l + a, b],
                ['C', l, b - a + o, l + a - o, b, l + a, b],
                ['L', r - a, b],
                //['A', a, a, 0, 0, 0, r, b - a],
                ['C', r - a + o, b, r, b - a + o, r, b - a],
                ['z'],
            ];
        },
        getEllipsePathCommand(t, l, b, r) {
            var rx = (r - l) / 2;
            var ry = (b - t) / 2;
            var ox = rx * 0.55228474983;
            var oy = ry * 0.55228474983;
            return [
                ['M', 0, t],
                //['A', rx, ry, 0, 0, 1, 0, b],
                ['C', 0 + ox, t, r, 0 - oy, r, 0],
                ['C', r, 0 + oy, 0 + ox, b, 0, b],
                //['A', rx, ry, 0, 0, 1, 0, t],
                ['C', 0 - ox, b, l, 0 + oy, l, 0],
                ['C', l, 0 - oy, 0 - ox, t, 0, t],
            ];
        },
        getOctagonPathCommand(t, l, b, r) {
            var a;
            if (r - l >= b - t) {
                // Landscape
                var h = ((b - t) / 2) * Math.tan(Math.PI / 8);
                a = ((b - t) / 2) - h;
            } else {
                // Portrait
                var w = ((r - l) / 2) * Math.tan(Math.PI / 8);
                a = ((r - l) / 2) - w;
            }
            return [
                ['M', l, t + a],
                ['L', l + a, t],
                ['L', r - a, t],
                ['L', r, t + a],
                ['L', r, b - a],
                ['L', r - a, b],
                ['L', l + a, b],
                ['L', l, b - a],
                ['z'],
            ];
        },
        getLandscapeHexagonPathCommand(t, l, b, r) {
            var a = ((b - t) / 2) * Math.tan(Math.PI / 6);
            if (a > (r - l) / 2) {
                a = (r - l) / 2;
                var h = ((r - l) / 2) * Math.tan(Math.PI / 3);
                var m = (t + b) / 2;
                t = m - h;
                b = m + h;
            }
            return [
                ['M', l, 0],
                ['L', l + a, t],
                ['L', r - a, t],
                ['L', r, 0],
                ['L', r - a, b],
                ['L', l + a, b],
                ['z'],
            ];
        },
        getPortraitHexagonPathCommand(t, l, b, r) {
            var a = ((r - l) / 2) * Math.tan(Math.PI / 6);
            if (a > (b - t) / 2) {
                a = (b - t) / 2;
                var w = ((b - t) / 2) * Math.tan(Math.PI / 3);
                var m = (l + r) / 2;
                l = m - w;
                r = m + w;
            }
            return [
                ['M', 0, t],
                ['L', r, t + a],
                ['L', r, b - a],
                ['L', 0, b],
                ['L', l, b - a],
                ['L', l, t + a],
                ['z'],
            ];
        },

        toPathString(commands) {
            return commands.map(item => item.join(' ')).join(' ');
        },
        toFabricPath(commands) {
            return new fabric.Path(this.toPathString(commands), { strokeWidth: 0 });
        },

        calculateAlignFabricObjectTop(fabricObject) {
            var bounding = fabricObject.getBoundingRect();
            var height = bounding.height;
            var top = 0;

            if (fabricObject.originY === 'center') {
                top = top + (height / 2);
            } else if (fabricObject.originY === 'bottom') {
                top = top + (height);
            }

            return {
                top: top,
            }
        },

        calculateAlignFabricObjectMiddle(fabricObject) {
            var bounding = fabricObject.getBoundingRect();
            var height = bounding.height;
            var top = (this.canvas.height - height) / 2;

            if (fabricObject.originY === 'center') {
                top = top + (height / 2);
            } else if (fabricObject.originY === 'bottom') {
                top = top + (height);
            }

            return {
                top: top,
            }
        },

        calculateAlignFabricObjectBottom(fabricObject) {
            var bounding = fabricObject.getBoundingRect();
            var height = bounding.height;
            var top = this.canvas.height - height;

            if (fabricObject.originY === 'center') {
                top = top + (height / 2);
            } else if (fabricObject.originY === 'bottom') {
                top = top + (height);
            }

            return {
                top: top,
            }
        },

        calculateAlignFabricObjectLeft(fabricObject) {
            var bounding = fabricObject.getBoundingRect();
            var width = bounding.width;
            var left = 0;

            if (fabricObject.originX === 'center') {
                left = left + (width / 2);
            } else if (fabricObject.originX === 'right') {
                left = left + (width);
            }

            return {
                left: left,
            }
        },

        calculateAlignFabricObjectCentre(fabricObject) {
            var bounding = fabricObject.getBoundingRect();
            var width = bounding.width;
            var left = (this.canvas.width - width) / 2;

            if (fabricObject.originX === 'center') {
                left = left + (width / 2);
            } else if (fabricObject.originX === 'right') {
                left = left + (width);
            }

            return {
                left: left,
            }
        },

        calculateAlignFabricObjectRight(fabricObject) {
            var bounding = fabricObject.getBoundingRect();
            var width = bounding.width;
            var left = this.canvas.width - width;

            if (fabricObject.originX === 'center') {
                left = left + (width / 2);
            } else if (fabricObject.originX === 'right') {
                left = left + (width);
            }

            return {
                left: left,
            }
        },

        calculateFitFabricObjectInsideSize(fabricObject, size) {
            var width = fabricObject.width;
            var height = fabricObject.height;

            var scaleX = size.width / width;
            var scaleY = size.height / height;

            if (scaleX < scaleY) {
                scaleY = scaleX;
            } else {
                scaleX = scaleY;
            }

            return {
                scaleX: scaleX,
                scaleY: scaleY,
            }
        },

        calculateFillFabricObjectInsideSize(fabricObject, size) {
            var width = fabricObject.width;
            var height = fabricObject.height;

            var scaleX = size.width / width;
            var scaleY = size.height / height;

            if (scaleX > scaleY) {
                scaleY = scaleX;
            } else {
                scaleX = scaleY;
            }

            return {
                scaleX: scaleX,
                scaleY: scaleY,
            }
        },

        calculateFitFabricObjectInside(fabricObject) {
            var width = fabricObject.width;
            var height = fabricObject.height;

            var scaleX = 1;
            var scaleY = 1;

            if (this.canvas.width < width || this.canvas.height < height) {
                scaleX = this.canvas.width / width;
                scaleY = this.canvas.height / height;
            }

            if (scaleX < scaleY) {
                scaleY = scaleX;
            } else {
                scaleX = scaleY;
            }

            var top = (this.canvas.height - (height * scaleY)) / 2;
            var left = (this.canvas.width - (width * scaleX)) / 2;

            if (fabricObject.originX === 'center') {
                left = left + (width * scaleX / 2);
            } else if (fabricObject.originX === 'right') {
                left = left + (width * scaleX);
            }

            if (fabricObject.originY === 'center') {
                top = top + (height * scaleY / 2);
            } else if (fabricObject.originY === 'bottom') {
                top = top + (height * scaleY);
            }

            return {
                angle: 0,
                scaleX: scaleX,
                scaleY: scaleY,
                top: top,
                left: left,
            }
        },

        calculateFillFabricObjectInside(fabricObject) {
            var width = fabricObject.width;
            var height = fabricObject.height;

            var scaleX = this.canvas.width / width;
            var scaleY = this.canvas.height / height;

            if (scaleX > scaleY) {
                scaleY = scaleX;
            } else {
                scaleX = scaleY;
            }

            var top = (this.canvas.height - (height * scaleY)) / 2;
            var left = (this.canvas.width - (width * scaleX)) / 2;

            if (fabricObject.originX === 'center') {
                left = left + (width * scaleX / 2);
            } else if (fabricObject.originX === 'right') {
                left = left + (width * scaleX);
            }

            if (fabricObject.originY === 'center') {
                top = top + (height * scaleY / 2);
            } else if (fabricObject.originY === 'bottom') {
                top = top + (height * scaleY);
            }

            return {
                angle: 0,
                scaleX: scaleX,
                scaleY: scaleY,
                top: top,
                left: left,
            }
        },

        calculateFillFabricObjectX(fabricObject) {
            var width = fabricObject.width;

            var scaleX = this.canvas.width / width;

            var left = 0;

            if (fabricObject.originX === 'center') {
                left = left + (width * scaleX / 2);
            } else if (fabricObject.originX === 'right') {
                left = left + (width * scaleX);
            }

            return {
                angle: 0,
                scaleX: scaleX,
                scaleY: scaleX,
                left: left,
            }
        },

        calculateFillFabricObjectY(fabricObject) {
            var height = fabricObject.height;

            var scaleY = this.canvas.height / height;

            var top = 0;

            if (fabricObject.originY === 'center') {
                top = top + (height * scaleY / 2);
            } else if (fabricObject.originY === 'bottom') {
                top = top + (height * scaleY);
            }

            return {
                angle: 0,
                scaleX: scaleY,
                scaleY: scaleY,
                top: top,
            }
        },

        calculateRotateFabricObjectUpright() {
            return { angle: 0 };
        },

        cloneLabel(vueObject) {
            var object = {
                objectId: ++vueObject.vue.lastObjectId,
                type: vueObject.type,
                kind: vueObject.kind,
                text: vueObject.text,
                fontSize: vueObject.fontSize,
                fontWeight: vueObject.fontWeight,
                underline: vueObject.underline,
                fontStyle: vueObject.fontStyle,
                fontFamily: vueObject.fontFamily,
                fill: vueObject.fill.hex8 ? vueObject.fill.hex8 : vueObject.fill,
                textAlign: vueObject.textAlign,
                offset: vueObject.offset,
                cornerRadius: vueObject.cornerRadius,
                backgroundColor: vueObject.backgroundColor.hex8 ? vueObject.backgroundColor.hex8 : vueObject.backgroundColor,
                data: {
                    text: vueObject.data.text,
                    fill: vueObject.data.fill,
                    backgroundColor: vueObject.data.backgroundColor,
                },
                vue: vueObject.vue,
            };

            vueObject.vue.model.objects.push(object);

            var fGroup = new fabric.Group([], {});
            fGroup.set('originX', object.textAlign === 'center' || object.textAlign === 'right' ? object.textAlign : 'left');
            fGroup.set('originY', 'center');
            fGroup.set('objectId', object.objectId);
            fGroup.set('kind', object.kind);
            fGroup.set('cornerRadius', object.cornerRadius);
            fGroup.set('offset', object.offset);
            fGroup.set('data', {
                text: object.data.text,
                fill: object.data.fill,
                backgroundColor: object.data.backgroundColor,
            });

            var fText = new fabric.Text(object.text, {
                text: object.text,
                fontSize: object.fontSize,
                fontWeight: object.fontWeight,
                underline: object.underline,
                fontStyle: object.fontStyle,
                fontFamily: object.fontFamily,
                fill: object.fill,
                textAlign: object.textAlign,
                originX: 'center',
                originY: 'center',
            });

            var fRect = new fabric.Rect({
                fill: object.backgroundColor,
                width: fText.width + (object.offset * 2),
                height: fText.height + (object.offset * 2),
                originX: 'center',
                originY: 'center',
                strokeWidth: 0,
            });

            fGroup.addWithUpdate(fRect);
            fGroup.addWithUpdate(fText);
            fGroup.clipPath = this.toFabricPath(this.getRoundedRectangleShapeCommand(fGroup.width, fGroup.height, object.cornerRadius));

            this.setupFabricGroupLabel(fGroup, object);
            this.canvas.add(fGroup);

            return object;
        },
        async cloneImage(vueObject) {
            var object = {
                objectId: ++vueObject.vue.lastObjectId,
                type: vueObject.type,
                src: vueObject.src,
                isDefaultImage: vueObject.isDefaultImage,
                cutter: vueObject.cutter,
                data: {
                    src: vueObject.data.src,
                },
                vue: vueObject.vue,
            };

            vueObject.vue.model.objects.push(object);
            await this.$nextTick();

            var fImage = new fabric.Image(document.createElement('img'), {});
            fImage.set('originX', 'center');
            fImage.set('originY', 'center');
            fImage.set('objectId', object.objectId);
            await this.setFabricImageSrc(fImage, object.src);
            fImage.set('isDefaultImage', object.isDefaultImage);
            fImage.set('cutter', object.cutter);
            fImage.set('data', {
                src: object.data.src,
            });

            this.setupFabricImage(fImage, object);
            this.canvas.add(fImage);

            return object;
        },
        cloneShape(vueObject) {
            var object = {
                objectId: ++vueObject.vue.lastObjectId,
                type: vueObject.type,
                kind: vueObject.kind,
                shape: vueObject.shape,
                width: vueObject.width,
                height: vueObject.height,
                fill: {
                    type: vueObject.fill.type,
                    gradientUnits: vueObject.fill.gradientUnits,
                    coords: {
                        x1: vueObject.fill.coords.x1,
                        y1: vueObject.fill.coords.y1,
                        x2: vueObject.fill.coords.x2,
                        y2: vueObject.fill.coords.y2,
                        r1: vueObject.fill.coords.r1,
                        r2: vueObject.fill.coords.r2,
                    },
                    colorStops: vueObject.fill.colorStops.map(item => { return { offset: item.offset, color: item.color.hex8 ? item.color.hex8 : item.color } }),
                },
                strokeWidth: vueObject.strokeWidth,
                strokeLineJoin: vueObject.strokeLineJoin,
                stroke: {
                    type: vueObject.stroke.type,
                    gradientUnits: vueObject.stroke.gradientUnits,
                    coords: {
                        x1: vueObject.stroke.coords.x1,
                        y1: vueObject.stroke.coords.y1,
                        x2: vueObject.stroke.coords.x2,
                        y2: vueObject.stroke.coords.y2,
                        r1: vueObject.stroke.coords.r1,
                        r2: vueObject.stroke.coords.r2,
                    },
                    colorStops: vueObject.stroke.colorStops.map(item => { return { offset: item.offset, color: item.color.hex8 ? item.color.hex8 : item.color } }),
                },
                data: {},
                vue: vueObject.vue,
            };

            vueObject.vue.model.objects.push(object);

            var shape = this.shapes.find(item => item.id === object.shape);

            var fabricPath = this.toFabricPath(shape.getShapeCommand(this, object.width, object.height));
            fabricPath.set('originX', 'center');
            fabricPath.set('originY', 'center');
            fabricPath.set('objectId', object.objectId);
            fabricPath.set('kind', object.kind);
            fabricPath.set('shape', object.shape);
            fabricPath.set('width', object.width);
            fabricPath.set('height', object.height);
            fabricPath.set('fill', new fabric.Gradient({
                type: object.fill.type,
                gradientUnits: object.fill.gradientUnits,
                coords: {
                    x1: object.fill.coords.x1,
                    y1: object.fill.coords.y1,
                    x2: object.fill.coords.x2,
                    y2: object.fill.coords.y2,
                    r1: object.fill.coords.r1,
                    r2: object.fill.coords.r2,
                },
                colorStops: object.fill.colorStops.map(item => { return { offset: item.offset, color: item.color } }),
            }));
            // Internal bug of fabric cause the path not rendered if stroke width is 0 here, set it later
            fabricPath.set('strokeWidth', 20);
            fabricPath.set('strokeLineJoin', object.strokeLineJoin);
            fabricPath.set('stroke', new fabric.Gradient({
                type: object.stroke.type,
                gradientUnits: object.stroke.gradientUnits,
                coords: {
                    x1: object.stroke.coords.x1,
                    y1: object.stroke.coords.y1,
                    x2: object.stroke.coords.x2,
                    y2: object.stroke.coords.y2,
                    r1: object.stroke.coords.r1,
                    r2: object.stroke.coords.r2,
                },
                colorStops: object.stroke.colorStops.map(item => { return { offset: item.offset, color: item.color } }),
            }));
            fabricPath.set('data', {});

            this.setupFabricShape(fabricPath, object);
            this.canvas.add(fabricPath);

            // Internal bug of fabric, set stroke width later
            fabricPath.set('strokeWidth', object.strokeWidth);

            return object;
        },

        calculateBadge(vueModel) {
            var size = this.badgeSizes.find(item => item.id === vueModel.badge.size);
            var pixelPerMm = size.pixelPerMm;
            var u = { width: size.width, height: size.height, cornerRadius: size.cornerRadius };

            if (vueModel.badge.orientation === 'portrait') {
                u = { width: u.height, height: u.width, cornerRadius: u.cornerRadius };
            }
            if (vueModel.badge.lanyardHole === 'top') {
                u.hole = { t: 3, l: (u.width - 14.5) / 2, b: 6, r: (u.width + 14.5) / 2, a: 1.5 }
            } else if (vueModel.badge.lanyardHole === 'left') {
                u.hole = { t: (u.height - 14.5) / 2, l: 3, b: (u.height + 14.5) / 2, r: 6, a: 1.5 }
            } else if (vueModel.badge.lanyardHole === 'right') {
                u.hole = { t: (u.height - 14.5) / 2, l: u.width - 6, b: (u.height + 14.5) / 2, r: u.width - 3, a: 1.5 }
            } else if (vueModel.badge.lanyardHole === 'bottom') {
                u.hole = { t: u.height - 6, l: (u.width - 14.5) / 2, b: u.height - 3, r: (u.width + 14.5) / 2, a: 1.5 }
            } else {
                u.hole = null;
            }

            var c = JSON.parse(JSON.stringify(u));
            var o = null;
            if (vueModel.badge.edge === 'inset') {
                c.width = c.width - 2;
                c.height = c.height - 2;
                c.cornerRadius = Math.max(c.cornerRadius - 1, 0);
                if (c.hole) {
                    c.hole = { t: c.hole.t - 2, l: c.hole.l - 2, b: c.hole.b, r: c.hole.r, a: c.hole.a + 1 };
                }
            } else if (vueModel.badge.edge === 'over') {
                c.width = c.width + 2;
                c.height = c.height + 2;
                c.cornerRadius = 0;
                c.hole = null;

                o = {
                    width: c.width,
                    height: c.height,
                    hole: u.hole ? {
                        t: u.hole.t + 1,
                        l: u.hole.l + 1,
                        b: u.hole.b + 1,
                        r: u.hole.r + 1,
                        a: u.hole.a,
                    } : null,
                    t: 1,
                    l: 1,
                    b: u.height + 1,
                    r: u.width + 1,
                    a: u.cornerRadius,
                };
            };

            this.scale(u, pixelPerMm);
            this.scale(c, pixelPerMm);
            if (o) this.scale(o, pixelPerMm);

            u.clipPathCommand = this.getRoundedRectanglePathCommand(0, 0, u.height, u.width, u.cornerRadius);
            if (u.hole) u.clipPathCommand = u.clipPathCommand.concat(this.getRoundedRectanglePathCommandAntiClockwise(u.hole.t, u.hole.l, u.hole.b, u.hole.r, u.hole.a));
            u.clipPath = this.toPathString(u.clipPathCommand);
            u.fill = '#ffffffff';

            c.clipPathCommand = this.getRoundedRectanglePathCommand(0, 0, c.height, c.width, c.cornerRadius);
            if (c.hole) c.clipPathCommand = c.clipPathCommand.concat(this.getRoundedRectanglePathCommandAntiClockwise(c.hole.t, c.hole.l, c.hole.b, c.hole.r, c.hole.a));
            c.clipPath = this.toPathString(c.clipPathCommand);

            if (o) {
                o.clipPathCommand = this.getRectanglePathCommand(0, 0, o.height, o.width);
                o.clipPathCommand = o.clipPathCommand.concat(this.getRoundedRectanglePathCommandAntiClockwise(o.t, o.l, o.b, o.r, o.a));
                if (o.hole) o.clipPathCommand = o.clipPathCommand.concat(this.getRoundedRectanglePathCommand(o.hole.t, o.hole.l, o.hole.b, o.hole.r, o.hole.a));
                o.clipPath = this.toPathString(o.clipPathCommand);
                o.fill = '#6C757DC0'; // semi-transparent bg-secondary
            };

            return { u: u, c: c, o: o };
        },
        scale(size, factor) {
            for (const prop in size) {
                if (typeof size[prop] === 'number') size[prop] = size[prop] * factor;
                else this.scale(size[prop], factor);
            }
        },
        applyBadge(vueModel) {
            var { o, c, u } = this.calculateBadge(vueModel);

            u = u ? { width: u.width, height: u.height, fill: u.fill, clipPath: u.clipPath } : null;
            o = o ? { width: o.width, height: o.height, fill: o.fill, clipPath: o.clipPath } : null;

            vueModel.underlayPath = u ? { ...u } : null;

            vueModel.width = c.width;
            vueModel.height = c.height;
            this.canvas.clipPath = this.toFabricPath(c.clipPathCommand);

            vueModel.overlayPath = o ? { ...o } : null;

            this.canvas.set('underlayPath', u);
            this.canvas.set('badge', { size: vueModel.badge.size, orientation: vueModel.badge.orientation, lanyardHole: vueModel.badge.lanyardHole, edge: vueModel.badge.edge });
            this.canvas.set('overlayPath', o);

            this.canvas.requestRenderAll();
        },

        getDataByPath(data, path) {
            var parts = path.split('.');

            var current = data;
            if (current == null || current == undefined) return current;
            for (var part of parts) {
                if (current[part] === undefined) return undefined;
                if (current[part] === null) return null;
                current = current[part];
            }

            return current;
        },
        filterByType(fields, type) {
            return fields.filter(item => item.type === type);
        },
        async applyData(data, vueModel) {
            await this.applyDataObject(data, vueModel);
            if (vueModel.backgroundImage) await this.applyDataObject(data, vueModel.backgroundImage);

            for (var vueObject of vueModel.objects) {
                await this.applyDataObject(data, vueObject);
            }
        },
        async applyDataObject(data, vueObject) {
            if (vueObject.data) {
                for (var property in vueObject.data) {
                    var field = vueObject.data[property];
                    if (field) {
                        var value = this.getDataByPath(data, field.path);
                        vueObject[property] = value;
                        if (field.type === 'string') {
                            this.applyVuePropertyString(vueObject, property, true);
                        } else if (field.type === 'boolean') {
                            this.applyVuePropertyBoolean(vueObject, property, true);
                        } else if (field.type === 'number') {
                            this.applyVuePropertyNumber(vueObject, property, true);
                        } else if (field.type === 'colour') {
                            this.applyVuePropertyColour(vueObject, property, true);
                        } else if (field.type === 'imageBase64') {
                            await this.applyVuePropertyImageBase64(vueObject, property, true);
                        }

                        if (vueObject.fabric && vueObject.fabric.setCoords) vueObject.fabric.setCoords();
                    }
                }
            }
        },
        applyVuePropertyString(vueObject, property) {
            var fabricObject = vueObject.fabric;

            var mapping = this.vueFabricPropertyMapping.find(m =>
                (m.type === null || m.type === vueObject.type) &&
                (m.kind === null || m.kind === vueObject.kind) &&
                (m.property === null || m.property === property)
            );
            var value = vueObject[property];
            if (value === null || value === undefined) {
                if (mapping && mapping.fallback) value = mapping.fallback;
            }

            if (mapping && mapping.action) {
                mapping.action(this, vueObject, fabricObject);
            } else {
                if (value === null || value === undefined) fabricObject.set(property, '');
                else fabricObject.set(property, value);
            }
            if (mapping && mapping.updateSize && vueObject.updateSize) vueObject.updateSize(this);

            fabricObject.set('dirty', true);

            this.canvas.requestRenderAll();
        },
        applyVuePropertyBoolean(vueObject, property) {
            var fabricObject = vueObject.fabric;

            var mapping = this.vueFabricPropertyMapping.find(m =>
                (m.type === null || m.type === vueObject.type) &&
                (m.kind === null || m.kind === vueObject.kind) &&
                (m.property === null || m.property === property)
            );
            var value = vueObject[property];
            if (value === null || value === undefined) {
                if (mapping && mapping.fallback) value = mapping.fallback;
            }

            if (mapping && mapping.action) {
                mapping.action(this, vueObject, fabricObject);
            } else {
                if (value === null || value === undefined) fabricObject.set(property, false);
                else fabricObject.set(property, value);
            }
            if (mapping && mapping.updateSize && vueObject.updateSize) vueObject.updateSize(this);

            fabricObject.set('dirty', true);

            this.canvas.requestRenderAll();
        },
        applyVuePropertyNumber(vueObject, property) {
            var fabricObject = vueObject.fabric;

            var mapping = this.vueFabricPropertyMapping.find(m =>
                (m.type === null || m.type === vueObject.type) &&
                (m.kind === null || m.kind === vueObject.kind) &&
                (m.property === null || m.property === property)
            );

            var value = vueObject[property];
            if (value === null || value === undefined || typeof value !== 'number') {
                if (mapping && mapping.fallback) value = mapping.fallback;
            }

            if (mapping && mapping.action) {
                mapping.action(this, vueObject, fabricObject);
            } else {
                if (value === null || value === undefined) fabricObject.set(property, 0);
                else fabricObject.set(property, value);
            }
            if (mapping && mapping.updateSize && vueObject.updateSize) vueObject.updateSize(this);

            fabricObject.set('dirty', true);

            this.canvas.requestRenderAll();
        },
        applyVuePropertyColour(vueObject, property) {
            var fabricObject = vueObject.fabric;

            var mapping = this.vueFabricPropertyMapping.find(m =>
                (m.type === null || m.type === vueObject.type) &&
                (m.kind === null || m.kind === vueObject.kind) &&
                (m.property === null || m.property === property)
            );
            var value = vueObject[property];
            if (value === null || value === undefined) {
                if (mapping && mapping.fallback) value = mapping.fallback;
            }
            if (value.hex8) value = value.hex8;

            if (mapping && mapping.action) {
                mapping.action(this, vueObject, fabricObject);
            } else {
                if (value === null || value === undefined) fabricObject.set(property, '#00000000');
                else fabricObject.set(property, value);
            }
            if (mapping && mapping.updateSize && vueObject.updateSize) vueObject.updateSize(this);

            fabricObject.set('dirty', true);

            this.canvas.requestRenderAll();
        },
        applyVuePropertyGradient(vueObject, property) {
            var fabricObject = vueObject.fabric;

            var mapping = this.vueFabricPropertyMapping.find(m =>
                (m.type === null || m.type === vueObject.type) &&
                (m.kind === null || m.kind === vueObject.kind) &&
                (m.property === null || m.property === property)
            );
            var value = vueObject[property];
            if (value === null || value === undefined) {
                if (mapping && mapping.fallback) value = mapping.fallback;
            }

            if (mapping && mapping.action) {
                mapping.action(this, vueObject, fabricObject);
            } else {
                if (value === null || value === undefined) {
                    fabricObject[property] = new fabric.Gradient({
                        type: 'linear',
                        gradientUnits: 'percentage',
                        coords: { x1: 0, y1: 0, x2: 1, y2: 1, r1: 0, r2: Math.cos(Math.PI / 8), },
                        colorStops: [
                            { offset: 0, color: '#ffffffff' },
                            { offset: 1, color: '#ffffffff' },
                        ],
                    });
                } else {
                    fabricObject[property] = new fabric.Gradient({
                        type: value.type,
                        gradientUnits: value.gradientUnits,
                        coords: value.coords,
                        colorStops: value.colorStops.map(item => { return { color: (item.color.hex8 ? item.color.hex8 : item.color), offset: item.offset } }),
                    });
                }
            }
            if (mapping && mapping.updateSize && vueObject.updateSize) vueObject.updateSize(this);

            fabricObject.set('dirty', true);

            this.canvas.requestRenderAll();
        },
        async applyVuePropertyImageBase64(vueObject, property, isApplyingData) {
            var fabricObject = vueObject.fabric;
            if (!fabricObject) {
                // If vueObject is background image, its fabric is undefined befor the first assignment
                return;
            }

            var mapping = this.vueFabricPropertyMapping.find(m =>
                (m.type === null || m.type === vueObject.type) &&
                (m.kind === null || m.kind === vueObject.kind) &&
                (m.property === null || m.property === property)
            );
            var value = vueObject[property];
            if (value === null || value === undefined) {
                if (mapping && mapping.fallback) value = mapping.fallback;
            }

            // When replacing image object, fit the new image into the size of the old image
            if (isApplyingData && !vueObject.fitSize) vueObject.fitSize = { width: fabricObject.width * fabricObject.scaleX, height: fabricObject.height * fabricObject.scaleY };

            if (mapping && mapping.action) {
                await mapping.action(this, vueObject, fabricObject);
            } else {
                if (value === null || value === undefined) await this.setFabricImageSrc(fabricObject, '');
                else await this.setFabricImageSrc(fabricObject, value);
            }

            if (fabricObject.width && fabricObject.height) {
                if (vueObject.isBackgroundImage) {
                    if (isApplyingData) {
                        // Fill the new image into the size of the old image
                        var calculations = this.calculateFillFabricObjectInsideSize(fabricObject, vueObject.fitSize);
                        this.setVueObjectPosition(vueObject, calculations);
                    } else {
                        // User setup a new background image, fill the new image into the canvas
                        var calculations = this.calculateFillFabricObjectInside(fabricObject);
                        this.setVueObjectPosition(vueObject, calculations);

                        vueObject.fitSize = undefined;
                    }
                } else {
                    if (isApplyingData) {
                        // Fit the new image into the size of the old image
                        var calculations = this.calculateFitFabricObjectInsideSize(fabricObject, vueObject.fitSize);
                        this.setVueObjectPosition(vueObject, calculations);
                    } else {
                        // User replacing image, fit the new image into the canvas
                        var calculations = this.calculateFitFabricObjectInside(fabricObject);
                        this.setVueObjectPosition(vueObject, calculations);

                        vueObject.fitSize = undefined;
                    }
                }
            }
            if (mapping && mapping.updateSize && vueObject.updateSize) vueObject.updateSize(this);

            fabricObject.set('dirty', true);

            this.canvas.requestRenderAll();
        },
        applyVuePropertyData(vueObject, data, property) {
            var fabricObject = vueObject.fabric;

            if (fabricObject.data && vueObject.data) fabricObject.data[property] = vueObject.data[property];

            if (data && vueObject.data && vueObject.data[property] && vueObject.data[property].path) {
                // data exists, apply the value
                vueObject[property] = this.getDataByPath(data, vueObject.data[property].path);
            } else if (vueObject.data && vueObject.data[property] && vueObject.data[property].description && vueObject.data[property].type === 'string') {
                // data not exists, apply the description if type is string
                vueObject[property] = '<<' + vueObject.data[property].description + '>>';
            }
        },
    },

    data() {
        return {
            vueFabricPropertyMapping: [
                {
                    // type/kind/property: null means not specifying criteria
                    // type/kind/property: undefined means the vueObject must not defined property "type", this happen in fabric canvas and background image
                    type: null, kind: null, property: 'scalePercentageX', updateSize: false, fallback: 100,
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.set('scaleX', vueObject.scalePercentageX / 100);
                    },
                },
                {
                    type: null, kind: null, property: 'scalePercentageY', updateSize: false, fallback: 100,
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.set('scaleY', vueObject.scalePercentageY / 100);
                    },
                },

                {
                    type: undefined, kind: undefined, property: 'background', updateSize: false, fallback: '#ffffff00',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        var value = vueObject.background.hex8 ? vueObject.background.hex8 : vueObject.background;
                        fabricObject.setBackgroundColor(value);
                    },
                },

                {
                    type: 'group', kind: 'label', property: 'text', updateSize: true, fallback: null, 
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        if (vueObject.text) {
                            fabricObject.item(1).set('text', vueObject.text);
                            fabricObject.set('visible', true);
                        } else {
                            fabricObject.set('visible', false);
                            fabricObject.item(1).set('text', '');
                        }
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'textAlign', updateSize: false, fallback: 'left',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('textAlign', vueObject.textAlign);
                        fabricObject.set('originX', vueObject.textAlign === 'center' || vueObject.textAlign === 'right' ? vueObject.textAlign : 'left');
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'fontFamily', updateSize: true, fallback: 'sans-serif',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('fontFamily', vueObject.fontFamily);
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'fontSize', updateSize: true, fallback: 10,
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('fontSize', vueObject.fontSize);
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'fontWeight', updateSize: true, fallback: 'normal',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('fontWeight', vueObject.fontWeight);
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'underline', updateSize: false, fallback: false,
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('underline', vueObject.underline);
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'fontStyle', updateSize: false, fallback: 'italic',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('fontStyle', vueObject.fontStyle);
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'fill', updateSize: false, fallback: '#ffffffff',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(1).set('fill', vueObject.fill.hex8 ? vueObject.fill.hex8 : vueObject.fill);
                    },
                },
                {
                    type: 'group', kind: 'label', property: 'offset', updateSize: true, fallback: 0,
                    action: null,
                },
                {
                    type: 'group', kind: 'label', property: 'cornerRadius', updateSize: true, fallback: 0,
                    action: null,
                },
                {
                    type: 'group', kind: 'label', property: 'backgroundColor', updateSize: false, fallback: '#00000077',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        fabricObject.item(0).set('fill', vueObject.backgroundColor.hex8 ? vueObject.backgroundColor.hex8 : vueObject.backgroundColor);
                    },
                },

                {
                    type: 'image', kind: undefined, property: 'src', updateSize: true, fallback: null,
                    action: null,
                },
                {
                    type: 'image', kind: undefined, property: 'cutter', updateSize: false, fallback: 'none',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        var shape = vueComponent.shapes.find(item => item.id === vueObject.cutter);
                        fabricObject.clipPath = new fabric.Path(shape.getShapeCommand(vueComponent, fabricObject.width, fabricObject.height), { strokeWidth: 0 });
                        fabricObject.set('cutter', vueObject.cutter);
                    },
                },

                {
                    type: 'path', kind: 'shape', property: 'shape', updateSize: false, fallback: 'none',
                    action: function (vueComponent, vueObject, fabricObject) {
                        // "this" is this mapping object now, use "vueComponent" to "this" of this mixin
                        var shape = vueComponent.shapes.find(item => item.id === vueObject.shape);
                        fabricObject.path = shape.getShapeCommand(vueComponent, fabricObject.width, fabricObject.height);
                        fabricObject.set('shape', vueObject.shape);
                    },
                },
                {
                    type: 'path', kind: 'shape', property: 'width', updateSize: true, fallback: null,
                    action: null,
                },
                {
                    type: 'path', kind: 'shape', property: 'height', updateSize: true, fallback: null,
                    action: null,
                },
                {
                    type: 'path', kind: 'shape', property: 'strokeWidth', updateSize: true, fallback: null,
                    action: null,
                },
            ],
            badgeSizes: [
                { id: 'id1', name: 'ID-1 (Common Cards, CR80)', width: 85.6, height: 54.0, cornerRadius: 3.2, pixelPerMm: 5 },
                { id: 'id2', name: 'ID-2 (Travel Visas)', width: 105, height: 74, cornerRadius: 3.2, pixelPerMm: 5 },
                { id: 'id3', name: 'ID-3 (Passport Booklets)', width: 125, height: 88, cornerRadius: 3.2, pixelPerMm: 5 },
                //{ id: 'id000', name: 'ID-000 (SIM Cards)', width: 25, height: 15, cornerRadius: 1, pixelPerMm: 5 },
            ],
            shapes: [
                {
                    id: 'rectangle', name: 'Rectangle',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRectangleShapeCommand(width, height);
                    },
                },
                {
                    id: '8thRoundedRectangle', name: '1/8 Rounded Rectangle',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedRectangleShapeCommand(width, height, Math.min(width, height) * 0.125);
                    },
                },
                {
                    id: '4thRoundedRectangle', name: '1/4 Rounded Rectangle',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedRectangleShapeCommand(width, height, Math.min(width, height) * 0.25);
                    },
                },
                {
                    id: '3rdRoundedRectangle', name: '1/3 Rounded Rectangle',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedRectangleShapeCommand(width, height, Math.min(width, height) * 0.3333333333);
                    },
                },
                {
                    id: 'capsule', name: 'Capsule',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedRectangleShapeCommand(width, height, Math.min(width, height) * 0.5);
                    },
                },
                {
                    id: 'square', name: 'Square',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getSquareShapeCommand(width, height);
                    },
                },
                {
                    id: '8thRoundedSquare', name: '1/8 Rounded Square',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedSquareShapeCommand(width, height, Math.min(width, height) * 0.125);
                    },
                },
                {
                    id: '4thRoundedSquare', name: '1/4 Rounded Square',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedSquareShapeCommand(width, height, Math.min(width, height) * 0.25);
                    },
                },
                {
                    id: '3rdRoundedSquare', name: '1/3 Rounded Square',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRoundedSquareShapeCommand(width, height, Math.min(width, height) * 0.3333333333);
                    },
                },
                {
                    id: 'circle', name: 'Circle',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getCircleShapeCommand(width, height);
                    },
                },
                {
                    id: 'ellipse', name: 'Ellipse',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getEllipseShapeCommand(width, height);
                    },
                },
                {
                    id: 'regularOctagon', name: 'Regular Octagon',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRegularOctagonShapeCommand(width, height);
                    },
                },
                {
                    id: 'octagon', name: 'Octagon',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getOctagonShapeCommand(width, height);
                    },
                },
                {
                    id: 'regularHexagon', name: 'Regular Hexagon',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getRegularHexagonShapeCommand(width, height);
                    },
                },
                {
                    id: 'hexagon', name: 'Hexagon',
                    getShapeCommand: function (vueComponent, width, height) {
                        // "this" is this shape object now, use "vueComponent" to "this" of this mixin
                        return vueComponent.getHexagonShapeCommand(width, height);
                    },
                },
            ]
        };
    },
}
