import { render, staticRenderFns } from "./notice-board-create-page.vue?vue&type=template&id=6f3a5fb4"
import script from "./notice-board-create-page.vue?vue&type=script&lang=js"
export * from "./notice-board-create-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports