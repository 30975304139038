import { render, staticRenderFns } from "./doors-and-door-groups-add-or-remove-button.vue?vue&type=template&id=3053050a"
import script from "./doors-and-door-groups-add-or-remove-button.vue?vue&type=script&lang=js"
export * from "./doors-and-door-groups-add-or-remove-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Shield.UI.Website/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports