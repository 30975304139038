export default class DataSourceColumn {
    constructor(dataSource) {
        this.dataSource = dataSource;

        this.header = '';
        this.textAlign = 'left';
    }

    getDisplay(data) {
        return null;
    }

    getUrl(data) {
        return null;
    }

    getBadgeVariant(data) {
        return null;
    }

    getBadgeDisplay(data) {
        return null;
    }

    getButtonVariant(data) {
        return null;
    }

    getButtonDisplay(data) {
        return null;
    }

    getButtonUrl(data) {
        return null;
    }

    async handleButtonActionAsync(data) {
        // Do nothing
    }
}