class UserMixin {
    constructor(vueComponent) {
        this.userObject = vueComponent.$auth.user();
        this.isACS = vueComponent.isACS;
        this.workingClientId = this.userObject.userData.workingClientId;
    }

    hasAcsInstallerRead(objectOrClientId) {
        if (this.isACS)
            return this.isAccessible(objectOrClientId);
        else
            return true;
    }

    hasAcsInstallerWrite(objectOrClientId) {
        if (this.isACS)
            return this.isManaging(objectOrClientId);
        else
            return true;
    }

    isAccessible(objectOrClientId) {
        var clientId = null;

        if (Number.isInteger(objectOrClientId))
            clientId = objectOrClientId;
        else if (typeof (objectOrClientId) == 'object' && 'clientId' in objectOrClientId && Number.isInteger(objectOrClientId.clientId))
            clientId = objectOrClientId.clientId;
        else
            return true;

        if (!this.userObject || !this.userObject.userData || !Array.isArray(this.userObject.userData.accessibleClientIds))
            return false;

        return this.userObject.userData.accessibleClientIds.includes(clientId);
    }

    isManaging(objectOrClientId) {
        var clientId = null;

        if (Number.isInteger(objectOrClientId))
            clientId = objectOrClientId;
        else if (typeof (objectOrClientId) == 'object' && 'clientId' in objectOrClientId && Number.isInteger(objectOrClientId.clientId))
            clientId = objectOrClientId.clientId;
        else
            return true;

        if (!this.userObject || !this.userObject.userData || !Array.isArray(this.userObject.userData.managingClientIds))
            return false;

        return this.userObject.userData.managingClientIds.includes(clientId);
    }

    isEngineering(objectOrClientId) {
        var clientId = null;

        if (Number.isInteger(objectOrClientId))
            clientId = objectOrClientId;
        else if (typeof (objectOrClientId) == 'object' && 'clientId' in objectOrClientId && Number.isInteger(objectOrClientId.clientId))
            clientId = objectOrClientId.clientId;
        else
            return true;

        if (!this.userObject || !this.userObject.userData || !Array.isArray(this.userObject.userData.engineeringClientIds))
            return false;

        return this.userObject.userData.engineeringClientIds.includes(clientId);
    }
}

export default {
    data() {
        return {
            userMixin: null,
        };
    },
    created() {
        this.userMixin = new UserMixin(this);
    },
}