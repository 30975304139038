export default {
    data() {
        return {
            simProviders: [],
            simTypes: []
        }
    },
    methods: {
        async loadSimProviders() {
            this.simProviders = [];
            this.simProviders = (await this.$http.get("/sim/sim-providers")).data;
        },
        async loadSimTypes() {
            this.simTypes = [];
            this.simTypes = (await this.$http.get("/sim/sim-types")).data;
        }
    }
}