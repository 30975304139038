<template>
    <div id="app" ref="box">

        <notifications position="top center" width="70%">
            <template slot="body" slot-scope="props">
                <div v-bind:class="'alert ' + props.item.type + ' mt-2 mb-0'">
                    <button type="button" class="close" @click="props.close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 v-if="props.item.title" class="alert-heading"> {{ props.item.title }} </h4>
                    <span v-html="props.item.text" />
                </div>
            </template>
        </notifications>

        <nav v-if="$auth.check()" class="navbar navbar-expand-lg navbar-dark ">



            <div class="navbar-text" v-if="$auth.check() && width >= 977">

                <button class="burger-menu-button burger-menu-button-left btn-sm btn-outline-primary" type="button"
                        @click="toggleSideMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>


                <!--<div class="dropdown ">
                    <button class="btn btn-outline-light btn-sm dropdown-toggle" type="button" id="userDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{  $auth.user().user.displayName }}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdownMenuButton">
                        <a class="dropdown-item usermenu-dropdown-item" href="#" @click.prevent="logout">Logout</a>
                    </div>
                </div>-->

            </div>

            <router-link to="/" class="navbar-brand">
                <!--<span><img src="/images/came-kms-white.png" style="height:2.1rem; margin-bottom: 15px; margin-right: 10px;" /></span>-->

                <span class="product">SimpleKey Web</span>
            </router-link>

            <!--<nav-menu params="route: route" class="collapse navbar-collapse" id="navbarSupportedContent"></nav-menu>-->

            <div class="navbar-text" v-if="$auth.check() && width < 977">

                <button class="burger-menu-button btn-sm btn-outline-primary" type="button"
                        @click="toggleSideMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>

            <universal-search v-if="$auth.check()" />

        </nav>

        <div class="d-flex" id="wrapper">
            <side-menu v-if="$auth.check() && $auth.user()" />

            <div id="page-content-wrapper">
                <div class="page-scroll-wrapper" ref="main">
                    <announcements v-if="$auth.check()" />
                    <transition name="fade">
                        <loading-came v-if="!$auth.ready()" :branding="true" :overlay="true" style="height: 100%"></loading-came>

                        <div v-if="$auth.check()" class="mb-5">
                            <kms-logo class="print-only mr-3 float-right" style="max-width: 300px;" />
                            <transition name="fade">
                                <router-view></router-view>
                            </transition>
                        </div>

                        <router-view v-else>
                            Whoa, this should never be seen!
                        </router-view>
                    </transition>
                </div>

                <footer v-if="$auth.check()" class="footer">
                    <div class="container">
                        <span class="text-muted">
                            <span class="footer-hide-extra-small-screen">
                                Copyright
                            </span>
                            &copy;
                            <span class="footer-hide-extra-small-screen">
                                2023
                            </span>
                            CAME KMS
                            <span class="footer-hide-small-screen"> - All rights reserved</span>
                        </span>
                        <span class="float-right">
                            <feedback-button class="mr-2" />
                            <router-link class="text-white" :to="'/settings/about'">ABOUT</router-link>
                        </span>
                    </div>
                </footer>
            </div>

        </div>
    </div>
</template>

<script>
    require('bootstrap')

    import NavMenu from '@/components/nav-menu'
    import SideMenu from '@/components/side-menu'
    import KmsLogo from 'common/components/common/kms-logo'
    import UniversalSearch from '@/components/universal-search'
    import LoadingCame from 'common/components/loading-came'
    import FeedbackButton from 'common/components/common/feedback-button'
    import Announcements from 'common/components/announcement/announcements'
    import PageTitle from 'common/mixins/page-title';
    //import Cookies from 'Cookies'


    import { mapMutations } from 'vuex'

    export default {
        mixins: [PageTitle],

        components: {
            'nav-menu': NavMenu,
            "kms-logo": KmsLogo,
            'side-menu': SideMenu,
            'universal-search': UniversalSearch,
            'loading-came': LoadingCame,
            'feedback-button': FeedbackButton,
            'announcements': Announcements
        },

        data() {
            return {
                width: null,
                height: null,
                observer: null,
                keys: {}
            }
        },

        methods: {
            logout: function () {
                this.$auth.logout({
                    makeRequest: false,
                    params: {},
                    success: () => {
                        this.$router.push({ to: '/login' });
                        this.$notify({ type: 'alert-success', text: 'Successfully logged out' })
                    },
                    error: (response) => this.$notify({ type: 'alert-danger', title: 'Failed to logout', text: response.data.message }),
                    redirect: '/',
                });
            },

            signalRStart: function () {
            },

            handleWindowResize() {
                const box = this.$refs.box,
                    boxSize = box.getBoundingClientRect();

                this.width = Math.trunc(boxSize.width);
                this.height = Math.trunc(boxSize.height);
            },

            async toggleSideMenu() {
                console.log("toggle side menu")
                document.body.classList.toggle('sb-sidenav-toggled');

                var sideMenuState = !document.body.classList.contains('sb-sidenav-toggled'); 

                await this.$http.post('/user/set-side-nav-state/' + sideMenuState);

                this.$auth.fetch();
            },

            handleUserChange() {
                this.$store.commit('initialise');

                var user = this.$auth.user();

                if (user.user.sideMenuExpanded)
                    document.body.classList.remove('sb-sidenav-toggled');
                else
                    document.body.classList.add('sb-sidenav-toggled');
                    

                if (user && user.userData && user.userData.workingClientId) this.$store.commit('setBrowsingClient', user.userData.workingClientId);
            },
        },

        watch: {
            $route(to, from) {
                if (window.innerWidth < 568)
                    document.body.classList.remove('sb-sidenav-toggled');

                if (window.document.documentMode) {
                    // It's IE
                    window.scrollTo(0, 0);
                }
                else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }
        },

        mounted() {
            const box = this.$refs.box,
                boxSize = box.getBoundingClientRect();

            this.width = Math.trunc(boxSize.width);
            this.height = Math.trunc(boxSize.height);


            window.addEventListener("resize", this.handleWindowResize);

            function handleKeyPress(evt) {
                let { keyCode, type } = evt || Event; // to deal with IE
                let isKeyDown = (type == 'keydown');
                this.keys[keyCode] = isKeyDown;

                if (isKeyDown && this.keys[75] && this.keys[77] && this.keys[83] && this.keys[17] && this.keys[18]) {
                    var app = document.getElementById('app');
                    if (app.classList.contains('easy-on-the-eyes'))
                        app.classList.remove('easy-on-the-eyes')
                    else
                        app.classList.add('easy-on-the-eyes')
                }
            };

            window.addEventListener("keyup", handleKeyPress.bind(this));
            window.addEventListener("keydown", handleKeyPress.bind(this));
        },

        beforeDestroy() {
            window.removeEventListener("resize", this.handleWindowResize);
        },

        created() {
            this.$watch(() => this.$auth.user(), this.handleUserChange)

            document.documentElement.classList.add('skw');
            document.documentElement.classList.remove('acs');

            document.documentElement.classList.add('light');
            document.documentElement.classList.remove('dark');

           
        },
    }

</script>

<style scoped>

    #wrapper {
        background: #eee !important;
    }

    #app {
        background: #eee;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

        #app.easy-on-the-eyes {
            /* opacity: 0.8; */
            filter: contrast(75%);
            /* filter: invert(60%); */
            /* -webkit-filter: grayscale(1); */
        }

    #wrapper {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 67px;
        left: 0;
        bottom: 0;
        overflow-x: hidden;
        background-color: #fff;
    }

    #page-content-wrapper {
        overflow: auto;
        position: fixed;
        top: 67px;
        left: 300px;
        bottom: 0;
        width: calc(100% - 300px);
        background: #eee;
        -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
    }

    body.sb-sidenav-toggled #wrapper #page-content-wrapper {
        width: 100%;
        left: 300px;
    }

    @media (min-width: 768px) {
        #page-content-wrapper {
            min-width: 0;
        }

        body.sb-sidenav-toggled #wrapper #page-content-wrapper {
            width: 100%;
            left: 0px;
        }
    }

    @media (max-width: 768px) {
        #page-content-wrapper {
            left: 0;
            width: 100%
        }
    }

    @media (max-width: 991px) {
        #page-content-wrapper {
            top: 111px;
        }

        #wrapper {
            top: 111px;
        }
    }

</style>

