export default class PeopleDataSource {
    constructor(dashboardComponent) {
        this.dashboardComponent = dashboardComponent;

        this.icon = 'people';
        this.title = this.dashboardComponent.$tc('objects.plurals.person', 2);
        this.data = null;
        this.columns = [];
        this.details = null;
        this.minValue = null;
        this.maxValue = null;
    }

    async loadDataAsync() {
        let response = await this.dashboardComponent.$http.get('/person?include_client_people=false');
        if (!response || !response.data) {
            this.data = [];
            return
        };

        this.data = response.data;
    }

    getId(person) {
        return person.personId;
    }

    getIcon(person) {
        return 'people';
    }

    getDisplay(person) {
        return [person.firstName, person.surname].filter(n => n).join(' ');
    }

    getVariant(person) {
        return null;
    }

    getUrl(person) {
        return `/administrator/person/${person.personId}/edit`;
    }

    async handleActionAsync(person) {
        // Do nothing
    }

    getValue(person) {
        return null;
    }

    getValueDisplay(person) {
        return null;
    }

    getMinValue(person) {
        return null;
    }

    getMaxValue(person) {
        return null;
    }

    async processDataAsync(dataTypeName, data) {
        switch (dataTypeName) {
        }
    }
}