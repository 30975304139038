export default {
    data() {
        return {
            multiLanguageOptions: [
                {
                    value: 0,
                    name: this.$t('door-entry-link-door.multi-language.0')
                },
                {
                    value: 1,
                    name: this.$t('door-entry-link-door.multi-language.1')
                },
                {
                    value: 2,
                    name: this.$t('door-entry-link-door.multi-language.2')
                },
                {
                    value: 3,
                    name: this.$t('door-entry-link-door.multi-language.3')
                },
                {
                    value: 4,
                    name: this.$t('door-entry-link-door.multi-language.4')
                }
            ],
            drLinkOptions: [
                {
                    value: 0,
                    name: this.$t('door-entry-link-door.dr-link.0')
                },
                {
                    value: 1,
                    name: this.$t('door-entry-link-door.dr-link.1')
                }
            ],
            inputOptionOptions: [
                {
                    value: 0,
                    name: this.$t('door-entry-link-door.input-options.0')
                },
                {
                    value: 1,
                    name: this.$t('door-entry-link-door.input-options.1')
                }
            ],
            blockSelectOperationOptions: [
                {
                    value: 0,
                    name: this.$t('door-entry-link-door.block-select-operation.0')
                },
                {
                    value: 1,
                    name: this.$t('door-entry-link-door.block-select-operation.1')
                },
                {
                    value: 2,
                    name: this.$t('door-entry-link-door.block-select-operation.2')
                },
                {
                    value: 3,
                    name: this.$t('door-entry-link-door.block-select-operation.3')
                },
                {
                    value: 4,
                    name: this.$t('door-entry-link-door.block-select-operation.4')
                },
                {
                    value: 5,
                    name: this.$t('door-entry-link-door.block-select-operation.5')
                },
                {
                    value: 6,
                    name: this.$t('door-entry-link-door.block-select-operation.6')
                },
                {
                    value: 7,
                    name: this.$t('door-entry-link-door.block-select-operation.7')
                },
                {
                    value: 8,
                    name: this.$t('door-entry-link-door.block-select-operation.8')
                },
                {
                    value: 9,
                    name: this.$t('door-entry-link-door.block-select-operation.9')
                },
                {
                    value: 10,
                    name: this.$t('door-entry-link-door.block-select-operation.10')
                },
                {
                    value: 11,
                    name: this.$t('door-entry-link-door.block-select-operation.11')
                }
            ],
            blockNameBlockNumber: [
                {
                    value: 0,
                    name: this.$t('door-entry-link-door.block-name.0')
                },
                {
                    value: 1,
                    name: this.$t('door-entry-link-door.block-name.1')
                }
                ,
                {
                    value: 2,
                    name: this.$t('door-entry-link-door.block-name.2')
                },
                {
                    value: 3,
                    name: this.$t('door-entry-link-door.block-name.3')
                },
                {
                    value: 4,
                    name: this.$t('door-entry-link-door.block-name.4')
                },
                {
                    value: 5,
                    name: this.$t('door-entry-link-door.block-name.5')
                },
                {
                    value: 6,
                    name: this.$t('door-entry-link-door.block-name.6')
                },
                {
                    value: 7,
                    name: this.$t('door-entry-link-door.block-name.7')
                },
                {
                    value: 8,
                    name: this.$t('door-entry-link-door.block-name.8')
                },
                {
                    value: 9,
                    name: this.$t('door-entry-link-door.block-name.9')
                }
            ],
        }
    },
    methods: {
        getBlockSelectOptionName(value) {
            return this.blockSelectOperationOptions.filter(b => b.value == value)[0].name;
        },
        getInputOptionName(value) {
            return this.inputOptionOptions.filter(b => b.value == value)[0].name;
        },
        getDrLinkOptionName(value) {
            return this.drLinkOptions.filter(b => b.value == value)[0].name;
        },
        getMultiLanguageOptionName(value) {
            return this.multiLanguageOptions.filter(b => b.value == value)[0].name;
        }
    }
}