<template>
    <div> </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        created: function () {
            if (this.$auth.check('KMSUSER_USER', 'permissions')) {
                this.$router.push({ path: `/dashboard` })
            }
            else {
                this.$router.push({ path: `/` })
            }
        }
    }
</script>

<style>
</style>
